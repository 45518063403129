/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { db, auth, storage, remoteConfig } from "../../firebase/firebase";
import MyLoader from "../../components/Modals/Spinner";
import LoadingOverlay from "react-loading-overlay";
import AlertModal from "../../components/Modals/Modals";
import { userInfoContext, userListContext } from "../../context/userContext";
import {
  NetworkDetector,
  fetchUserProfile,
} from "../../components/Modals/AsyncTask";
import { useHistory, Link } from "react-router-dom";

// reactstrap components

import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import Pagination from "pagination/Pagination";
import Posts from "pagination/Posts";
import axios from "axios";
import ProfileList from "../../pagination/ProfileList";
import SkeletonCard from "components/SkeletonCard/SkeletonCard";
import TroubleFetching from "components/TroubleFetching/TroubleFetching";
import MobileVerificationModal from "components/Modals/MobileVerificationModal";
import { GLOBLE_CONSTANT } from "config/constant";
import { Capacitor } from "@capacitor/core";
function DashboardPage(props) {
  const history = useHistory();
  const ALERT_PARAMS_INITIAL_STATE = {
    title: "",
    body: "",
    btnOk: "",
    btnCancel: "",
  };
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [UID, setUID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [activeTab, setActiveTab] = React.useState("1");
  const [getError, setError] = useState(null);
  const [alertParams, setAlertParams] = useState(ALERT_PARAMS_INITIAL_STATE);
  const [showConfirmAlert, setConfirmAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeleton, setSkeleton] = useState(true);
  const [poorConnection, setPoorConnection] = useState(false);
  const { userInfo, setUserInfo } = useContext(userInfoContext);
  const { userList, setUserList } = useContext(userListContext);
  const [acceptedCount, setAcceptedCount] = useState(0);
  const [pendingReqCount, setPendingReqCount] = useState(0);
  const [showMobileOtpValidation, setShowMobileOtpValidation] = useState(false);
  var resetNetwork;
  var ntwokChk = false;
  var activeUser;
  // var acceptedCount = 0;
  // const { count, setCount } = useState(1);
  const [count, setCounter] = useState(0);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  useEffect(() => {
    //     var path = window.location.pathname;
    // if(path == '/dashboard'){
    //  window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event){
    //         window.history.pushState(null, document.title,  window.location.href);
    //     });
    //     console.log('BACK DISABLED')
    // }
    // Keyboard BACK BUTTON EVENT
    // window.addEventListener("keyup", function(e){ if(e.keyCode == 27){
    //     console.log('BACK BUTTON PRESSED')
    //          window.history.pushState(null, document.title, window.location.href);
    //          window.addEventListener('popstate', function (event){
    //              window.history.pushState(null, document.title,  window.location.href);
    //          });
    //          if(window.localStorage.getItem('index') && window.localStorage.getItem('index') !== null ){
    //              var pCount = window.localStorage.getItem('index');
    //              if(pCount !== '1'){
    //                  pCount--;
    //                  window.localStorage.setItem('index',pCount);
    //                  initialFetch();
    //              }
    //          }
    //  }; }, false);
  });
  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      // const res = await  fetch("https://jsonplaceholder.typicode.com/posts");
      // const data = await res.json();

      const res = await axios.get("https://jsonplaceholder.typicode.com/posts");
      setPosts(res.data);
      setLoading(false);
    };

    // fetchPosts();
    // window.scrollTo(0, 0)

    initialFetch();
  }, []);

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  const showOtpValidationModal = () => {
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        let globle_config = JSON.parse(
          remoteConfig.getValue("globle_config").asString()
        );
        let master_config = globle_config.master_config;
        if (master_config && master_config.OTP_VALIDATION_FLAG) {
          setShowMobileOtpValidation(false);
          setTimeout(() => {
            setShowMobileOtpValidation(true);
          }, 100);
        }
      })
      .catch((err) => {
        console.log("REMOTE CONFIG ERROR : ", err);
      });
  };
  function initialFetch() {
    activeUser = auth.currentUser;
    // if(activeUser && activeUser.phoneNumber){
    //   if (Capacitor.isNativePlatform()) {
    //     adMobIn();
    //   }
    // }
    // setUserInfo(props.location.state.userData);
    // fetchData(props.location.state.userData);
    if (userInfo) {
      // acceptedCount = userInfo.acceptedProfileId.length;
      // setAcceptedCount(userInfo.acceptedProfileId.length)
      // setPendingReqCount(userInfo.receivedProfileId.length)

      console.log("userInfo has data");
      var lastSynced = window.localStorage.getItem("sync");
      if (userList && _calculateDays(lastSynced) == 0) {
        console.log("No page refresh required");
        // setUserInfo(userInfo);
        setPosts(userList);
        setPoorConnection(false);
        setSkeleton(false);
        var pCount = findProfiles(userInfo.receivedProfileId, userList);
        var aCount = findProfiles(userInfo.acceptedProfileId, userList);
        if (pCount) {
          setPendingReqCount(pCount.length);
        }
        if (aCount) {
          setAcceptedCount(aCount.length);
        }
        if (activeUser && !activeUser.phoneNumber) {
          showOtpValidationModal();
        }
      } else {
        console.log("No1111 page refresh required");
        fetchData(userInfo);
      }
    } else {
      setNetworkTimeOut(40000);

      console.log("userInfo data not found");
      let result = NetworkDetector();
      let response = fetchUserProfile();
      setSkeleton(true);
      result.then(
        (result) => {
          response.then(
            (resp) => {
              ntwokChk = true;
              clearTimeout(resetNetwork);
              // acceptedCount = resp.acceptedProfileId.length;
              // setAcceptedCount(resp.acceptedProfileId.length)
              // setPendingReqCount(resp.receivedProfileId.length)
              // alert(acceptedCount)
              setUserInfo(resp);
              fetchData(resp);
              // setUserList(resp)
            },
            function(error) {
              ntwokChk = false;
              clearTimeout(resetNetwork);
            }
          );
        },
        function(error) {
          ntwokChk = false;
          clearTimeout(resetNetwork);
          setAlertParams((prevState) => ({
            ...prevState,
            title: "Internet Disconnected",
            body: "Please check your internet connection and try again.",
            btnOk: "RELOAD",
            btnCancel: "CANCEL",
          }));
          setSkeleton(false);
          setPoorConnection(true);
          console.log("Internet Disconnected");
        }
      );
    }
  }
  const _calculateDays = (syncDate) => {
    if (!syncDate) {
      return 1;
    }
    const timeDiff = new Date() - new Date(syncDate);
    return Math.round(timeDiff / (1000 * 60 * 60 * 24));
  };
  const successCallBackData = (data) => {
    if (data == "numberVerified") {
      alert("Mobile Number Verified Successfully.");
    }
    setShowMobileOtpValidation(false);
    setConfirmAlert(false);
    if (data == "RECONNECT") {
      //   updateUserInfo();
    } else if (data == "RELOAD") {
      fetchData(userInfo);
    } else if (data == "TRY AGAIN") {
      //   updateUserPhoto();
    } else if (data == "goBack") {
      //   history.goBack(); // This code is for OTP
      history.push("/");
    }
  };
  const setNetworkTimeOut = (timer) => {
    ntwokChk = false;
    resetNetwork = setTimeout(() => {
      if (ntwokChk) {
        console.log("INTERNET WORKING");
        setIsLoading(false);
        setSkeleton(false);
      } else {
        console.log("SHOW NETWORK ERROR");
        setPoorConnection(true);
        setIsLoading(false);
        setSkeleton(false);
      }
    }, timer);
  };
  const fetchData = (data) => {
    setNetworkTimeOut(40000);
    setPosts([]);
    let result = NetworkDetector();
    // setIsLoading(true)
    setSkeleton(true);
    result.then(
      (result) => {
        //   });
        // db.collection("ENJOYER_PFOFILE")
        db.collection("ENJOYER_PFOFILE")
          .where(
            "userData.gender",
            "==",
            data.gender == "male" ? "female" : "male"
          )
          .where("userData.isDisableAccount", "==", false)
          // db.collection("ENJOYER_PFOFILE").where("userData.email", "==", user.email).limit(2)
          // db.collection("ENJOYER_PFOFILE").where("userData.gender", "<", user.email).where("userData.email", ">", user.email)
          .get()
          .then(function(querySnapshot) {
            ntwokChk = true;
            clearTimeout(resetNetwork);
            setPoorConnection(false);
            setSkeleton(false);
            var arrList = [];
            window.scrollTo(0, 0);
            if (activeUser && !activeUser.phoneNumber) {
              showOtpValidationModal();
            }
            window.localStorage.setItem("sync", new Date());
            querySnapshot.forEach(function(doc, i, array) {
              // doc.data() is never undefined for query doc snapshots

              var data = doc.data().userData;
              setPosts((prevArray) => [...prevArray, data]);

              arrList.push(data);
              // console.log(doc.id, " => ", doc.data());
              // console.log(data)
              //  setIsLoading(false);
            });
            setUserList(arrList);
            window.localStorage.setItem("index", 1);

            for (var i = 0; i < arrList.length; i++) {
              if (data.receivedProfileId && data.acceptedProfileId) {
                var pCount = findProfiles(data.receivedProfileId, arrList);
                var aCount = findProfiles(data.acceptedProfileId, arrList);
                if (pCount) {
                  setPendingReqCount(pCount.length);
                }
                if (aCount) {
                  setAcceptedCount(aCount.length);
                }
              }
            }
          })
          .catch(function(error) {
            ntwokChk = false;
            clearTimeout(resetNetwork);
            console.log("Error getting documents: ", error);
            //  setIsLoading(false);
            setSkeleton(false);
            setPoorConnection(true);
          });
      },
      function(error) {
        ntwokChk = false;
        clearTimeout(resetNetwork);
        setAlertParams((prevState) => ({
          ...prevState,
          title: "Internet Disconnected",
          body: "Please check your internet connection and try again.",
          btnOk: "RELOAD",
          btnCancel: "CANCEL",
        }));
        // setIsLoading(false);
        setSkeleton(false);
        // setConfirmAlert(true);
        setPoorConnection(true);
        console.log("Internet Disconnected");
      }
    );
  };
  // Get current posts
  // const indexOfLastPost = currentPage * postsPerPage;
  // const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  // const paginate = pageNumber => setCurrentPage(pageNumber);

  const profileListCallBack = (data) => {
    // console.log(data);
    // console.log(userInfo);
    // acceptedCount = userInfo.acceptedProfileId.length;
    // setAcceptedCount(userInfo.acceptedProfileId.length)
    // setPendingReqCount(userInfo.receivedProfileId.length)
    // console.log('CALLBACK RECEIVED'+acceptedCount)
    var pCount = findProfiles(userInfo.receivedProfileId, posts);
    var aCount = findProfiles(userInfo.acceptedProfileId, posts);
    if (pCount) {
      setPendingReqCount(pCount.length);
    }
    if (aCount) {
      setAcceptedCount(aCount.length);
    }
  };
  useEffect(() => {}, [pendingReqCount]);
  useEffect(() => {}, [acceptedCount]);
  const handleEntailmentRequest = (e) => {
    e.preventDefault();
    //do something...
  };
  const PendingProfileViews = () => {
    var data;
    if (userInfo.receivedProfileId.length !== 0 && posts.length !== 0) {
      data = findProfiles(userInfo.receivedProfileId, posts);
      if (data)
        // history.push('/profile-views',{posts:data});
        history.push("/profile-views", {
          posts: data,
          userInfo: userInfo,
          profileType: "Pending",
        });
    }
    // history.push('/profile-views', { posts: data, userInfo: userInfo });
  };
  const profileViews = () => {
    var data;
    if (userInfo.acceptedProfileId.length !== 0 && posts.length !== 0) {
      // console.log(userInfo.acceptedProfileId)
      data = findProfiles(userInfo.acceptedProfileId, posts);
      // console.log(data)
      if (data)
        history.push("/profile-views", {
          posts: data,
          userInfo: userInfo,
          profileType: "Accepted",
        });
      // history.push('/profile-views',{posts:data});
    }
    // history.push('/profile-views', { posts: data, userInfo: userInfo });
  };
  const findProfiles = (dataArray, posts) => {
    var arr = [];
    for (var i = 0; i < dataArray.length; i++) {
      for (let index = 0; index < posts.length; index++) {
        if (dataArray[i] == posts[index].id) {
          // if(!posts[index].isDisableAccount)
          arr.push(posts[index]);
        }
      }
      if (i == dataArray.length - 1) {
        return arr;
      }
    }
  };
  return (
    <>
      {isLoading ? <MyLoader /> : null}
      {showMobileOtpValidation ? (
        <MobileVerificationModal callBack={successCallBackData} />
      ) : null}
      {isSkeleton ? (
        <SkeletonCard />
      ) : (
        <div>
          {poorConnection ? (
            <TroubleFetching callBack={initialFetch} />
          ) : (
            <div>
              <ExamplesNavbar />
              <ProfilePageHeader />
              <div className="section profile-content">
                <Container>
                  {userInfo ? (
                    <div className="owner">
                      <div className="avatar">
                        {!userInfo.photoURL && userInfo.gender == "male" ? (
                          <img
                            alt={userInfo.firstName}
                            className="img-circle img-no-padding img-responsive"
                            // src={userData.photoURL}
                            src={require("assets/image/default_User_Icon_Men.png")}
                            style={{ width: "150px", height: "150px" }}
                          />
                        ) : null}
                        {!userInfo.photoURL && userInfo.gender == "female" ? (
                          <img
                            alt={userInfo.firstName}
                            className="img-circle img-no-padding img-responsive"
                            // src={userData.photoURL}
                            style={{ width: "150px", height: "150px" }}
                            src={require("assets/image/default_User_Icon_Women.png")}
                          />
                        ) : null}
                        {userInfo.photoURL ? (
                          <img
                            alt={userInfo.firstName}
                            style={{ width: "150px", height: "150px" }}
                            className="img-circle img-no-padding img-responsive"
                            src={userInfo.photoURL}
                          />
                        ) : null}
                      </div>
                      <div className="name">
                        <h4
                          className="title"
                          style={{ textTransform: "capitalize" }}
                        >
                          {userInfo.firstName} {userInfo.lastName}
                          <br />
                        </h4>
                        {!userInfo.photoURL ? (
                          <p style={{ color: "red" }}>
                            Please update your profile photo for better match.{" "}
                            <Link to={{ pathname: "/profile-page" }}>
                              {" "}
                              Click Here
                            </Link>{" "}
                            to upload photo
                          </p>
                        ) : !userInfo.maritalStatus && !userInfo.diet ? (
                          <p style={{ color: "red" }}>
                            Please update your profile details for better match.{" "}
                            <Link to={{ pathname: "/profile-page" }}>
                              {" "}
                              Click Here
                            </Link>{" "}
                            to update profile.
                          </p>
                        ) : (
                          ""
                        )}
                        <p>
                          Welcome Back. You are looking for love in all the
                          right places, lets find someone right for you.
                        </p>
                      </div>
                      {/* <Button className="btn-round" color="default" outline style={{ marginLeft: '30px' }} onClick={() => profileViews()}>
                                            Accepted Profiles :  {acceptedCount}
                                        </Button> */}
                    </div>
                  ) : null}
                  {/* <Row>
                                        <Col className="ml-auto mr-auto text-center" md="6">
                                            <p>
                                                An artist of considerable range, Jane Faker — the name taken by
                                                Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs
                                                and records all of his own music, giving it a warm, intimate
                                                feel with a solid groove structure.
                             </p>
                                            <br />
                                            <Button className="btn-round" color="default" outline style={{marginLeft:'30px'}}>
                                                <i className="fa fa-cog" /> Settings
                                            </Button>

                                        </Col>
                                    </Row> */}
                  <br />
                  <br />
                  <Row>
                    <Col
                      className="ml-auto mr-auto  text-center"
                      lg="6"
                      md="6"
                      xs="6"
                    >
                      <Button
                        className="btn-round"
                        color="default"
                        outline
                        style={{ marginLeft: "30px" }}
                        onClick={() => profileViews()}
                      >
                        Accepted 🤝 : {acceptedCount}
                      </Button>
                    </Col>
                    <Col
                      className="ml-auto mr-auto  text-center"
                      lg="6"
                      md="6"
                      xs="6"
                    >
                      <Button
                        className="btn-round"
                        color="default"
                        outline
                        style={{ marginLeft: "30px" }}
                        onClick={() => PendingProfileViews()}
                      >
                        Pending ⌛ : {pendingReqCount}
                      </Button>
                    </Col>
                  </Row>

                  <br />
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav role="tablist" tabs>
                        <NavItem>
                          <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            Your Matches
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                                    <NavLink
                                        className={activeTab === "2" ? "active" : ""}
                                        onClick={() => {
                                            toggle("2");
                                        }}
                                    >
                                        Following
                  </NavLink>
                                </NavItem> */}
                      </Nav>
                    </div>
                  </div>
                  {/* <div className='container mt-5'>
                        <Posts posts={currentPosts} loading={loading} />
                        <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={posts.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    </div> */}
                </Container>
                <ProfileList
                  profiles={posts}
                  callBack={profileListCallBack}
                  onClick={(e) => {
                    handleEntailmentRequest(e);
                  }}
                />
              </div>
              <DemoFooter />{" "}
            </div>
          )}{" "}
        </div>
      )}
    </>
  );
}

export default DashboardPage;
