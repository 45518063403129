/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect } from "react";

// reactstrap components
/* eslint-disable */
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  Form,
} from "reactstrap";
import { Router, Route, Switch, Redirect, Link } from "react-router-dom";
// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import { AdMob, AdOptions } from "@capacitor-community/admob";
import { GLOBLE_CONSTANT } from "config/constant";
import { Capacitor } from "@capacitor/core";
function HatkarCommitteePage(match) {
  const [activeTab, setActiveTab] = React.useState("1");
  const [getGender, setGender] = React.useState({
    selectedOption: "",
  });

  const [authType, setAuthType] = useState("LOGIN");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (Capacitor.isNativePlatform()) {
      // adMobIn();
    }
  }, []);
  const adMobIn = async () => {
    try {
      await AdMob.initialize();
    } catch (error) {
      console.log("ADMOB INITIALIZE ERROR");
    }

    const options: AdOptions = {
      adId: GLOBLE_CONSTANT.rewardVideoAd_ID,
    };
    await AdMob.prepareRewardVideoAd(options);
    // const options_: AdOptions = {
    //   adId: GLOBLE_CONSTANT.interstitial_Add_ID,
    // };
    // // Prepare interstitial banner
    // await AdMob.prepareInterstitial(options_);

    setTimeout(() => {
      showRrewardVideo();
    }, 4000);
  };
  const showRrewardVideo = async () => {
    await AdMob.showRewardVideoAd();
  };
  const Textcolor = {
    color: "#fff",
  };
  const radioButtonMargin = {
    margin: "0 10px 0 10px",
  };
  const handleOptionChange = (changeEvent) => {
    console.log(changeEvent.target.value);
    setGender({
      selectedOption: changeEvent.target.value,
    });
  };

  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="name">
              <h4 className="title">
                Hatkar-Committee <br />
              </h4>
            </div>
          </div>

          <br />
          <h5 className="description text-center">
            <marquee
              direction="left"
              width="400"
              style={{ fontWeight: "800", color: "green" }}
            >
              संकेतस्थळावर जाहिरात करण्यासाठी संपर्क करा :
              hatkarmatrimony@gmail.com
            </marquee>
          </h5>
          <hr />
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <h2 className="title  text-center">
                हटकर समाज महाराष्ट्र राज्य.
              </h2>
              <h5 className="description">
                हटकर समाजाच्या हितासाठी, उन्नतीसाठी, नेहमी अग्रेसर असणारा हटकर
                समाज महासंघ होय.महाराष्ट्रात हटकर समाज खुप मोठ्या प्रमाणात असुन
                वेगवेगळ्या भागात विखुरलेल्या अवस्थेत आहे.समाजातील युवा पिढीला
                बरोबर घेऊन भविष्यात समाज बांधवांना एकत्र करून समाज परिवर्तन
                घडवून आणण्यासाठी हटकर समाजाचे संस्थापक/ अध्यक्ष श्री.भिमराव
                भुसनर ,प्रदेश अध्यक्ष मा.राज हटकर पाटील, प्रदेश प्रसिद्धी प्रमुख
                मा.काशिनाथ निळे सर यांच्या नेतृत्वाखाली सर्व जिल्हा अध्यक्ष,
                तालुका अध्यक्ष, शाखा अध्यक्ष,समाजातील सर्व जाणकार
                व्यक्तीमत्व,समाजातील युवा पिढीला बरोबर घेऊन समाज परिवर्तन घडवून
                आणणे हेच उद्देश आहे.
              </h5>
            </Col>
          </Row>

          <br />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <img
                alt="Bhimaraw Bhusanur"
                className="img-thumbnail img-responsive "
                src={require("assets/image/Bhimaraw_Bhusanur.jpg")}
              />
              <h4 className="title text-center">
                <a href="https://www.facebook.com/profile.php?id=100010526647712">
                  संस्थापक/अध्यक्ष : मा. श्री. भिमराव दशरथ भूसनर
                </a>
              </h4>
            </Col>
          </Row>
          <hr />

          <div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h4 className="title text-center" style={{ fontWeight: "800" }}>
                  प्रदेश कोअर कमिटी{" "}
                </h4>
              </Col>
            </Row>
            <Row className="description  ">
              <Col md="6">
                <div style={{ marginLeft: "15%" }}>
                  <h5 className="description  " style={{ fontWeight: "600" }}>
                    प्रदेश अध्यक्ष - मा.राज हटकर पाटील{" "}
                  </h5>
                </div>
              </Col>
              <Col md="6">
                <div style={{ marginLeft: "15%" }}>
                  <h5 className="description  " style={{ fontWeight: "600" }}>
                    प्रदेश प्रसिद्धी प्रमुख - मा.काशिनाथ निळे सर
                  </h5>
                </div>
              </Col>
            </Row>
            <hr />
            <Row className="description  ">
              <Col md="6">
                <div style={{ marginLeft: "15%" }}>
                  <h5 className="description  ">
                    - श्री.भिमराव दशरथ भुसनर जांबुड सोलापूर{" "}
                  </h5>
                  <h5 className="description  ">
                    - मा.श्री.आण्णा महाराज भुसनर रा.व्होळे ता.पंढरपूर जि.सोलापुर
                  </h5>
                  <h5 className="description  ">
                    - मा.श्री.तानाजी मनोहर खोत रा.कोंगनोळी जि.सांगली
                  </h5>
                  <h5 className="description  ">
                    - मा.श्री.चांगदेव यमगर रा.करोळे ता.पंढरपुर
                  </h5>
                  <h5 className="description ">
                    - मा.श्री.मोहन खजे रा.खजेवाडी ता.अंबाजोगाई जि.बीड
                  </h5>
                  <h5 className="description  ">
                    - सौ.वंदना भिमराव भुसनर रा.जांबुड सोलापुर महिला प्रतिनिधी{" "}
                  </h5>
                </div>
              </Col>
              <Col md="6">
                <div style={{ marginLeft: "15%" }}>
                  <h5 className="description  ">
                    - मा.श्री.बालाजी गुबनुरे ता.मुखेड जि.नांदेड
                  </h5>
                  <h5 className="description  ">
                    - मा.श्री.नंदकुमार बेलदार पाटील रा.सरडे ता.फलटण जि.सातारा
                  </h5>
                  <h5 className="description ">
                    - मा.श्री.कॅप्टन भाऊसाहेब पाटील जत
                  </h5>
                  <h5 className="description  ">
                    - मा.श्री.शाम केशव निळे रा.रुमणा ता.गंगाखेड जि.परभणी
                  </h5>
                  <h5 className="description  ">
                    - मा.श्री.ॠषीकेश यमगर रा.राघुचीवाडी ता.जि. उस्मानाबाद
                  </h5>
                  <h5 className="description  ">
                    - मा.श्री.मारुती बेनगर रा.सापळी ता.कळमनुरी जि.हिंगोली
                  </h5>
                </div>
              </Col>
            </Row>

            <br />
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <Link
                  to={{
                    pathname: "/Hatkar-Committee-Maharashtra-State",
                  }}
                >
                  {" "}
                  <Button className="btn-round" color="info">
                    Show more
                  </Button>{" "}
                </Link>
              </Col>
            </Row>
          </div>

          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h4 className="title text-center" style={{ fontWeight: "800" }}>
                संयोजक{" "}
              </h4>
            </Col>
          </Row>
          <Row className="description text-center ">
            <Col md="6">
              <div>
                <Link
                  to={{
                    pathname:
                      "/Nirmal-Gram-Purskar-Gajanan-Ganapati-Bhusanur-4th-May-2007",
                  }}
                >
                  {" "}
                  <div className="avatar">
                    <img
                      alt="Gajanan Bhusanoor"
                      className="img-circle img-no-padding img-responsive"
                      src={require("assets/image/Gajanan Bhusanur.jpg")}
                      style={{ width: "150px", height: "150px" }}
                    />
                  </div>
                  <div className="name">
                    <h4 className="title">
                      मा.श्री.गजानन गणपती भुसनुर <br />
                    </h4>
                    <h6 className="description">
                      राष्ट्रपती डॉ. ए.पि.जे. अब्दुल. कलाम यांच्या हस्ते निर्मल
                      ग्राम पुरस्काराने सन्मानित माजी सरपंच लकडेवाडी
                    </h6>
                  </div>
                </Link>
              </div>
            </Col>
            <Col md="6">
              <div>
                <div className="avatar">
                  <img
                    alt="Bhimrao Bhusnar"
                    className="img-circle img-no-padding img-responsive"
                    src={require("assets/image/Bhimrao Bhusnar.jpg")}
                    style={{ width: "150px", height: "150px" }}
                  />
                </div>
                <div className="name">
                  <h4 className="title">
                    <a href="https://www.facebook.com/profile.php?id=100010526647712">
                      मा.श्री.भिमराव दशरथ भुसनर{" "}
                    </a>{" "}
                    <br />
                  </h4>
                  <h6 className="description">
                    संस्थापक/अध्यक्ष : हटकर समाज महाराष्ट्र राज्य
                  </h6>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h4 className="title text-center" style={{ fontWeight: "800" }}>
                सांगली जिल्ह्यातील प्रमुख व्यक्ती
              </h4>
            </Col>
          </Row>
          <Row className="description  ">
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  " style={{ fontWeight: "700" }}>
                  - मा.श्री.हनुमंत कुंभारे साहेब पोलिस अधिकारी मुंबई{" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.नाथा पाटील पंचायत समिती सदस्य
                </h5>
                <h5 className="description  ">
                  - मा.श्री.गजानन गणपती भुसनुर. माजी सरपंच लकडेवाडी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.राजाराम संभाजी लकडे. लकडेवाडी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.डॉ.बिरुदेव मरगु निळे. लकडेवाडी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.तम्मान्ना कोंडीबा भुसनुर. उद्योजक पुणे/लकडेवाडी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.तानाजी खोत मा.उपसरपंच कोंगनोळी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.तानाजी भाऊसाहेब लकडे. चेअरमन सर्व सेवा सोसायटी
                  लकडेवाडी 
                </h5>
                <h5 className="description  ">
                  - मा.श्री.बिभीषण निळे युवा नेते लकडेवाडी
                </h5>
                <h5 className="description ">
                  - मा.श्री.कामन्ना पाटील सरपंच-जल्लाळ
                </h5>
                <h5 className="description  ">
                  - मा.श्री.शिवाजी व्होरे साहेब कोणीकोणुर जत{" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.दत्ता गोदे जत तालुका युवा अध्यक्ष हटकर समाज{" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.आवांना बिराजदार मा.ग्रामसेवक
                </h5>
                <h5 className="description  ">
                  - मा.श्री.प्रकाश भंडे पोलिस पाटील
                </h5>
                <h5 className="description  ">- मा.श्री.खोत मेजर कोगंनोळी.</h5>
                <h5 className="description  ">- मा.डॉ.सुशांत चौगुले </h5>
                <h5 className="description  ">
                  - मा.श्री.राजाराम दुर्योधन लकडे. उद्योजक लकडेवाडी
                </h5>
              </div>
            </Col>
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  " style={{ fontWeight: "700" }}>
                  - मा.श्री.दरेश्वर चौगुले सांगली जिल्हा युवा अध्यक्ष हटकर समाज
                </h5>
                <h5 className="description  ">
                  - मा.श्री.उत्तम संभाजी लकडे. लकडेवाडी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.विकास महादेव लकडे. लकडेवाडी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.दत्ता पाटील सरपंच चुडेखिंडी
                </h5>
                <h5 className="description ">
                  - मा.श्री.लक्ष्मण पाटील सरपंच कोणीकोणुर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.राजु डोरले उद्योजक कुंभारी जत
                </h5>

                <h5 className="description  ">
                  - मा.श्री.संभाजी हूवांनां लकडे माजी व्हा. चेअरमन सर्व सेवा
                  सोसायटी लकडेवाडी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.लिंगाप्पा साऊबा व्हरे शिक्षक कोल्हापूर/लकडेवाडी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.रमेश विठोबा चौगुले. दरी-बडची उद्योजक
                </h5>
                <h5 className="description  ">
                  - मा.श्री.राजु डिकसळ युवा नेते
                </h5>
                <h5 className="description  ">
                  - मा.श्री.विश्वास निळे युवा उद्योजक जत
                </h5>
                <h5 className="description  ">
                  - मा.श्री.बलभिम तात्या पाटील मनसे जत तालुका अध्यक्ष
                </h5>
                <h5 className="description  ">
                  - मा.श्री.कँप्टन भाऊसाहेब पाटील
                </h5>
                <h5 className="description  ">
                  - मा.श्री.उमेश बिराजदार मिडीया रिपोर्टर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.मनोहर चौगुले आदर्श शिक्षक
                </h5>
                <h5 className="description  ">
                  - मा.श्री.उमेश बिराजदार मिडीया रिपोर्टर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.सुखदेव गोदे पि.एस.आय.गडचिरोली
                </h5>
              </div>
            </Col>
          </Row>
          <hr />

          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h4 className="title text-center" style={{ fontWeight: "800" }}>
                सोलापूर जिल्ह्यातील प्रमुख व्यक्ती
              </h4>
            </Col>
          </Row>
          <Row className="description  ">
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  " style={{ fontWeight: "700" }}>
                  - मा.श्री.विनायक पाटील शिरढोण सोलापूर जिल्हा अध्यक्ष{" "}
                </h5>
                <h5 className="description  " style={{ fontWeight: "700" }}>
                  - मा.श्री.हनुमंत कवले पोलीस उपनिरीक्षक{" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.चंद्रकांत होळकर  साहेब रा.हिरज अधिक्षक जि.प.महिला व
                  बालकल्याण विभाग सोलापूर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.अशोक चौंडे नंदेश्वर मुलुख मैदान तोफ
                </h5>
                <h5 className="description  ">
                  - मा.श्री.बाळासाहेब जयराम पाटिल. संस्थापक, विठ्ठल शिक्षण
                  प्रसारक मंडळ संच्यालित आदर्श विद्यामंदिर हायस्कूल, जुजारपूर,
                  ता. सांगोला
                </h5>
                <h5 className="description  ">
                  - मा.श्री.दत्तात्रय बाळू पाटिल महीम ता. सांगोला - सामाजिक
                  कार्यकर्ता
                </h5>
                <h5 className="description  ">
                  - मा.डाॅ.सदाशिव मारुती दबडे, राजुरी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.बिटु करवर आव्हे संचालक क्रुषी उत्पन्न बाजार समिती
                  पंढरपूर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.सुरेश मरगर साहेब महिम/सोलापूर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.अमोल जोरवर पाटील मिरे संचालक सहकार महर्षी
                  शं.स.सा.का. अकलुज
                </h5>
                <h5 className="description  ">
                  - मा.श्री.दादासाहेब व्हळगळ राजुरी /अकलुज उद्योजक
                </h5>
                <h5 className="description  ">
                  - मा.श्री.पांडुरंग चौगुले भाळवणी मा.संचालक संत दामाजी
                  सह.सा.कारखाना मंगळवेढा
                </h5>
                <h5 className="description  ">
                  - मा.श्री.दत्तात्रय चौगुले शिरढोण मा.संचालक विठ्ठल
                  सह.सा.का.गुरसाळे
                </h5>
                <h5 className="description  ">
                  - मा.श्री.पै.नितीन हांडे सय्यद वरवडे उद्योजक
                </h5>
                <h5 className="description  ">
                  - मा.श्री.परिक्षीत सोपनर पाटील उद्योपती पुणे
                </h5>
                <h5 className="description  ">
                  - मा.श्री.हनुमंत पाटील जेष्ठ नेते पोफळी मोहोळ
                </h5>
                <h5 className="description  ">
                  - मा.श्री.दाजी भुसनर शिरढोण मा.सभापती पंचायत समिती पंढरपूर{" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.केशवराव पाटील रा.चांदापुरी मा.संचालक श्रीशंकर
                  सह.सा.का.सदाशिवनगर
                </h5>

                <h5 className="description  ">
                  - मा.श्री.सुनिल बंडगर करोळे शेतकी अधिकारी विठ्ठलराव शिंदे
                  सह.सा.कारखाना माढा
                </h5>
                <h5 className="description  ">
                  - मा.श्री.विजय दादा भुसनर युवा उद्योजक पंढरपूर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.चांगदेव यमगर करोळे हटकर समाज प्रदेश कोअर कमिटी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.पै.सोमनाथ मरगर महिम सदस्य महाराष्ट्र कुस्तीगीर परिषद
                </h5>
                <h5 className="description  ">
                  - मा.श्री.हनुमंत बंडगर पाटील करोळे मा.संचालक खरेदी विक्री संघ
                  पंढरपूर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.नामदेव पाटील ह.मंगेवाडी/मुंबई
                </h5>
                <h5 className="description  ">
                  - मा.श्री.तुकाराम भुसनर डिकसळ संचालक खरेदी विक्री संघ सांगोला
                </h5>
                <h5 className="description  ">
                  - मा.श्री.बाबासाहेब बेलदार मा.संचालक दामाजी मंगळवेढा
                </h5>
                <h5 className="description  ">
                  - मा.श्री.शंकर लक्ष्मण चौगुले महिम संचालक क्रुषी उत्पन्न बाजार
                  समिती सांगोला
                </h5>
                <h5 className="description  ">
                  - मा.श्री.उत्तम बेलदार पाटील खडकी/नंदेश्वर संचालक क्रुषी
                  उत्पन्न बाजार समिती मंगळवेढा
                </h5>
                <h5 className="description  ">
                  - मा.श्री.मोहन पाटील शिरढोण मा.संचालक पांडुरंग
                  सह.सा.का.श्रीपुर
                </h5>

                <h5 className="description  ">
                  - मा सौ.मिनाक्षी गणेश भुसनर सरपंच शिरढोण
                </h5>

                <h5 className="description  ">
                  - मा.श्री.सचिन खुळे मंगळवेढा तालुकाध्यक्ष हटकर समाज
                </h5>
                <h5 className="description  ">
                  - मा.श्री.सुरेश पाटील पंढरपुर तालुकाध्यक्ष हटकर समाज
                </h5>
                <h5 className="description  ">
                  - मा.श्री.अर्जुन यमगर उपसरपंच करोळे
                </h5>
                <h5 className="description  ">
                  - मा.श्री.पांडुरंग भारत व्हरगळ जांबुड इंडियन आर्मी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.समाधान यमगर पत्रकार ,एस.व्ही.न्युज चॅनेल करोळे
                </h5>
                <h5 className="description  ">
                  - मा.श्री.अनिल जोरवर विठ्ठलवाडी / जांबुड जनरल मॅनेजर मुंबई
                </h5>
                <h5 className="description  ">
                  - मा.श्री.गणेश लक्ष्मण बंडगर गोणेवाडी /मंगळवेढा सदस्य प्रदेश
                  कोअर कमिटी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.लिंगेश भुसनर पत्रकार शिरढोण
                </h5>
                <h5 className="description  ">- मा.डाॅ.नितीन चौंडे मंगळवेढा</h5>
                <h5 className="description  ">
                  - मा.प्रा.महादेव खोत वेताळवाडी /उमरगा वेताळवाडी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.संभाजी मिसकर साहेब वडाचीवाडी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.संतोष कवले रयतक्रांती शेतकरी महासंघ
                </h5>
                <h5 className="description  ">
                  - मा.श्री.आण्णा पाटील युवा नेते आहेरगांव ता.माढा{" "}
                </h5>
              </div>
            </Col>
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  " style={{ fontWeight: "700" }}>
                  - मा.श्री.सचिन कवले साहेब समाज कल्याण अधिकारी परभणी
                </h5>
                <h5 className="description  " style={{ fontWeight: "700" }}>
                  - मा.श्री.नितीन कवले साहेब विक्रीकर आयुक्त मुंबई
                </h5>
                <h5 className="description  " style={{ fontWeight: "700" }}>
                  - मा.श्री.ज्ञानेश्वर बगडे रा. हटकरवाडी माढा तालुका युवा
                  अध्यक्ष हटकर समाज महाराष्ट्र राज्य
                </h5>
                <h5 className="description  ">
                  - मा.श्री.भगवान बंडगर सोलापूर शहर अध्यक्षहटकर समाज
                </h5>
                <h5 className="description  ">
                  - मा.श्री.औदुंबर बंडगर सर गोणेवाडी सोलापूर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.राजाभाऊ पुंजाबा पाटिल, मजूर सोसायटी चेअरमन, अध्यक्ष:
                  विठ्ठल शिक्षण प्रसारक मंडळ संच्यालित आदर्श विद्यामंदिर
                  हायस्कूल, जुजारपूर, ता. सांगोला
                </h5>
                <h5 className="description  ">
                  - मा.श्री.आप्पा(माधव) सुखदेव पाटिल,प्रगतशील शेतकरी. चांदापूर,
                  ता. माळशिरस
                </h5>
                <h5 className="description  ">
                  - मा.श्री.आकाश भुसनर उद्योजक मुंबई
                </h5>
                <h5 className="description  ">
                  - मा.श्री.राजन कवले अॅडव्होकेट माढा
                </h5>
                <h5 className="description  ">
                  - मा.श्री.सुखदेव बंडगर युवा नेते
                </h5>
                <h5 className="description  ">
                  - मा.श्री.अमर बेलदार मुंबई पोलीस
                </h5>
                <h5 className="description  ">
                  - मा.श्री.राम जालिंदर पाटील साहेब आव्हे शेतकी अधिकारी जयवंत
                  शुगर कराड
                </h5>

                <h5 className="description  ">
                  - मा.सौ.अर्चना संजय व्हरगर रा.करोळे सभापती पंचायत समिती
                  पंढरपूर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.शिवाजी पाटील आहेरगाव कार्यकारी संचालक येडेश्वरी सह
                  सा.का.परळी वैद्यनाथ
                </h5>
                <h5 className="description  ">
                  - मा.श्री.नवनाथ बंडगर कुसमोड कार्यकारी संचालक जयवंत शुगर कराड
                </h5>
                <h5 className="description  ">
                  - मा.श्री.नाथा पाटील साहेब आव्हे नायब तहसिलदार
                </h5>
                <h5 className="description  ">
                  - मा.श्री.विठ्ठल जोरवर साहेब तहसिलदार विठ्ठलवाडी/जांबुड
                </h5>

                <h5 className="description  ">
                  - मा.श्री.सचिन चौगुले सरपंच जल्लाळ
                </h5>
                <h5 className="description  ">
                  - मा.श्री.निव्रुती बंडगर सरपंच खळवे
                </h5>
                <h5 className="description  ">
                  - मा.श्री.तानाजी भुसनर सरपंच व्होळे
                </h5>
                <h5 className="description  ">
                  - मा.श्री.धनाजी यजगर मुंबई पोलिस
                </h5>
                <h5 className="description  ">
                  - मा.श्री.भिमराव बंडगर महाळुंग संचालक चंद्रशेखर स्कुल कमिटी
                  श्रीपुर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.संजय मिसकर श्रीपुर युवा उद्योजक
                </h5>
                <h5 className="description  ">
                  - मा.श्री.शिवराज पाटील पेहे युवा उद्योजक
                </h5>

                <h5 className="description  ">
                  - मा.प्रा.आबासाहेब भुसनर व्होळे / सांगली
                </h5>
                <h5 className="description  ">
                  - मा.श्री.संपत पाटील तरडगाव जिल्हा उपाध्यक्ष
                </h5>
                <h5 className="description  ">
                  - मा.श्री.आबासाहेब शेवाळे ह.मंगेवाडी पत्रकार जनसंपर्क अधिकारी
                  सांगोला
                </h5>
                <h5 className="description  ">
                  - मा.श्री.धनंजय भुसनर साहेब व्होळे अधिकारी बँक ऑफ बडोदा गुजरात
                </h5>
                <h5 className="description  ">
                  - मा.प्रा.शत्रुघ्न भगवान भुसनर जांबुड राजस्थान
                </h5>
                <h5 className="description  ">
                  - मा.श्री.हनुमंत भुसनर प्रशिक्षक पांडुरंग कुस्ती आखाडा श्रीपुर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.सर्जेराव पाटील करोळे युवा उद्योजक
                </h5>
                <h5 className="description  ">
                  - मा.श्री.डाॅ.भुसनर जांबुड 15 सेक्शन पशुवैद्यकीय सेवा
                </h5>
                <h5 className="description  ">
                  - मा.श्री.नामदेव लकडे पत्रकार सांगोला
                </h5>
                <h5 className="description  ">
                  - मा.प्रा.नंदकुमार व्हरे गोणेवाडी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.हनुमंत बेलदर युवा नेते जांबुड
                </h5>
                <h5 className="description  ">
                  - मा.श्री.पांडुरंग भुसनर मुंबई पोलीस
                </h5>

                <h5 className="description  ">
                  - ह.भ.प.आण्णा महाराज भुसनर व्होळे
                </h5>
                <h5 className="description  ">
                  - ह.भ.प.जालिंधर चौगुले महाराज महिम
                </h5>
                <h5 className="description  ">
                  - ह.भ.प.क्रुष्णा पाटील महाराज पेहे
                </h5>
                <h5 className="description  ">
                  - ह.भ.प.दादा व्हरगळ महाराज जांबुड
                </h5>
                <h5 className="description  ">
                  - मा.श्री.अंकुश चौगुले साहेब ऑनलाईन नेटवर्किंग बिझनेस
                </h5>
                <h5 className="description  ">
                  - मा.श्री.गणेश जोरवर नंदेश्वर युवा नेता
                </h5>
                <h5 className="description  ">
                  - मा.श्री महेश खोत अॅडव्होकेट माढा
                </h5>

                <h5 className="description  ">
                  - मा.श्री.कांतीलाल बेलदार पाटील जेष्ठ नेते रा.अंत्रोळी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.आनंता बगडे रा.बगडेवाडी  जिल्हा उपाध्यक्ष हटकर समाज
                </h5>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h4 className="title text-center" style={{ fontWeight: "800" }}>
                सातारा जिल्ह्यातील प्रमुख व्यक्ती
              </h4>
            </Col>
          </Row>
          <Row className="description  ">
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  " style={{ fontWeight: "700" }}>
                  - मा.श्री.भालचंद्र यजगर सातारा जिल्हा अध्यक्ष रा.सांगवी
                  ता.फलटण{" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.सुखदेव बेलदार पाटील संचालक श्रीराम सह.सा.का.फलटण
                </h5>
                <h5 className="description  ">
                  - मा.श्री.विकास बेलदार पाटील (पत्रकार){" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.धर्मराज यजगर पाटील (किसान मोर्चा जिल्हा उपाध्यक्ष
                  सातारा){" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.विरसिंह बेलदार पाटील मा.सरपंच सरडे
                </h5>
                <h5 className="description  ">
                  - मा.श्री.जयाप्पा बेलदार सरपंच सोनगाव
                </h5>
              </div>
            </Col>
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  ">
                  - मा.श्री.सुरेश पाटील मा.सभापती पंचायत समिती फलटण{" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.विनायक पाटील संचालक क्रुषी उत्पन्न बाजार समिती फलटण{" "}
                </h5>
                <h5 className="description  ">- मा.श्री.सोमनाथ यजगर </h5>
                <h5 className="description  ">
                  - मा.श्री.रविंद्र बेलदार पाटील साहेब{" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.संजय बेलदार पाटील साहेब{" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.शांतीलाल बेलदार पाटील मा.संचालक श्रीराम
                  सह.सा.का.फलटण{" "}
                </h5>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h4 className="title text-center" style={{ fontWeight: "800" }}>
                हिंगोली जिल्ह्यातील प्रमुख व्यक्ती
              </h4>
            </Col>
          </Row>
          <Row className="description  ">
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  " style={{ fontWeight: "700" }}>
                  - मा.श्री.ब्रम्हानंद बेनगर हिंगोली जिल्हा अध्यक्ष रा.सापळी
                  ता.कळमनुरी
                </h5>
                <h5 className="description  ">- युवा नेते वैभव मुलगीर</h5>
                <h5 className="description  ">- मा.श्री रघुनाथ पाटील</h5>
                <h5 className="description  ">- मा.श्री.प्रभु पाटील</h5>
                <h5 className="description  ">- मा.श्री.दशरथ कलगोंडे</h5>
                <h5 className="description  ">- मा.श्री.विजयराव मुलगीर</h5>
                <h5 className="description  ">- मा.श्री.आनंदा मुलगीर</h5>
              </div>
            </Col>
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  ">- मा.श्री.अंकुश भुसनर</h5>
                <h5 className="description  ">- पत्रकार शंकर मुलगीर </h5>
                <h5 className="description  ">- मा.प्रा.संजय मुलगीर</h5>
                <h5 className="description  ">- डॉ निळकंठ मुलगीर</h5>
                <h5 className="description  ">- मा.श्री.अंकुश मुलगीर</h5>
                <h5 className="description  ">- मा.श्री विकास राणगिरे</h5>
                <h5 className="description  ">- मा.श्री.गजानन चेभेले</h5>
                <h5 className="description  ">- मा.श्री.संतोष मुलगीर</h5>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h4 className="title text-center" style={{ fontWeight: "800" }}>
                लातूर जिल्ह्यातील प्रमुख व्यक्ती
              </h4>
            </Col>
          </Row>
          <Row className="description  ">
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  " style={{ fontWeight: "700" }}>
                  - मा.श्री.पेमनाथ दोडगे जिल्हा अध्यक्ष हनमंतवाडी ता.निलंगा
                </h5>
                <h5 className="description  ">
                  - माःश्रीः शिवाजी डोरले चेअरमन
                </h5>
                <h5 className="description  ">- माःश्रीः विजय बिराजदार </h5>
                <h5 className="description  ">
                  - माःश्रीः रमेश खुर्दे जिल्हा उपाध्यक्ष{" "}
                </h5>
                <h5 className="description  ">
                  - माःश्रीः राजेंद्र खुर्दे चेअरमन{" "}
                </h5>

                <h5 className="description  ">
                  - मा.श्री.मारूती पाटील. पोलीस पाटील
                </h5>
                <h5 className="description  ">- मा.श्री.विटल आदित्य नगरसेवक</h5>
                <h5 className="description  ">- मा.श्री.मधु खुर्दे </h5>
                <h5 className="description  ">- मा.श्री.गोपाळ मानवतकर</h5>
              </div>
            </Col>
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  ">
                  - माःश्रीः आन्ना म्हेत्रे (सकाळ पत्रकार )
                </h5>
                <h5 className="description  ">
                  - मा.श्री.युवराज जोगी वंचित आघाडी लातूर अध्यक्ष{" "}
                </h5>
                <h5 className="description  ">- माःश्रीः बालासाहेब वाघमारे </h5>
                <h5 className="description  ">- माःश्रीः गोविंद खजे सरपंच </h5>
                <h5 className="description  ">- माःश्रीः गोरख खुर्दे सरपंच </h5>

                <h5 className="description  ">- मा.श्री.नितीन बडगर ओसा </h5>
                <h5 className="description  ">- मा.श्री.डिगंबर डोरले </h5>
                <h5 className="description  ">- मा.श्री.बाबाजी गोडके</h5>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h4 className="title text-center" style={{ fontWeight: "800" }}>
                मुंबई शहर प्रमुख व्यक्ती
              </h4>
            </Col>
          </Row>
          <Row className="description  ">
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  ">
                  - मा.श्री.संतोष मिजगर साहेब पाटील चित्रपट  निर्माता अभिनेता
                </h5>
                <h5 className="description  ">
                  - मा.श्री.राज मिजगर साहेब मुंबई शहर अध्यक्ष
                </h5>
                <h5 className="description  ">
                  - मा.सौ.वर्षाराणी भुसनर मुंबई शहर महिला अध्यक्ष
                </h5>
                <h5 className="description  ">- मा.प्रा.आर.बी.पाटील </h5>
                <h5 className="description  ">- मा.श्री.महादेव मुलगीर</h5>
                <h5 className="description  ">- मा.डाॅ.विशाल पाटील</h5>
                <h5 className="description  ">- मा.श्री.आकाश जुजारे</h5>
                <h5 className="description  ">- मा.श्री.जय मिजगर</h5>
              </div>
            </Col>
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  ">
                  - मा.श्री.आन्ना म्हेत्रे (सकाळ पत्रकार )
                </h5>
                <h5 className="description  ">- मा.श्री.शाम भुसनर </h5>
                <h5 className="description  ">- मा.श्री.तुकाराम कर्वे</h5>
                <h5 className="description  ">- मा.प्रा.प्रविण गुन्नर</h5>
                <h5 className="description  ">- मा.श्री.सुनिल भुसनर</h5>
                <h5 className="description  ">- मा.श्री.राज पाटील </h5>
                <h5 className="description  ">- मा.श्री.बालाजी मिजगर </h5>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h4 className="title text-center" style={{ fontWeight: "800" }}>
                बीड जिल्ह्य़ातील प्रमुख व्यक्ती
              </h4>
            </Col>
          </Row>
          <Row className="description  ">
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  ">
                  - मा.श्री.राजु निळे साहेब रा.चोथेवाडी/नगर मराठवाडा प्रमुख हटकर
                  समाज महासंघ{" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.रामदास निळे साहेब रा.चोथेवाडी बीड जिल्हा अध्यक्ष
                  हटकर समाज
                </h5>
                <h5 className="description  ">
                  - मा.श्री.मोहन खजे रा.खजेवाडी जेष्ठ नेते हटकर समाज{" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री पंडीत रंगनाथ भुसनर उपसरपंच रा.भुसनर
                </h5>
                <h5 className="description  ">
                  - मा.सौ.त्रेवेणीताई रमेश भुसनर रा.भुसनरवाडी कर सहायक अधिकारी
                  जळगाव
                </h5>
                <h5 className="description  ">
                  - मा.श्री.संतोष विष्णु भुसनर इंडियन आर्मी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.आण्णासाहेब सदगर पोलिस बीड
                </h5>
                <h5 className="description  ">
                  - मा.श्री.अजित सदगर क्रुषी सहायक चंद्रपूर{" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.दादा हरी सदगर सर रायमोह
                </h5>
                <h5 className="description  ">
                  - मा.श्री.बाळासाहेब मच्छिंद्र सदगर साहेब रा.माहळप्पाचीवाडी
                  आरोग्य सेवक पाटोदा
                </h5>
              </div>
            </Col>
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <h5 className="description  ">
                  - मा.श्री.रामकिसन आबा सदगर नगर पंचायत अधिकारी नागपूर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.पिंटु बेलदार सरपंच हाटकरवाडी
                </h5>
                <h5 className="description  ">
                  - मा.श्री.विकास सदगर साहेब रा.म्हाळपाचीवाडी महाराष्ट्र पोलिस
                  मुंबई{" "}
                </h5>
                <h5 className="description  ">
                  - मा.श्री.धनंजय गोरख भुसनर रा.भुसनरवाडी बीड जिल्हा युवा
                  अध्यक्ष हटकर समाज
                </h5>
                <h5 className="description  ">
                  - मा.श्री.पाराजी सदगर साहेब रा.हाटकरवाडी ता.शिरुर क्रुषी सहायक
                  चिमुर जिल्हा चंद्रपूर
                </h5>
                <h5 className="description  ">
                  - मा.श्री.राजु हरिश्चंद्र सदगर पोलिस बीड
                </h5>
                <h5 className="description  ">
                  - मा.श्री.अरुण रोहिदास बंडगर रेल्वे वेस्टर्न मुंबई
                </h5>
                <h5 className="description  ">
                  - मा.श्री.लक्ष्मण शंकर भुसनर साहेब सहाय्यक बांधकाम विभाग बीड
                </h5>
                <h5 className="description  ">
                  - मा.श्री.सोमनाथ नारायण सदगर साहेब बांधकाम अभियंता दिग्रस
                  यवतमाळ
                </h5>
                <h5 className="description  ">
                  - मा.श्री.वैभव नारायण सदगर एस.आर.पी.दौंड
                </h5>
                <h5 className="description  ">
                  - मा.श्री.बळीराम महादेव सदगर साहेब रा.हाटकरवाडी वनरक्षक ठाणे
                </h5>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h5 className="title text-center">
                To advertise on the website, kindly contact us via email at{" "}
                <a
                  href="mailto:hatkarmatrimony@gmail.com"
                  style={{ color: "#76daff", textTransform: "none" }}
                >
                  hatkarmatrimony@gmail.com
                </a>
              </h5>
            </Col>
          </Row>
          <br />
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <Button className="btn-round" color="info" href="/">
                Back to Home Page
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default HatkarCommitteePage;
