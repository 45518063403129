/* eslint-disable */
import React from 'react';

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
    // if(i==currentPage){
    //   // console.log();
    // }
    // console.log( typeof(currentPage));
 
  }
  // console.log(currentPage);
 const selectedPage = {
     "color":'#000000',
     "backgroundColor": '#f5593d',
    "borderColor": '#f5593d'
 }
  return (
    <nav>
      <ul className='pagination'>
        {pageNumbers.map(number => (
          <li key={number} className='page-item'>
            <a onClick={() => paginate(number)}  className='page-link' style= {{ "color": number == currentPage ? '#f5593d' :'#000000' }}>
          {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;