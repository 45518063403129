/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// reactstrap components

import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  Form,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import ImgsViewer from "react-images-viewer";
import { remoteConfig } from "../../firebase/firebase";
import { AdMob, AdOptions } from "@capacitor-community/admob";
import { Capacitor } from "@capacitor/core";
import { GLOBLE_CONSTANT } from "config/constant";
import ImageViewerModal from "components/Modals/imageVieweModal";

function CommunityEvents(props) {
  const [activeTab, setActiveTab] = React.useState("1");
  const [getGender, setGender] = React.useState({
    selectedOption: "",
  });

  const [eventsData, setEventsData] = useState();
  const [imageViewParams, setImageViewParams] = useState("");
  const [imageViewerModal, setImageViewerModal] = useState(false);
  const history = useHistory();
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const cntImage = 0;
  const imgWidth = 220;
  const open = true;
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.eventsData
    ) {
      // console.log(props.location.state.eventsData);
      setEventsData(props.location.state.eventsData);
    } else {
      fetchFromFirebase();
    }
    window.scrollTo(0, 0);
    if (Capacitor.isNativePlatform()) {
      // adMobIn();
    }
  }, []);
  const adMobIn = async () => {
    try {
      await AdMob.initialize();
    } catch (error) {
      // console.log("ADMOB INITIALIZE ERROR");
    }

    const options: AdOptions = {
      adId: GLOBLE_CONSTANT.rewardVideoAd_ID,
    };
    await AdMob.prepareRewardVideoAd(options);
    // const options_: AdOptions = {
    //   adId: GLOBLE_CONSTANT.interstitial_Add_ID,
    // };
    // // Prepare interstitial banner
    // await AdMob.prepareInterstitial(options_);

    setTimeout(() => {
      showRrewardVideo();
    }, 4000);
  };
  const showRrewardVideo = async () => {
    await AdMob.showRewardVideoAd();
  };
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  const fetchFromFirebase = () => {
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        let normal_events_feeds = JSON.parse(
          remoteConfig.getValue("normal_events_feeds").asString()
        );
        // console.log(normal_events_feeds);
        if (normal_events_feeds && normal_events_feeds.status) {
          setEventsData(normal_events_feeds);
        }
      })
      .catch((err) => {
        // console.log("REMOTE CONFIG ERROR : ", err);
      });
  };
  const radioButtonMargin = {
    margin: "0 10px 0 10px",
  };
  const handleOptionChange = (changeEvent) => {
    // console.log(changeEvent.target.value);
    setGender({
      selectedOption: changeEvent.target.value,
    });
  };

  function showPhoto(data) {
    if (data.image_URL) {
      setImageViewParams(data);
      setImageViewerModal(false);
      setTimeout(() => {
        setImageViewerModal(true);
      }, 100);
    }
  }

  return (
    <>
      {imageViewerModal ? <ImageViewerModal data={imageViewParams} /> : null}
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        {eventsData && eventsData.status ? (
          <Container>
            <div className="owner">
              <div className="name">
                <h4 className="title">
                  Community Events
                  <br />
                </h4>
                <h5 className="description text-center">
                  <marquee
                    direction="left"
                    width="400"
                    style={{ fontWeight: "800", color: "green" }}
                  >
                    संकेतस्थळावर जाहिरात करण्यासाठी संपर्क करा :
                    hatkarmatrimony@gmail.com
                  </marquee>
                </h5>
                <hr />
              </div>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h4 className="title text-center" style={{ fontWeight: "800" }}>
                  {" "}
                  {eventsData.type}
                </h4>
              </Col>
            </Row>
            {eventsData.ITEMS.map((item, index) => {
              return (
                <div key={index}>
                  <Row className="description  ">
                    <Col md="6" className=" text-center ">
                      <div>
                        <img
                          alt={item.title}
                          className=" img-no-padding img-responsive"
                          src={item.image_URL}
                          style={{ width: "70%", height: "60%" }}
                          onClick={(event) => showPhoto(item)}
                        />
                        {/* <div className="name">
                                    <h4 className="title">
                                        {item.title}<br />
                                    </h4>
                                </div> */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div>
                        <h4 className="title text-center">
                          <b style={{ fontWeight: "600" }}>{item.title}</b>
                          <br />
                        </h4>
                        <h5
                          className="description text-center"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {item.long_Description}
                        </h5>
                      </div>
                    </Col>
                  </Row>{" "}
                  <hr />
                </div>
              );
            })}

            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h5 className="title text-center">
                  To advertise on the website, kindly contact us via email at{" "}
                  <a
                    href="mailto:hatkarmatrimony@gmail.com"
                    style={{ color: "#76daff", textTransform: "none" }}
                  >
                    hatkarmatrimony@gmail.com
                  </a>
                </h5>
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <Button className="btn-round" color="info" href="/">
                  Back to Home Page
                </Button>
              </Col>
            </Row>
          </Container>
        ) : null}
      </div>
      <DemoFooter />
    </>
  );
}

export default CommunityEvents;
