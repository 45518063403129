/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import AlertModal from "../../components/Modals/Modals";
import { userInfoContext, userListContext } from "../../context/userContext";
import { useHistory, Link } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
// reactstrap components

import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  Form,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import * as firebase from "firebase/app";
import { db, auth } from "../../firebase/firebase";
import fakeAuth from "views/examples/PrivateRoute";
import {
  NetworkDetector,
  fetchUserProfile,
  IPInfoFetcher,
} from "../../components/Modals/AsyncTask";
import MyLoader from "components/Modals/Spinner";
import MobileVerificationModal from "components/Modals/MobileVerificationModal";

function ProfilePage(match) {
  const history = useHistory();
  const [activeTab, setActiveTab] = React.useState("1");
  const isLoggedin = JSON.parse(localStorage.getItem("isLoggedIn"));
  const { userList, setUserList } = useContext(userListContext);
  const [showMobileOtpValidation, setShowMobileOtpValidation] = useState(false);
  const [getGender, setGender] = React.useState({
    selectedOption: "",
  });
  var resetNetwork;
  var ntwokChk = false;
  const REGISTRATION_INITIAL_STATE = {
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    mobileNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    isAdmin: false,
    error: null,
  };
  const LOGIN_INITIAL_STATE = {
    email: "",
    password: "",
    error: null,
  };
  const RESET_PASSWORD_INITIAL_STATE = {
    email: "",
    error: null,
  };
  const ALERT_PARAMS_INITIAL_STATE = {
    title: "Well Done!!",
    body:
      "Account is successfully created. Please login with your registered email and password to find your soulmate.",
    btnOk: "Hurrey!!",
  };
  const [isRegistration, setRegistration] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [defaultContainer, setDefaultContainer] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [authType, setAuthType] = useState("LOGIN");
  const [getTerms, setTS] = useState(true);
  const [getRegistrationDetails, setRegistrationDetails] = useState(
    REGISTRATION_INITIAL_STATE
  );
  const [getLoginDetails, setLoginDetails] = useState(LOGIN_INITIAL_STATE);
  const [getResetPasswordDetails, setResetPasswordDetails] = useState(
    RESET_PASSWORD_INITIAL_STATE
  );
  const [alertParams, setAlertParams] = useState(ALERT_PARAMS_INITIAL_STATE);
  const [showConfirmEmailAlert, setSubscriberEmail] = useState(false);
  const [resentEmail, setResentEmail] = useState(false);
  const { userInfo, setUserInfo } = useContext(userInfoContext);
  const [isLoading, setIsLoading] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const onHandleChangeRegister = (event) => {
    // setRegistrationDetails({ [event.target.name]: event.target.value });
    const { name, value } = event.target;
    setRegistrationDetails((prevState) => ({
      ...prevState,
      [name]: value,
      error: null,
    }));
  };
  const onHandleChangeLogin = (event) => {
    // setRegistrationDetails({ [event.target.name]: event.target.value });
    const { name, value } = event.target;
    setLoginDetails((prevState) => ({
      ...prevState,
      [name]: value,
      error: null,
    }));
  };
  const onHandleChangeResetPassword = (event) => {
    // setRegistrationDetails({ [event.target.name]: event.target.value });
    const { name, value } = event.target;
    setResetPasswordDetails((prevState) => ({
      ...prevState,
      [name]: value,
      error: null,
    }));
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    if (match.location.state !== undefined) {
      if (match.location.state.authType == "LOGIN") {
        setLogin(true);
      }
      if (match.location.state.authType == "REGISTER") {
        setRegistration(true);
      }
      setAuthType(match.location.state.authType);
    } else {
      setLogin(true);
    }

    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  }, []);
  React.useEffect(() => {
    if (isLoggedin) {
      if (userInfo) {
        // console.log("userInfo has data");
      } else {
        // console.log("userInfo data not found");
        let response = fetchUserProfile();
        response.then(
          (resp) => {
            setUserInfo(resp);
          },
          function(error) {}
        );
      }
    } else {
      // console.log("User is not logged in");
      window.localStorage.setItem("index", 1);
      setUserInfo(null);
      setUserList(null);
    }
  }, []);

  const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

  const ERROR_MSG_ACCOUNT_EXISTS =
    "An account with this E-Mail address already exists. Try to login with this account instead.";
  const doPasswordReset = () => {
    if (getResetPasswordDetails.email == "") {
      setResetPasswordDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Email ID should not be blank",
        },
      }));
      return;
    }
    if (!emailValidation(getResetPasswordDetails.email)) {
      setResetPasswordDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Email ID is not valid",
        },
      }));
      return;
    }
    setIsLoading(true);
    setNetworkTimeOut(40000);
    auth
      .sendPasswordResetEmail(getResetPasswordDetails.email)
      .then((authUser) => {
        // console.log("RESET_PASSWORD_SUCCESS");
        setAlertParams((prevState) => ({
          ...prevState,
          title: "Reset your password",
          body:
            "Password reset. A verification link has been sent to your email.",
          btnOk: "Ok",
        }));
        setSubscriberEmail(true);
        setIsLoading(false);
        // console.log(authUser);
        // if(authUser.user.emailVerified){

        // }else{
        //   setLoginDetails(prevState => ({
        //       ...prevState,
        //       error: {
        //           message: 'Seems like your email id is not yet verified. To varify your email please click the link in the email we sent you at '+authUser.user.email
        //       }
        //   }));
        //   setResentEmail(true);
        // }

        // setSubscriberEmail(true)
      })
      .catch(function(error) {
        // Handle Errors here.
        setIsLoading(false);
        // console.log("RESET_PASSWORD_FAILURE");
        // console.log(error);
        var errorCode = error.code;
        var errorMessage = error.message;
        setResetPasswordDetails((prevState) => ({
          ...prevState,
          error: {
            message: error.message,
          },
        }));
        // ...
      });
  };

  const doSignInWithEmailAndPassword = () => {
    // console.log('Login success'+auth.currentUser.emailVerified())
    if (getLoginDetails.email == "") {
      setLoginDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Email ID should not be blank",
        },
      }));
      return;
    }
    if (!emailValidation(getLoginDetails.email)) {
      setLoginDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Email ID not valid",
        },
      }));
      return;
    }
    if (getLoginDetails.password == "") {
      setLoginDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Password should not be blank",
        },
      }));
      return;
    }
    setIsLoading(true);
    setNetworkTimeOut(40000);
    auth
      .signInWithEmailAndPassword(
        getLoginDetails.email.trim(),
        getLoginDetails.password
      )
      .then((authUser) => {
        // console.log("LOGIN_SUCCESS");
        fakeAuth.isAuthenticated = true;

        let response = fetchUserProfile();
        response.then(
          (resp) => {
            setUserInfo(resp);
            setIsLoading(false);
            localStorage.setItem("isLoggedIn", true);
            history.push("/dashboard", { userData: resp });
            ntwokChk = true;
            clearTimeout(resetNetwork);
          },
          function(error) {
            ntwokChk = false;
            clearTimeout(resetNetwork);
            setIsLoading(false);
          }
        );

        // if (authUser.user.emailVerified) {
        //     fakeAuth.isAuthenticated = true;

        //     let response = fetchUserProfile();
        //     response.then(resp => {
        //         setUserInfo(resp)
        //         setIsLoading(false);
        //         localStorage.setItem('isLoggedIn', true);
        //         history.push('/dashboard', { userData: resp });
        //         ntwokChk = true;
        //         clearTimeout(resetNetwork);
        //     }, function (error) {
        //         ntwokChk = false;
        //         clearTimeout(resetNetwork);
        //         setIsLoading(false);
        //     })

        // } else {
        //     ntwokChk = false;
        //     clearTimeout(resetNetwork);
        //     setIsLoading(false);
        //     setLoginDetails(prevState => ({
        //         ...prevState,
        //         error: {
        //             message: 'Seems like your email id is not yet verified. To varify your email please click the link in the email we sent you at ' + authUser.user.email
        //         }
        //     }));
        //     setResentEmail(true);
        //     localStorage.setItem('isLoggedIn', false);
        // }
      })
      .catch(function(error) {
        ntwokChk = false;
        clearTimeout(resetNetwork);
        // Handle Errors here.
        // console.log("LOGIN_FAILURE");
        setIsLoading(false);
        // console.log(error);
        var errorCode = error.code;
        var errorMessage = error.message;
        setLoginDetails((prevState) => ({
          ...prevState,
          error: {
            message: error.message,
          },
        }));
        // ...
      });
  };
  const successCallBackData = (data) => {
    if (data == "numberVerified") {
      // createAccount();
    } else {
      setShowMobileOtpValidation(false);
      setSubscriberEmail(false);
      setRegistrationDetails(REGISTRATION_INITIAL_STATE);
      setResetPasswordDetails(RESET_PASSWORD_INITIAL_STATE);
      setLoginDetails(LOGIN_INITIAL_STATE);
      setRegistration(false);
      setLogin(true);
    }

    window.scrollTo(0, 0);
  };
  const _calculateAge = (birthday) => {
    // birthday is a date
    var ageDifMs = Date.now() - new Date(birthday).getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };
  const emailValidation = (value) => {
    return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value);
  };
  const setNetworkTimeOut = (timer) => {
    ntwokChk = false;
    resetNetwork = setTimeout(() => {
      if (ntwokChk) {
        // console.log("INTERNET WORKING");
        setIsLoading(false);
      } else {
        // console.log("SHOW NETWORK ERROR");
        setIsLoading(false);
      }
    }, timer);
  };
  const doCreateUserWithEmailAndPassword = () => {
    // let response = IPInfoFetcher();
    // let ipInfo;
    // response.then(resp =>{
    //     // console.log(resp);
    //     ipInfo = resp;
    //   }, function(error) {
    //     // console.log(error);
    //   })
    if (getRegistrationDetails.firstName == "") {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "First name should not be blank",
        },
      }));
      return;
    }
    if (getRegistrationDetails.firstName.length > 20) {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "First name should be not be too longer",
        },
      }));
      return;
    }
    if (getRegistrationDetails.lastName == "") {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Last name should not be blank",
        },
      }));
      return;
    }
    if (getRegistrationDetails.lastName.length > 20) {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Last name should be not be too longer",
        },
      }));
      return;
    }
    if (getRegistrationDetails.gender == "") {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Please select gender",
        },
      }));
      return;
    }
    if (getRegistrationDetails.dob == "") {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Please select date of birth",
        },
      }));
      return;
    }
    if (_calculateAge(getRegistrationDetails.dob) < 18) {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Your age should be minimum 18 years",
        },
      }));
      return;
    }

    if (getRegistrationDetails.mobileNumber == "") {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Mobile number should not be blank",
        },
      }));
      return;
    }
    // if (getRegistrationDetails.mobileNumber.length > 12 || getRegistrationDetails.mobileNumber.length < 10) {
    if (getRegistrationDetails.mobileNumber.length !== 10) {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Mobile number is not valid.",
        },
      }));
      return;
    }
    if (getRegistrationDetails.email == "") {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Email ID should not be blank",
        },
      }));
      return;
    }
    if (!emailValidation(getRegistrationDetails.email)) {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Email ID not valid",
        },
      }));
      return;
    }
    if (getRegistrationDetails.password == "") {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Password should not be blank",
        },
      }));
      return;
    }
    if (
      getRegistrationDetails.password.length < 8 ||
      getRegistrationDetails.password.length > 14
    ) {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Password lenght should be in between 8 to 14 characters",
        },
      }));
      return;
    }
    if (
      getRegistrationDetails.password !== getRegistrationDetails.confirmPassword
    ) {
      setRegistrationDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Confirm password is not same as password",
        },
      }));
      return;
    }
    createAccount();
    // showOtpValidationModal();
    // if(getRegistrationDetails.firstName.length > 30){
    //     getRegistrationDetails.error={
    //         message:'First name is too long'
    //     }
    //     return;
    // }
    // auth.createUserWithEmailAndPassword('gajananbhusanoor@gmail.com', '8007shubham').then(authUser => {
    //     // Create a user in your Firebase realtime database
    //     console.log(authUser);
    //     console.log(authUser.user.uid);
    //     // return this.props.firebase.user(authUser.user.uid).set({
    //     //   username,
    //     //   email,
    //     //   roles,
    //     // });
    //   }).catch(function(error) {
    //       console.log(error)
    //     // Handle Errors here.
    //     // var errorCode = error.code;
    //     // var errorMessage = error.message;
    //     // ...
    //     // setRegistrationDetails({...getRegistrationDetails, error=error})
    //   });
  };
  const createAccount = () => {
    setIsLoading(true);
    setNetworkTimeOut(40000);
    auth
      .createUserWithEmailAndPassword(
        getRegistrationDetails.email.trim(),
        getRegistrationDetails.password
      )
      .then((authUser) => {
        // Create a user in your Firebase realtime database
        // return this.props.firebase.user(authUser.user.uid).set({
        //   username,
        //   email,
        //   roles,
        // });

        db.collection("MASTER_DATA")
          .doc(authUser.user.uid)
          .set({
            firstName: getRegistrationDetails.firstName,
            lastName: getRegistrationDetails.lastName,
            mobileNumber: getRegistrationDetails.mobileNumber,
            gender: getRegistrationDetails.gender,
            dob: getRegistrationDetails.dob,
            email: getRegistrationDetails.email,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            createdDate: new Date(),
          });
        let pushToken = "";
        if (Capacitor.isNativePlatform()) {
          if (localStorage.getItem("registrationToken") !== null) {
            pushToken = localStorage.getItem("registrationToken");
          }
        }

        const userData = {
          firstName: getRegistrationDetails.firstName,
          lastName: getRegistrationDetails.lastName,
          id: authUser.user.uid,
          gender: getRegistrationDetails.gender,
          dob: getRegistrationDetails.dob,
          email: getRegistrationDetails.email,
          mobileNumber: getRegistrationDetails.mobileNumber,
          age: _calculateAge(getRegistrationDetails.dob),
          isAdmin: false,
          isPaymentDone: false,
          isPrimeMember: false,
          isPhotoVerified: false,
          requstedProfileId: [],
          acceptedProfileId: [],
          receivedProfileId: [],
          isDisableAccount: false,
          isDeleteAccount: false,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          createdDate: new Date(),
          pushToken: pushToken,
        };
        db.collection("ENJOYER_PFOFILE")
          .doc(authUser.user.uid)
          .set({
            userData,
          });

        return authUser;
      }) // CODE for email verification link
      // .then(() => {
      //     return auth.currentUser.sendEmailVerification({
      //         url: 'https://www.hatkarmatrimony.com/secure-auth',
      //     });
      // })
      .then(() => {
        ntwokChk = true;
        clearTimeout(resetNetwork);
        setIsLoading(false);
        setSubscriberEmail(true);
        // console.log("Registration success");
      })
      .catch((error) => {
        ntwokChk = true;
        clearTimeout(resetNetwork);
        setIsLoading(false);
        // auth.currentUser.sendEmailVerification({
        //     url: 'https://www.hatkarmatrimony.com/',
        // });
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
          // console.log(ERROR_MSG_ACCOUNT_EXISTS);
        }
        // console.log(error);
        setRegistrationDetails((prevState) => ({
          ...prevState,
          error: {
            message: error.message,
          },
        }));

        // this.setState({ error });
      });
  };
  const showOtpValidationModal = () => {
    setShowMobileOtpValidation(false);
    setTimeout(() => {
      setShowMobileOtpValidation(true);
    }, 100);
  };
  const resendEmailVerificationLink = () => {
    auth.currentUser.sendEmailVerification({
      url: "https://www.hatkarmatrimony.com/secure-auth",
    });
    setSubscriberEmail(true);
  };
  const onLoginClick = () => {
    window.scrollTo(0, 0);
    setLogin(true);
    setDefaultContainer(false);
    setRegistration(false);
    setForgotPassword(false);
  };

  const onRegisterClick = () => {
    window.scrollTo(0, 0);
    setLogin(false);
    setDefaultContainer(false);
    setRegistration(true);
    setForgotPassword(false);
  };
  const clickOnForgotPassword = () => {
    window.scrollTo(0, 0);
    setLogin(false);
    setDefaultContainer(false);
    setRegistration(false);
    setForgotPassword(true);
  };
  const setTermsConditions = () => {
    setTS(!getTerms);
  };
  const Textcolor = {
    color: "#fff",
  };
  const radioButtonMargin = {
    margin: "0 10px 0 10px",
  };
  const handleOptionChange = (changeEvent) => {
    setGender({
      selectedOption: changeEvent.target.value,
    });
  };

  return (
    <>
      {isLoading ? <MyLoader /> : null}
      <ExamplesNavbar />
      {showMobileOtpValidation ? (
        <MobileVerificationModal callBack={successCallBackData} />
      ) : null}
      {!Capacitor.isNativePlatform() ? (
        <ProfilePageHeader />
      ) : (
        <div style={{ marginTop: "20%" }}></div>
      )}
      <div className="section profile-content">
        {isLogin ? (
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="6" lg="8">
                <Card className="card-register ml-auto mr-auto" md="6" lg="8">
                  <h3 className="title mx-auto" style={Textcolor}>
                    Welcome
                  </h3>
                  <hr />
                  <p className="text-center" style={{ color: "#c3c3c3" }}>
                    Hurry Up!! Let's login and find someone right for you.
                  </p>
                  <hr />
                  {/* <div className="social-line text-center">
                                    <Button
                                        className="btn-neutral btn-just-icon mr-1"
                                        color="facebook"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="fa fa-facebook-square" />
                                    </Button>
                                    <Button
                                        className="btn-neutral btn-just-icon mr-1"
                                        color="google"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="fa fa-google-plus" />
                                    </Button>
                                    <Button
                                        className="btn-neutral btn-just-icon"
                                        color="twitter"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="fa fa-twitter" />
                                    </Button>
                                </div> */}
                  <Form className="register-form">
                    <label style={Textcolor}>Email</label>
                    <Input
                      placeholder="Email"
                      type="text"
                      name="email"
                      value={getLoginDetails.email}
                      onChange={onHandleChangeLogin}
                    />
                    <label style={Textcolor}>Password</label>
                    <Input
                      placeholder="Password"
                      type="password"
                      name="password"
                      value={getLoginDetails.password}
                      onChange={onHandleChangeLogin}
                    />
                    <Button
                      block
                      className="btn-round"
                      color="danger"
                      onClick={() => doSignInWithEmailAndPassword()}
                    >
                      Login
                    </Button>
                    {getLoginDetails.error && (
                      <p style={{ color: "#fff" }}>
                        {getLoginDetails.error.message}
                      </p>
                    )}
                    <br />
                    {resentEmail && (
                      <p style={{ color: "#fff" }}>
                        Didnt receive the email? Check your spam mail or{" "}
                        <a
                          style={{ color: "#00ff43" }}
                          onClick={resendEmailVerificationLink}
                        >
                          click here to resend it.
                        </a>
                      </p>
                    )}
                  </Form>

                  <div className="forgot">
                    <Button
                      className="btn-link"
                      style={Textcolor}
                      onClick={() => onRegisterClick()}
                    >
                      Sign Up
                    </Button>
                    <Button
                      className="btn-link"
                      style={Textcolor}
                      onClick={() => clickOnForgotPassword()}
                    >
                      Forgot password?
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : null}
        {isRegistration ? (
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="6" lg="8">
                <Card className="card-register ml-auto mr-auto">
                  <h3 className="title mx-auto" style={Textcolor}>
                    Create your account
                  </h3>
                  <hr />
                  <p className="text-center" style={{ color: "#c3c3c3" }}>
                    Hurrey!! Let's create your account and find someone right
                    for you.
                  </p>
                  <hr />
                  {/* <div className="social-line text-center">
                                    <Button
                                        className="btn-neutral btn-just-icon mr-1"
                                        color="facebook"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="fa fa-facebook-square" />
                                    </Button>
                                    <Button
                                        className="btn-neutral btn-just-icon mr-1"
                                        color="google"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="fa fa-google-plus" />
                                    </Button>
                                    <Button
                                        className="btn-neutral btn-just-icon"
                                        color="twitter"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="fa fa-twitter" />
                                    </Button>
                                </div> */}
                  <Form className="register-form" style={Textcolor}>
                    <label style={Textcolor}>First Name</label>
                    <Input
                      placeholder="First Name"
                      name="firstName"
                      type="text"
                      value={getRegistrationDetails.firstName}
                      onChange={onHandleChangeRegister}
                    />
                    <label style={Textcolor}>Last Name</label>
                    <Input
                      placeholder="Last Name"
                      type="text"
                      name="lastName"
                      value={getRegistrationDetails.lastName}
                      onChange={onHandleChangeRegister}
                    />
                    <label style={Textcolor}>Gender</label>
                    {/* <Input placeholder="Last Name" type="text" /> */}
                    {/* <div className="radio">
                                        <label style={Textcolor}>
                                            <input type="radio" value="male" style={radioButtonMargin} checked={getGender.selectedOption == "male"} onChange={(e) => handleOptionChange(e)} />
                                            Male
                                        </label>
                                        <label style={{ marginLeft: 10, color: '#fff' }} >
                                            <input type="radio" value="female" style={radioButtonMargin} checked={getGender.selectedOption == "female"} onChange={(e) => handleOptionChange(e)} />
                                            Female
                                        </label>

                                    </div> */}
                    <div className="radio">
                      <label style={Textcolor}>
                        <input
                          type="radio"
                          value="male"
                          name="gender"
                          style={radioButtonMargin}
                          checked={getRegistrationDetails.gender == "male"}
                          onChange={onHandleChangeRegister}
                        />
                        Male
                      </label>
                      <label style={{ marginLeft: 10, color: "#fff" }}>
                        <input
                          type="radio"
                          value="female"
                          name="gender"
                          style={radioButtonMargin}
                          checked={getRegistrationDetails.gender == "female"}
                          onChange={onHandleChangeRegister}
                        />
                        Female
                      </label>
                    </div>
                    <label style={Textcolor}>Date of Birth</label>
                    <Input
                      placeholder="Date of Birth"
                      type="date"
                      name="dob"
                      value={getRegistrationDetails.dob}
                      onChange={onHandleChangeRegister}
                    />
                    <label style={Textcolor}>Email</label>
                    <Input
                      placeholder="Email"
                      type="email"
                      name="email"
                      value={getRegistrationDetails.email}
                      onChange={onHandleChangeRegister}
                    />
                    <label style={Textcolor}>Mobile Number</label>
                    <Input
                      placeholder="Mobile Number"
                      type="number"
                      name="mobileNumber"
                      value={getRegistrationDetails.mobileNumber}
                      onChange={onHandleChangeRegister}
                    />
                    <label style={Textcolor}>Password</label>
                    <Input
                      placeholder="Password"
                      type="password"
                      name="password"
                      value={getRegistrationDetails.password}
                      onChange={onHandleChangeRegister}
                    />
                    <label style={Textcolor}> Confirm Password</label>
                    <Input
                      placeholder="Confirm Password"
                      type="password"
                      name="confirmPassword"
                      value={getRegistrationDetails.confirmPassword}
                      onChange={onHandleChangeRegister}
                    />
                    <label
                      style={{ color: "#fff", marginLeft: "22px" }}
                      className="checkbox"
                    >
                      <Input
                        type="checkbox"
                        onClick={() => setTermsConditions()}
                      />{" "}
                      By clicking Register, you agree to our
                      <Link
                        to={{
                          pathname: "/terms-conditions",
                        }}
                      >
                        {" "}
                        <a style={{ color: "#51bcda" }}>Terms</a>{" "}
                      </Link>
                      and that you have read our
                      <Link
                        to={{
                          pathname: "/privacy-policy",
                        }}
                      >
                        {" "}
                        <a style={{ color: "#51bcda" }}>Data Use Policy</a>{" "}
                      </Link>
                      , including our Cookie Use.
                    </label>

                    <Button
                      block
                      className="btn-round"
                      color="danger"
                      disabled={getTerms}
                      onClick={() => doCreateUserWithEmailAndPassword()}
                    >
                      Register
                    </Button>
                    {getRegistrationDetails.error && (
                      <p style={{ color: "#fff" }}>
                        {getRegistrationDetails.error.message}
                      </p>
                    )}
                  </Form>

                  <div className="forgot">
                    <Button
                      className="btn-link"
                      style={Textcolor}
                      onClick={() => onLoginClick()}
                    >
                      Sign In
                    </Button>
                    <Button
                      className="btn-link"
                      style={Textcolor}
                      onClick={() => clickOnForgotPassword()}
                    >
                      Forgot password?
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : null}
        {forgotPassword ? (
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="6" lg="8">
                <Card className="card-register ml-auto mr-auto">
                  <h3 className="title mx-auto" style={Textcolor}>
                    Reset Password
                  </h3>
                  <hr />
                  <p className="text-center" style={{ color: "#c3c3c3" }}>
                    Reset your password by submitting registerd email id.
                  </p>
                  <hr />
                  {/* <div className="social-line text-center">
                                    <Button
                                        className="btn-neutral btn-just-icon mr-1"
                                        color="facebook"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="fa fa-facebook-square" />
                                    </Button>
                                    <Button
                                        className="btn-neutral btn-just-icon mr-1"
                                        color="google"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="fa fa-google-plus" />
                                    </Button>
                                    <Button
                                        className="btn-neutral btn-just-icon"
                                        color="twitter"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="fa fa-twitter" />
                                    </Button>
                                </div> */}
                  <Form className="register-form">
                    <label style={Textcolor}>Email</label>
                    <Input
                      placeholder="Email"
                      type="email"
                      name="email"
                      value={getResetPasswordDetails.email}
                      onChange={onHandleChangeResetPassword}
                    />
                    {/* <label>Password</label>
                  <Input placeholder="Password" type="password" /> */}
                    <Button
                      block
                      className="btn-round"
                      color="danger"
                      onClick={() => doPasswordReset()}
                    >
                      Submit
                    </Button>
                    {getResetPasswordDetails.error && (
                      <p style={{ color: "#fff" }}>
                        {getResetPasswordDetails.error.message}
                      </p>
                    )}
                  </Form>
                  <div className="forgot">
                    <Button
                      className="btn-link"
                      style={Textcolor}
                      onClick={() => onLoginClick()}
                    >
                      Sign In
                    </Button>
                    <Button
                      className="btn-link"
                      style={Textcolor}
                      onClick={() => onRegisterClick()}
                    >
                      Sign Up
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : null}
        {/* <Container>
                    <div className="owner">
                        <div className="avatar">
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/faces/joe-gardner-2.jpg")}
              />
            </div>
                        <div className="name">
                            <h4 className="title">
                                Jane Faker <br />
                            </h4>
                            <h6 className="description">Hello Producer</h6>
                        </div>
                    </div>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="6">
                            <p>
                                An artist of considerable range, Jane Faker — the name taken by
                                Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs
                                and records all of his own music, giving it a warm, intimate
                                feel with a solid groove structure.
              </p>
                            <br />
                            <Button className="btn-round" color="default" outline>
                                <i className="fa fa-cog" /> Settings
              </Button>
                        </Col>
                    </Row>
                    <br />
                    <div className="nav-tabs-navigation">
                        <div className="nav-tabs-wrapper">
                            <Nav role="tablist" tabs>
                                <NavItem>
                                    <NavLink
                                        className={activeTab === "1" ? "active" : ""}
                                        onClick={() => {
                                            toggle("1");
                                        }}
                                    >
                                        Follows
                  </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={activeTab === "2" ? "active" : ""}
                                        onClick={() => {
                                            toggle("2");
                                        }}
                                    >
                                        Following
                  </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                    <TabContent className="following" activeTab={activeTab}>
                        <TabPane tabId="1" id="follows">
                            <Row>
                                <Col className="ml-auto mr-auto" md="6">
                                    <ul className="list-unstyled follows">
                                        <li>
                                            <Row>
                                                <Col className="ml-auto mr-auto" lg="2" md="4" xs="4">
                                                    <img
                                                        alt="..."
                                                        className="img-circle img-no-padding img-responsive"
                                                        src={require("assets/img/faces/clem-onojeghuo-2.jpg")}
                                                    />
                                                </Col>
                                                <Col className="ml-auto mr-auto" lg="7" md="4" xs="4">
                                                    <h6>
                                                        Flume <br />
                                                        <small>Musical Producer</small>
                                                    </h6>
                                                </Col>
                                                <Col className="ml-auto mr-auto" lg="3" md="4" xs="4">
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                defaultChecked
                                                                defaultValue=""
                                                                type="checkbox"
                                                            />
                                                            <span className="form-check-sign" />
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </li>
                                        <hr />
                                        <li>
                                            <Row>
                                                <Col className="mx-auto" lg="2" md="4" xs="4">
                                                    <img
                                                        alt="..."
                                                        className="img-circle img-no-padding img-responsive"
                                                        src={require("assets/img/faces/ayo-ogunseinde-2.jpg")}
                                                    />
                                                </Col>
                                                <Col lg="7" md="4" xs="4">
                                                    <h6>
                                                        Banks <br />
                                                        <small>Singer</small>
                                                    </h6>
                                                </Col>
                                                <Col lg="3" md="4" xs="4">
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input defaultValue="" type="checkbox" />
                                                            <span className="form-check-sign" />
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane className="text-center" tabId="2" id="following">
                            <h3 className="text-muted">Not following anyone yet :(</h3>
                            <Button className="btn-round" color="warning">
                                Find artists
              </Button>
                        </TabPane>
                    </TabContent>
                </Container> */}
      </div>
      <DemoFooter />
      {showConfirmEmailAlert ? (
        <AlertModal data={alertParams} callBack={successCallBackData} />
      ) : null}
    </>
  );
}

export default ProfilePage;
