const authReducer = (state = false, action) => {
    switch (action.type) {
        case 'SHOWLOGINCOMPONENT':
            return action.payload;

        case 'SHOWSIGNUPCOMPONENT':
            return action.payload;

        case 'ISLOGGEDIN':
            return action.payload;

        default:
            return state;

    }
}

export default authReducer;