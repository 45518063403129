/* eslint-disable */
import React, { useState, useEffect, useRef, useContext } from 'react';



// reactstrap components

import {
    Button,
    Label,
    FormGroup,
    Input,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
} from "reactstrap";
import Pagination from '../pagination/Pagination'
import Posts from '../pagination/Posts'



function ProfileList(props) {
    //   console.log(props.profiles)
    const [posts, setPosts] = useState(props.profiles);
    const [loading, setLoading] = useState(false);
    const [UID, setUID] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setpostsPerPage] = useState(2);
    const myRef = useRef(null)
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
    useEffect(() => {
        if (props.profiles !== undefined) {
            if (props.profiles.length <= 200) {
                setpostsPerPage(40);
            } else if (props.profiles.length > 200 && props.profiles.length <= 500) {
                setpostsPerPage(80);
            } else if (props.profiles.length > 500 && props.profiles.length <= 1000) {
                setpostsPerPage(100);
            } else if (props.profiles.length > 1000 && props.profiles.length <= 2000) {
                setpostsPerPage(250);
            } else if (props.profiles.length > 2000) {
                // var tempLenght = props.profiles.length / 15
                // tempLenght = tempLenght.toFixed();
                setpostsPerPage(400);
            }
        }
        if (window.localStorage.getItem('index') && window.localStorage.getItem('index') !== null) {
            setCurrentPage(window.localStorage.getItem('index'));
        }
        // setPosts(props.profiles);
        // setPosts(prevArray => [...prevArray, props.profiles])
    });

    const postCallBack = (data) => {
        props.callBack(data);
    }
    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = props.profiles.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = pageNumber => {
        
        scrollToRef(myRef)
        window.localStorage.setItem('index', pageNumber);
        setCurrentPage(pageNumber);
    }
    return (
        <>

            <div className="section profile-content" ref={myRef}>
                <Container>

                    <div className='container mt-5'>
                        <Posts posts={currentPosts} loading={loading} callBack={postCallBack} />
                        <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={props.profiles.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    </div>
                </Container>
            </div>
        </>
    );
}

export default ProfileList;
