import React from "react";
import Skeleton from "react-loading-skeleton";

// reactstrap components
/* eslint-disable */
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
const SkeletonCard = () => {
  return (
    <div className="section profile-content ">
      <Container>
        <section>
          {/* <h2 className="section-title">
          <Skeleton duration={1} height={30} width={300} />
        </h2>  */}
          <div style={{ textAlign: "center" }}>
            <div className="card">
              <Skeleton
                style={{ textAlign: "center" }}
                circle={true}
                height={120}
                width={120}
              />{" "}
              &nbsp;
              <p className="card-channel">
                <Skeleton width={`60%`} />
              </p>
              <p className="card-channel">
                <Skeleton width={`80%`} />
              </p>
            </div>
            {Array(9)
              .fill()
              .map((item, index) => (
                <div className="card" key={index}>
                  <Skeleton height={110} />

                  <h4 className="card-title">
                    <Skeleton circle={true} height={50} width={50} /> &nbsp;
                    <Skeleton height={36} width={`80%`} />
                  </h4>
                  <p className="card-channel">
                    <Skeleton width={`60%`} />
                  </p>
                  <div className="card-metrics">
                    <Skeleton width={`90%`} />
                  </div>
                </div>
              ))}
          </div>
        </section>
      </Container>
    </div>
  );
};

export default SkeletonCard;
