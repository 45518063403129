/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect } from "react";
// import Firebase from 'firebase';
import MyLoader from "../../components/Modals/Spinner";

// reactstrap components

import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from "reactstrap";
// import { Link } from '@material-ui/core';

// core components

const AppUpdateModal = (props) => {
  let pageHeader = React.createRef();

  const [modal, setModal] = useState(true);
  const [modalParams, setModalParams] = useState(props.data);

  const toggle = () => setModal(!modal);
  // console.log(props);
  // {title, body, btnOk } = ;
  React.useEffect(() => {
    // if (window.innerWidth < 991) {
    //   const updateScroll = () => {
    //     let windowScrollTop = window.pageYOffset / 3;
    //     pageHeader.current.style.transform =
    //       "translate3d(0," + windowScrollTop + "px,0)";
    //   };
    //   window.addEventListener("scroll", updateScroll);
    //   return function cleanup() {
    //     window.removeEventListener("scroll", updateScroll);
    //   };
    // }
  });

  const closeModal = (returnParam) => {
    if (returnParam !== "UPDATE_APP") {
      setModal(false);
    }
    props.callBack(returnParam);
  };

  const style = {
    margin: "20px",
  };
  const backgroundColor = {
    backgroundColor: "#f5f5f5",
    justifyContent: "space-around",
  };

  const Textcolor = {
    color: "#f5593d",
  };
  return (
    <Modal isOpen={modal} size="sm">
      {/* <MyLoader/> */}
      <div>
        {/* <div className="section"> */}
        {/* <Container> */}
        <ModalHeader style={backgroundColor}>App Update</ModalHeader>
        <ModalBody style={backgroundColor}>
          <Form className="register-form">
            <h5 className="title">{modalParams.title}</h5>

            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                {modalParams.img ? (
                  <img
                    className="img-thumbnail img-responsive "
                    src={require("assets/image/" + modalParams.img)}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Button
              className="btn-round"
              color="info"
              type="button"
              outline
              style={{ margin: "15px" }}
              onClick={(event) => closeModal("UPDATE_APP")}
              block
            >
              Update Now
            </Button>
            <br />
            {modalParams.isOptional ? (
              <Button
                className="btn-round"
                color="info"
                type="button"
                outline
                style={{ margin: "15px" }}
                onClick={(event) => closeModal("")}
                block
              >
                Update Later
              </Button>
            ) : null}
          </Form>
        </ModalBody>
        {/* <ModalFooter style={backgroundColor}>
        {modalParams.btnCancel ? <Button className="btn-round" color="default" type="button"  outline style={{ margin: '15px' }} onClick={event => closeModal(modalParams.btnCancel)}>
            {modalParams.btnCancel}
          </Button> : null}
          {modalParams.btnOk ? <Button   className="btn-round" color="info" type="button"  outline style={{ margin: '15px' }} onClick={event => closeModal(modalParams.btnOk)}>
            {modalParams.btnOk}
          </Button> : null}
   

        </ModalFooter> */}
        {/* </Container> */}
        {/* </div> */}
      </div>
    </Modal>
  );
};

export default AppUpdateModal;
