/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect } from "react";
// import Firebase from 'firebase';
import MyLoader from "../../components/Modals/Spinner";

// reactstrap components

import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from "reactstrap";
// import { Link } from '@material-ui/core';

// core components

const CommunityConnectModal = (props) => {
  let pageHeader = React.createRef();

  const [modal, setModal] = useState(true);
  const [modalParams, setModalParams] = useState(props.data);

  const toggle = () => setModal(!modal);
  // console.log(props);
  // {title, body, btnOk } = ;
  React.useEffect(() => {
    // if (window.innerWidth < 991) {
    //   const updateScroll = () => {
    //     let windowScrollTop = window.pageYOffset / 3;
    //     pageHeader.current.style.transform =
    //       "translate3d(0," + windowScrollTop + "px,0)";
    //   };
    //   window.addEventListener("scroll", updateScroll);
    //   return function cleanup() {
    //     window.removeEventListener("scroll", updateScroll);
    //   };
    // }
  });

  const closeModal = (returnParam) => {
    setModal(false);
    props.callBack(returnParam);
  };

  const style = {
    margin: "20px",
  };
  const backgroundColor = {
    backgroundColor: "#f5f5f5",
    justifyContent: "space-around",
  };

  const Textcolor = {
    color: "#f5593d",
  };
  var w = window.innerWidth;
  return (
    <Modal isOpen={modal} size={w > 600 ? "lg" : "sm"} centered>
      {/* <MyLoader/> */}
      <div>
        {/* <div className="section"> */}
        {/* <Container> */}
        <ModalHeader style={backgroundColor}>
          {modalParams.ITEMS[0].title}
        </ModalHeader>
        <ModalBody
          style={backgroundColor}
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
        >
          <Form className="register-form">
            <h4 className="title" style={{ textAlign: "center" }}>
              {modalParams.ITEMS[0].short_Description}
            </h4>

            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                {modalParams.img ? (
                  <img
                    className="img-thumbnail img-responsive "
                    src={require("assets/image/" + modalParams.img)}
                  />
                ) : (
                  ""
                )}
                {modalParams.ITEMS[0].image_URL ? (
                  <img
                    className="img-thumbnail img-responsive "
                    src={modalParams.ITEMS[0].image_URL}
                    onClick={(event) => closeModal("COMMUNITY_CONNECTS")}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter style={backgroundColor}>
          <Button
            className="btn-round"
            color="default"
            type="button"
            outline
            style={{ margin: "15px" }}
            onClick={(event) => closeModal("")}
          >
            Close
          </Button>
          <Button
            className="btn-round"
            color="info"
            type="button"
            outline
            style={{ margin: "15px" }}
            onClick={(event) => closeModal("COMMUNITY_CONNECTS")}
          >
            Read More
          </Button>
        </ModalFooter>
        {/* </Container> */}
        {/* </div> */}
      </div>
    </Modal>
  );
};

export default CommunityConnectModal;
