/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { db, auth, storage } from "../../firebase/firebase";
import {
  NetworkDetector,
  fetchUserProfile,
  EncodeData,
  DecodeData,
} from "../../components/Modals/AsyncTask";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardTitle,
  CardText,
  Badge,
  CardHeader,
  CardFooter,
  Progress,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import { userInfoContext } from "../../context/userContext";
import SkeletonCard from "components/SkeletonCard/SkeletonCard";
import TroubleFetching from "components/TroubleFetching/TroubleFetching";
import ImgsViewer from "react-images-viewer";
import { GLOBLE_CONSTANT } from "config/constant";
import { AdMob, AdOptions } from "@capacitor-community/admob";
import { Capacitor } from "@capacitor/core";
import ImageViewerModal from "components/Modals/imageVieweModal";
function UserDetails(props) {
  let pageHeader = React.createRef();
  const [activeTab, setActiveTab] = useState("1");
  const [userData, setUserData] = useState({});
  const [photoURL, setPhotoURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeleton, setSkeleton] = useState(true);
  const [poorConnection, setPoorConnection] = useState(false);
  const { userInfo, setUserInfo } = useContext(userInfoContext);
  const [showProflePicture, setShowProflePicture] = useState(false);
  const [imgSRC, setImgSRC] = useState("");
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [imageViewParams, setImageViewParams] = useState("");
  const [imageViewerModal, setImageViewerModal] = useState(false);
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  const adMobIn = async () => {
    try {
      await AdMob.initialize();
    } catch (error) {
      console.log("ADMOB INITIALIZE ERROR");
    }

    const options: AdOptions = {
      adId: GLOBLE_CONSTANT.rewardVideoAd_ID,
    };
    await AdMob.prepareRewardVideoAd(options);
    // const options_: AdOptions = {
    //   adId: GLOBLE_CONSTANT.interstitial_Add_ID,
    // };
    // // Prepare interstitial banner
    // await AdMob.prepareInterstitial(options_);

    setTimeout(() => {
      showRrewardVideo();
    }, 4000);
  };
  const showRrewardVideo = async () => {
    await AdMob.showRewardVideoAd();
  };
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  useEffect(() => {
    setUserData(props.location.state.userData);
    setUserInfo(props.location.state.userInfo);

    // if(props.userData && props.userData.id !== undefined){
    //     EncodeData(props.userData.id, userInfo.email).then(encodedString =>{
    //         window.localStorage.setItem('refID', encodedString)
    //       })

    // }else{
    //     initialFetch();
    // }
    // setPoorConnection(false);
    // setSkeleton(false)
    // initialFetch();
    setSkeleton(false);
    setPhotoURL("");
    window.scrollTo(0, 0);
    if (Capacitor.isNativePlatform()) {
      // adMobIn();
    }
    // setDataLoaded(false);
  }, []);

  function initialFetch() {
    // setSkeleton(true)
    // if(userInfo){
    //     console.log('userInfo has data')
    //     fetchData(userInfo);
    // }else{
    //     console.log('userInfo data not found')
    //     let response = fetchUserProfile();
    //     response.then(resp =>{
    //         setUserInfo(resp);
    //         fetchData(resp);
    //     }, function(error) {
    //         console.log('Internet Disconnected')
    //        setSkeleton(false)
    //        setDataLoaded(false);
    //          if(error == 'Internet'){
    //          setPoorConnection(true);
    //          }
    //     })
    // }
    // let refID = window.localStorage.getItem('refID');
    // DecodeData(resp).then(resp2 =>{
    //     console.log(resp2)
    //   })
    // db.collection('ENJOYER_PFOFILE').doc(user.uid).get()
    // .then(function (doc) {
    //     if (doc.exists) {
    //     resolve(doc.data().userData);
    //     } else {
    //     // doc.data() will be undefined in this case
    //     console.log("No such document!");
    //     reject('No such document!');
    //     }
    // });
  }
  useEffect(() => {}, [userData], [photoURL]);
  //   useEffect(() => {
  //   setTimeout(() => {
  //       console.log(isDataLoaded)
  //       if(isDataLoaded){
  //         console.log('ALL OK')
  //         setSkeleton(false)
  //         setPoorConnection(false);
  //       }else{
  //         console.log('SHOW INTERNET ERROR')
  //         setSkeleton(false);
  //         setPoorConnection(true);
  //       }
  //     }, 20000);
  // }, [isDataLoaded]);
  const fetchData = (data) => {
    // console.log(props.location.state.userData)
    if (props.location.state.userData) {
      EncodeData(props.location.state.userData.id, data.email).then(
        (encodedString) => {
          window.localStorage.setItem("refID", encodedString);
          setSkeleton(false);
          setPoorConnection(false);
          setDataLoaded(true);
        }
      );
    } else {
      var newID = window.localStorage.getItem("refID");
      DecodeData(newID, data.email).then((decodeString) => {
        let result = NetworkDetector();
        result.then(
          (result) => {
            db.collection("ENJOYER_PFOFILE")
              .doc(decodeString)
              .get()
              .then(function(doc) {
                // console.log(doc.exists)
                if (doc.exists) {
                  setUserData(doc.data().userData);
                  setSkeleton(false);
                  setPoorConnection(false);
                  setDataLoaded(true);
                }
              });
          },
          function(error) {
            console.log("Internet Disconnected");
            setSkeleton(false);
            setDataLoaded(false);
            if (error == "Internet") {
              setPoorConnection(true);
            }
          }
        );
      });
    }
  };
  function closeViewer() {
    setShowProflePicture(false);
  }
  function showProfilePic() {
    let imageData = {};
    if (userData.photoURL) {
      imageData.image_URL = userData.photoURL;
      imageData.title = userData.firstName + " " + userData.lastName;
    }
    setImageViewParams(imageData);
    setImageViewerModal(false);
    setTimeout(() => {
      setImageViewerModal(true);
    }, 100);
  }
  function inArray(needle, haystack) {
    if (haystack !== undefined) {
      var length = haystack.length;
      for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) return true;
      }
      return false;
    } else {
      return false;
    }
  }

  const cntImage = 0;
  const imgWidth = 220;
  const open = true;

  return (
    <>
      {imageViewerModal ? <ImageViewerModal data={imageViewParams} /> : null}
      {isSkeleton ? (
        <SkeletonCard />
      ) : (
        <div>
          {poorConnection ? (
            <TroubleFetching callBack={initialFetch} />
          ) : (
            <div>
              <ExamplesNavbar />
              <ProfilePageHeader />
              <div
                className="section profile-content"
                style={{ textTransform: "capitalize" }}
              >
                <Container>
                  <div className="owner">
                    <div className="avatar">
                      {!userData.photoURL && userData.gender == "male" ? (
                        <img
                          alt={userData.firstName}
                          className="img-circle img-no-padding img-responsive"
                          // src={userData.photoURL}
                          src={require("assets/image/default_User_Icon_Men.png")}
                          style={{ width: "150px", height: "150px" }}
                        />
                      ) : null}
                      {!userData.photoURL && userData.gender == "female" ? (
                        <img
                          alt={userData.firstName}
                          className="img-circle img-no-padding img-responsive"
                          // src={userData.photoURL}
                          style={{ width: "150px", height: "150px" }}
                          src={require("assets/image/default_User_Icon_Women.png")}
                        />
                      ) : null}
                      {userData.photoURL ? (
                        <img
                          alt={userData.firstName}
                          style={{ width: "150px", height: "150px" }}
                          className="img-circle img-no-padding img-responsive"
                          src={userData.photoURL}
                          onClick={showProfilePic}
                        />
                      ) : null}
                    </div>
                    <div className="name">
                      <h4 className="title">
                        {userData.firstName} {userData.lastName} <br />
                      </h4>
                      {/* <h6 className="description">Hello Producer</h6> */}
                      {/* <input type="file"  accept="image/*" onChange={event => setImage( event.target.files[0])} /> */}
                    </div>
                  </div>
                </Container>

                <Container>
                  <br />
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav role="tablist" tabs>
                        <NavItem>
                          <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            Personal Details
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  {/* Tab panes */}
                  <TabContent className="following" activeTab={activeTab}>
                    <TabPane tabId="1" id="follows">
                      <Row>
                        <Col className="ml-auto mr-auto" md="8">
                          <ul className="list-unstyled follows">
                            <li>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Full Name</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>
                                      {userData.firstName} {userData.lastName}
                                    </small>
                                  </p>
                                </Col>
                              </Row>

                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Date of Birth</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.dob}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Gender</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.gender}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Height</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.height}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Weight</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.weight}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Marital Status</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.maritalStatus}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Body Type</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.bodyType}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Diet</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.diet}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Native Place</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.nativePlace}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Current Place</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.currentPlace}</small>
                                  </p>
                                </Col>
                              </Row>
                            </li>

                            {/* <hr /> */}
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane className="text-center" tabId="2" id="following">
                      <h3 className="text-muted">
                        Not following anyone yet :(
                      </h3>
                      <Button className="btn-round" color="warning">
                        Find artists
                      </Button>
                    </TabPane>
                  </TabContent>
                  <br />
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav role="tablist" tabs>
                        <NavItem>
                          <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            Professional Details
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  {/* Tab panes */}
                  <TabContent className="following" activeTab={activeTab}>
                    <TabPane
                      tabId="1"
                      id="follows"
                      style={{ minHeight: "100px" }}
                    >
                      <Row>
                        <Col className="ml-auto mr-auto" md="8">
                          <ul className="list-unstyled follows">
                            <li>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Profession</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.profession}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Annual Income</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.annualIncome}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Employed In</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.employedIn}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Education</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.education}</small>
                                  </p>
                                </Col>
                              </Row>
                            </li>

                            {/* <hr /> */}
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                  {/* <br />
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav role="tablist" tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Horoscope Details
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <TabContent className="following" activeTab={activeTab}>
            <TabPane tabId="1" id="follows" style={{ minHeight: '100px' }}>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <ul className="list-unstyled follows">
                    <li>
                      <Row>
                        <Col className="ml-auto mr-auto" lg="4" md="4" xs="4">
                          <p>Time of Birth</p>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="1" md="1" xs="1">
                          <p>:</p>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="7" md="7" xs="7">
                          <p> <small>Musical Producer</small></p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="ml-auto mr-auto" lg="4" md="4" xs="4">
                          <p>Place of Birth</p>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="1" md="1" xs="1">
                          <p>:</p>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="7" md="7" xs="7">
                          <p> <small>Musical Producer</small></p>
                        </Col>
                      </Row>

                    </li>
                  </ul>
                </Col>
              </Row>
            </TabPane>


          </TabContent> */}
                  <br />
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav role="tablist" tabs>
                        <NavItem>
                          <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            Family Details
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  {/* Tab panes */}
                  <TabContent className="following" activeTab={activeTab}>
                    <TabPane
                      tabId="1"
                      id="follows"
                      style={{ minHeight: "100px" }}
                    >
                      <Row>
                        <Col className="ml-auto mr-auto" md="8">
                          <ul className="list-unstyled follows">
                            <li>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Father Status</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.fatherStatus}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Mother Status</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.motherStatus}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Brothers</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.brothers}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Sisters</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.sisters}</small>
                                  </p>
                                </Col>
                              </Row>
                            </li>

                            {/* <hr /> */}
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                  <br />

                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav role="tablist" tabs>
                        <NavItem>
                          <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            Contact Details
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  {/* Tab panes */}

                  <TabContent className="following" activeTab={activeTab}>
                    <TabPane
                      tabId="1"
                      id="follows"
                      style={{ minHeight: "100px" }}
                    >
                      <Row>
                        <Col className="ml-auto mr-auto" md="8">
                          <ul className="list-unstyled follows">
                            <li>
                              {inArray(
                                props.location.state.userData.id,
                                props.location.state.userInfo.acceptedProfileId
                              ) ? (
                                <div>
                                  <Row>
                                    <Col
                                      className="ml-auto mr-auto"
                                      lg="4"
                                      md="4"
                                      xs="4"
                                    >
                                      <p>Full Name</p>
                                    </Col>
                                    <Col
                                      className="ml-auto mr-auto"
                                      lg="1"
                                      md="1"
                                      xs="1"
                                    >
                                      <p>:</p>
                                    </Col>
                                    <Col
                                      className="ml-auto mr-auto"
                                      lg="7"
                                      md="7"
                                      xs="7"
                                    >
                                      <p>
                                        {" "}
                                        <small>
                                          {userData.firstName}{" "}
                                          {userData.lastName}
                                        </small>
                                      </p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      className="ml-auto mr-auto"
                                      lg="4"
                                      md="4"
                                      xs="4"
                                    >
                                      <p>Email Address</p>
                                    </Col>
                                    <Col
                                      className="ml-auto mr-auto"
                                      lg="1"
                                      md="1"
                                      xs="1"
                                    >
                                      <p>:</p>
                                    </Col>
                                    <Col
                                      className="ml-auto mr-auto"
                                      lg="7"
                                      md="7"
                                      xs="7"
                                    >
                                      <p>
                                        {" "}
                                        <small>{userData.email}</small>
                                      </p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      className="ml-auto mr-auto"
                                      lg="4"
                                      md="4"
                                      xs="4"
                                    >
                                      <p>Mobile Number</p>
                                    </Col>
                                    <Col
                                      className="ml-auto mr-auto"
                                      lg="1"
                                      md="1"
                                      xs="1"
                                    >
                                      <p>:</p>
                                    </Col>
                                    <Col
                                      className="ml-auto mr-auto"
                                      lg="7"
                                      md="7"
                                      xs="7"
                                    >
                                      <p>
                                        {" "}
                                        <small>{userData.mobileNumber}</small>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              ) : (
                                <div>
                                  <Row>
                                    <Col className="ml-auto mr-auto text-center">
                                      <p>
                                        Contact info is not visible to you
                                        unless you both accept each others
                                        requests.
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </li>

                            {/* <hr /> */}
                          </ul>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Container>
              </div>

              <DemoFooter />
            </div>
          )}{" "}
        </div>
      )}
    </>
  );
}
// const NewComponent = NetworkDetector(ProfilePage);
// export default  NetworkDetector(ProfilePage);
export default UserDetails;
