/* eslint-disable */
import React, {useState,useEffect,useMemo} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";

import "assets/css/material-dashboard-react.css?v=1.8.0";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
// import RegisterPage from "views/examples/RegisterPage.js";
// import LoginPage from "views/examples/LoginPage";
import SecureAuth from "views/examples/SecureAuth";
import PageNotFound from "views/examples/PageNotFound";
import DashboardPage from "views/examples/DashboardPage";
import PrivateRoute from "views/examples/PrivateRoute";
import PrivacyPolicy from "views/examples/PrivacyPolicy";
import TermCondition from "views/examples/TermCondition";
import SitemapPage from "views/examples/Sitemap";
import SettingPage from "views/examples/Settings";
// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import { createStore } from 'redux';
import allReducers from '../reducers';
import { Provider } from 'react-redux';
import {userInfoContext, userListContext} from '../context/userContext'
import UserDetails from "views/examples/UserDetails";
import ProfileViews from "views/examples/ProfileViews";
import AboutPage from "views/examples/AboutPage";
import HatkarCommitteePage from "views/examples/HatkarCommittee";
import NirmalGramPurskarPage from "views/examples/NirmalGramPurskar";
import CommunityEvents from "views/examples/CommunityEvents";
import Download from "views/examples/Download";

const Router = ()=>{
const store = createStore(allReducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
// const [isAuthenticated, setAuthenticated] = useState(false);
const rootElement = document.getElementById("root");
const [userInfo, setUserInfo] = useState(null);
const [userList, setUserList] = useState(null);

const user = useMemo(() => ({ userInfo, setUserInfo }), [userInfo, setUserInfo]);
const users = useMemo(() => ({ userList, setUserList }), [userList, setUserList]);

// const isAuthenticated= localStorage.getItem('isLoggedIn');
const isAuthenticated= localStorage.getItem('isLoggedIn');

    return (
        <Provider store={store}>
        <userInfoContext.Provider  value={user}>
        <userListContext.Provider  value={users}>
       <BrowserRouter>
         <Switch>
           <Route exact path="/" component={LandingPage} />
           <Route path="/secure-auth" component={SecureAuth} />
           {/* <PrivateRoute path="/register-page" component={RegisterPage} isAuth={isAuthenticated} /> */}
           {/* <PrivateRoute path="/login-page" component={LoginPage} isAuth={isAuthenticated} /> */}
           <Route path="/privacy-policy" component={PrivacyPolicy} />
           <Route path="/terms-conditions" component={TermCondition} />
           <Route path="/sitemap" component={SitemapPage} />
           <Route path="/About-us" component={AboutPage} />
           <Route path="/Download" component={Download} />
           <Route path="/Hatkar-Committee-Maharashtra-State" component={HatkarCommitteePage} />
           <Route path="/Community-Events" component={CommunityEvents} />
           <Route path="/Nirmal-Gram-Purskar-Gajanan-Ganapati-Bhusanur-4th-May-2007" component={NirmalGramPurskarPage} />
           <PrivateRoute path="/user-setting" component={SettingPage} isAuth={isAuthenticated} />
           <PrivateRoute path="/profile-page" component={ProfilePage} isAuth={isAuthenticated} />
           <PrivateRoute path="/user-details" component={UserDetails} isAuth={isAuthenticated} />
           <PrivateRoute path="/profile-views" component={ProfileViews} isAuth={isAuthenticated} />
           {/* <PrivateRoute path="/profile-page" component={ProfilePage}>
             </PrivateRoute> */}
             <PrivateRoute path='/dashboard' component={DashboardPage} isAuth={isAuthenticated} />
             <Route  component={PageNotFound} />
           {/* <PrivateRoute path='/protected' component={Protected} /> */}
         </Switch>
       </BrowserRouter>
       </userListContext.Provider>
     </userInfoContext.Provider>
     </Provider>
    );
  }

export default Router;
