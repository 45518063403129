/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect } from "react";
// import Firebase from 'firebase';
import MyLoader from "../../components/Modals/Spinner";
import * as firebase from "firebase/app";
import { db, remoteConfig } from "../../firebase/firebase";
// reactstrap components

import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from "reactstrap";
// import { Link } from '@material-ui/core';

// core components

const InquiryModal = (props) => {
  let pageHeader = React.createRef();

  const [modal, setModal] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const toggle = () => setModal(!modal);
  const LOGIN_INITIAL_STATE = {
    mobileNumber: "",
    name: "",
    email: "",
    error: null,
    success: null,
  };
  const [inquiryData, setInquiryData] = useState(LOGIN_INITIAL_STATE);
  // console.log(props);
  // {title, body, btnOk } = ;
  React.useEffect(() => {
    // if (window.innerWidth < 991) {
    //   const updateScroll = () => {
    //     let windowScrollTop = window.pageYOffset / 3;
    //     pageHeader.current.style.transform =
    //       "translate3d(0," + windowScrollTop + "px,0)";
    //   };
    //   window.addEventListener("scroll", updateScroll);
    //   return function cleanup() {
    //     window.removeEventListener("scroll", updateScroll);
    //   };
    // }
  });
  const emailValidation = (value) => {
    return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value);
  };
  const submitDetails = () => {
    if (inquiryData.name == "" || inquiryData.name.length >= 40) {
      setInquiryData((prevState) => ({
        ...prevState,
        error: {
          message: "Please enter valid name",
        },
      }));
      return;
    }
    if (
      inquiryData.email == "" ||
      inquiryData.email.length >= 40 ||
      !emailValidation(inquiryData.email)
    ) {
      setInquiryData((prevState) => ({
        ...prevState,
        error: {
          message: "Email id is not valid.",
        },
      }));
      return;
    }
    if (
      inquiryData.mobileNumber == "" ||
      inquiryData.mobileNumber.length !== 10
    ) {
      setInquiryData((prevState) => ({
        ...prevState,
        error: {
          message:
            "Mobile Number is not valid. Please enter 10 Digit Mobile Number.",
        },
      }));
      return;
    }

    setToFirebase();
  };

  const setToFirebase = () => {
    const dbName = props && props.data ? props.data.type : "mentor_connect";
    setIsLoading(true);
    db.collection(dbName)
      .doc(inquiryData.mobileNumber)
      .set({
        name: inquiryData.name,
        mobileNumber: inquiryData.mobileNumber,
        email: inquiryData.email,
      })
      .then(function(docRef) {
        setIsLoading(false);
        alert("Inquiry Request Submitted. You will hear soon from us.");
        closeModal();
      })
      .catch(function(error) {
        //   console.error("Error adding document: ", error);
        setIsLoading(false);

        alert(
          "OOPS!! At this moment we are facing some issues. Please try after some time."
        );
      });
  };
  const closeModal = (returnParam) => {
    setModal(false);
    props.callBack(returnParam);
  };
  const onHandleChangeOTP = (event) => {
    // setRegistrationDetails({ [event.target.name]: event.target.value });
    const { name, value } = event.target;
    setInquiryData((prevState) => ({
      ...prevState,
      [name]: value,
      error: null,
      success: null,
    }));
  };

  const style = {
    margin: "20px",
  };
  const backgroundColor = {
    backgroundColor: "#f5f5f5",
    justifyContent: "space-around",
  };

  const Textcolor = {
    color: "#f5593d",
  };
  var w = window.innerWidth;
  return (
    <Modal isOpen={modal} size={w > 600 ? "lg" : "sm"} centered>
      {/* <MyLoader/> */}
      {isLoading ? <MyLoader /> : null}
      <div>
        <ModalHeader style={backgroundColor}>
          {props && props.data ? props.data.title : "Inquiry"}
        </ModalHeader>
        <ModalBody
          style={backgroundColor}
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
        >
          <br />
          <Form className="register-form">
            <label>Enter Your Name</label>
            <Input
              placeholder="Your Name"
              type="text"
              name="name"
              value={inquiryData.name}
              onChange={onHandleChangeOTP}
            />{" "}
            <br />
            <label>Enter Your Email</label>
            <Input
              placeholder="Your Email"
              type="email"
              name="email"
              value={inquiryData.email}
              onChange={onHandleChangeOTP}
            />{" "}
            <br />
            <label>Enter Mobile Number</label>
            <Input
              placeholder="10 Digit Mobile Number"
              type="number"
              name="mobileNumber"
              value={inquiryData.mobileNumber}
              onChange={onHandleChangeOTP}
            />{" "}
            <br />
            {inquiryData.error && (
              <p style={{ color: "red" }}>{inquiryData.error.message}</p>
            )}
            <br />
          </Form>
        </ModalBody>
        <ModalFooter style={backgroundColor}>
          <Button
            className="btn-round"
            color="default"
            type="button"
            outline
            style={{ margin: "15px" }}
            onClick={(event) => closeModal()}
          >
            Cancel
          </Button>
          <Button
            className="btn-round"
            color="info"
            type="button"
            outline
            onClick={() => submitDetails()}
          >
            Submit
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default InquiryModal;
