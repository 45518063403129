/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import * as firebase from "firebase/app";
import { Capacitor } from "@capacitor/core";
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";
import { userInfoContext } from "context/userContext";
import { db, auth, storage } from "../../src/firebase/firebase";
import AlertModal from "../components/Modals/Modals";
import {
  NetworkDetector,
  fetchUserProfile,
} from "../components/Modals/AsyncTask";
import ProfileViewModal from "../components/Modals/ProfileViewModal";

const Posts = (props) => {
  const ALERT_PARAMS_INITIAL_STATE = {
    title: "",
    body: "",
    btnOk: "",
    btnCancel: "",
  };
  // console.log(props.posts)
  const history = useHistory();
  const { userInfo, setUserInfo } = useContext(userInfoContext);
  const [posts, setPosts] = useState(props.posts);
  const [btnTxt, setBtnTxt] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const [showConfirmAlert, setConfirmAlert] = useState(false);
  const [alertParams, setAlertParams] = useState(ALERT_PARAMS_INITIAL_STATE);
  const [sendBtnTxt, setSendBtnTxt] = useState(false);
  const [acceptBtnTxt, setAcceptBtnTxt] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [showUserProfileParams, setShowUserProfileParams] = useState({});
  const [requID, setReqID] = useState();
  var selectedPost;

  function getServerTime() {
    return $.ajax({ async: false }).getResponseHeader("Date");
  }

  // if (loading) {
  //   return <h2>Loading...</h2>;
  // }

  const navigateTo = (post) => {
    // console.log(post)
    history.push("/user-details", {
      userData: post,
      userInfo: userInfo,
    });
    // setShowUserProfileParams(post)
    // setShowUserProfile(true)
  };
  function inArray(needle, haystack) {
    if (haystack !== undefined) {
      var length = haystack.length;
      for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) return true;
      }
      return false;
    } else {
      return false;
    }
  }
  const _calculateDays = (lastDate, todaysDate) => {
    const timeDiff = new Date(lastDate) - new Date(todaysDate);
    return Math.round(timeDiff / (1000 * 60 * 60 * 24));
  };
  const sendRequest = (selectedPost) => {
    if (userInfo.isDisableAccount) {
      setAlertParams((prevState) => ({
        ...prevState,
        title: "Account Disabled",
        body:
          "Your account is not visible to the public. You are not able to send a request until you change your account setting to enable.",
        btnOk: "Account Setting",
        btnCancel: "Cancel",
      }));
      setConfirmAlert(true);
    } else {
      if (!userInfo.maritalStatus && !userInfo.diet) {
        setAlertParams((prevState) => ({
          ...prevState,
          title: "Update Profile",
          body:
            "It seems like your profile is not updated. Please update your profile details to send request",
          btnOk: "Update",
          btnCancel: "Cancel",
        }));
        setConfirmAlert(true);
      } else {
        let dailyrequestLimit = "";
        let getDay = new Date().getDay().toString();
        if (localStorage.getItem("dailyrequestLimit") !== null) {
          dailyrequestLimit = localStorage.getItem("dailyrequestLimit");
        }
        if (dailyrequestLimit == getDay) {
          setAlertParams((prevState) => ({
            ...prevState,
            title: "Daily Limit",
            body:
              "Your today's daily quota is over. You can send a maximum of 1 request each day. Please try tomorrow.",
            btnOk: "",
            btnCancel: "Okey",
          }));
          setConfirmAlert(true);
        } else {
          setReqID(selectedPost.id);
          // console.log("Good to go");
          // return true;
          if (!sendBtnTxt) {
            setSendBtnTxt(true);
            selectedPost = selectedPost;
            let result = NetworkDetector();
            result.then(
              (result) => {
                //Get a new write batch
                var batch = db.batch();
                // Set the value of 'NYC'
                var nycRef = db.collection("ENJOYER_PFOFILE").doc(userInfo.id);
                // var addNewID = userInfo.requstedProfileId;
                // addNewID.push(post.id)
                // userInfo.requstedProfileId = addNewID
                batch.update(nycRef, {
                  "userData.requstedProfileId": firebase.firestore.FieldValue.arrayUnion(
                    selectedPost.id
                  ),
                });

                // Update the population of 'SF'
                var sfRef = db
                  .collection("ENJOYER_PFOFILE")
                  .doc(selectedPost.id);
                // batch.update(sfRef, {"population": 1000000});
                // var addNewRID = post.receivedProfileId;
                // addNewRID.push(userInfo.id)
                // post.receivedProfileId = addNewRID
                // Atomically add a new region to the "regions" array field.
                batch.update(sfRef, {
                  "userData.receivedProfileId": firebase.firestore.FieldValue.arrayUnion(
                    userInfo.id
                  ),
                });

                // Atomically remove a region from the "regions" array field.
                // washingtonRef.update({
                //   regions: firebase.firestore.FieldValue.arrayRemove("east_coast")
                // });
                // Delete the city 'LA'
                // var laRef = db.collection("ENJOYER_PFOFILE").doc("LA");
                // batch.delete(laRef);

                // Commit the batch
                batch
                  .commit()
                  .then(function() {
                    if (selectedPost.pushToken) {
                      sendNotification(selectedPost, "SENT_REQUEST");
                    }
                    // console.log("Document successfully updated!");
                    localStorage.setItem("dailyrequestLimit", getDay);

                    const updatedPosts = props.posts.map((post) => {
                      // Does not match, so return the counter without changing.
                      if (post.id !== selectedPost.id) {
                        return post;
                      } else {
                        // Else (means match) return a new counter but change only the value
                        var receivedProfileId = [];
                        var receivedProfileId = post.receivedProfileId;
                        receivedProfileId.push(userInfo.id);
                        post.receivedProfileId = receivedProfileId;
                        return post;
                        // return { ...post, receivedProfileId: receivedProfileId };
                      }
                    });

                    var requstedProfileId = [];
                    var requstedProfileId = userInfo.requstedProfileId;
                    requstedProfileId.push(selectedPost.id);
                    setPosts(updatedPosts);
                    setUserInfo({
                      ...userInfo,
                      requstedProfileId: requstedProfileId,
                    });
                    // props.setPosts(updatedPosts);
                    // setSendBtnTxt('Send Request');
                    setSendBtnTxt(false);
                    props.callBack(selectedPost);
                  })
                  .catch(function(error) {
                    // The document probably doesn't exist.
                    // setSendBtnTxt('Send Request');
                    setSendBtnTxt(false);
                    console.error("Error updating document: ", error);
                    setAlertParams((prevState) => ({
                      ...prevState,
                      title: "OOPS!!",
                      body:
                        "We are facing issues to reach remote server please try again.",
                      btnOk: "OKEY",
                      btnCancel: "",
                    }));
                    // console.log("Internet Disconnected");
                    setConfirmAlert(true);
                  });
              },
              function(error) {
                // setSendBtnTxt('Send Request');
                setSendBtnTxt(false);
                setAlertParams((prevState) => ({
                  ...prevState,
                  title: "Internet Disconnected",
                  body: "Please check your internet connection and try again.",
                  btnOk: "SEND",
                  btnCancel: "CANCEL",
                }));
                console.log("Internet Disconnected");
                setConfirmAlert(true);
              }
            );
          }
        }
      }
    }
  };
  const acceptRequest = (selectedPost) => {
    if (userInfo.isDisableAccount) {
      setAlertParams((prevState) => ({
        ...prevState,
        title: "Account Disabled",
        body:
          "Your account is not visible to the public. You are not able to send a request until you change your account setting to enable.",
        btnOk: "Account Setting",
        btnCancel: "Cancel",
      }));
      setConfirmAlert(true);
    } else {
      if (!userInfo.maritalStatus && !userInfo.diet) {
        setAlertParams((prevState) => ({
          ...prevState,
          title: "Update Profile",
          body:
            "It seems like your profile is not updated. Please update your profile to accept request.",
          btnOk: "Update",
          btnCancel: "Cancel",
        }));
        setConfirmAlert(true);
      } else {
        setReqID(selectedPost.id);
        // console.log("Good to go");
        if (!acceptBtnTxt) {
          setAcceptBtnTxt(true);
          selectedPost = selectedPost;
          let result = NetworkDetector();
          result.then(
            (result) => {
              var batch = db.batch();
              var nycRef = db.collection("ENJOYER_PFOFILE").doc(userInfo.id);
              batch.update(nycRef, {
                "userData.acceptedProfileId": firebase.firestore.FieldValue.arrayUnion(
                  selectedPost.id
                ),
              });
              batch.update(nycRef, {
                "userData.receivedProfileId": firebase.firestore.FieldValue.arrayRemove(
                  selectedPost.id
                ),
              });
              batch.update(nycRef, {
                "userData.requstedProfileId": firebase.firestore.FieldValue.arrayRemove(
                  selectedPost.id
                ),
              }); // Remove from received or send request array
              var sfRef = db.collection("ENJOYER_PFOFILE").doc(selectedPost.id);
              batch.update(sfRef, {
                "userData.acceptedProfileId": firebase.firestore.FieldValue.arrayUnion(
                  userInfo.id
                ),
              });
              batch.update(sfRef, {
                "userData.receivedProfileId": firebase.firestore.FieldValue.arrayRemove(
                  userInfo.id
                ),
              });
              batch.update(sfRef, {
                "userData.requstedProfileId": firebase.firestore.FieldValue.arrayRemove(
                  userInfo.id
                ),
              });

              batch
                .commit()
                .then(function() {
                  // console.log("Document successfully updated!");
                  if (selectedPost.pushToken) {
                    sendNotification(selectedPost, "ACCEPT_REQUEST");
                  }
                  const updatedPosts = props.posts.map((post) => {
                    // Does not match, so return the counter without changing.
                    if (post.id !== selectedPost.id) {
                      return post;
                    } else {
                      // Else (means match) return a new counter but change only the value
                      var receivedProfileId = [];
                      var acceptedProfileId = [];
                      var requstedProfileId = [];
                      acceptedProfileId = post.acceptedProfileId;
                      acceptedProfileId.push(userInfo.id);
                      post.acceptedProfileId = acceptedProfileId;
                      post.receivedProfileId = removeFromArray(
                        post.receivedProfileId,
                        userInfo.id
                      );
                      post.requstedProfileId = removeFromArray(
                        post.requstedProfileId,
                        userInfo.id
                      );

                      return post;
                      // return { ...post, receivedProfileId: receivedProfileId };
                    }
                  });

                  var requstedProfileId = [];
                  var acceptedProfileIdForActiveUser =
                    userInfo.acceptedProfileId;
                  acceptedProfileIdForActiveUser.push(selectedPost.id);
                  userInfo.acceptedProfileId = acceptedProfileIdForActiveUser;
                  userInfo.receivedProfileId = removeFromArray(
                    userInfo.receivedProfileId,
                    selectedPost.id
                  );
                  userInfo.requstedProfileId = removeFromArray(
                    userInfo.requstedProfileId,
                    selectedPost.id
                  );
                  setUserInfo(userInfo);
                  setPosts(updatedPosts);
                  // setUserInfo({ ...userInfo, requstedProfileId: requstedProfileId });
                  // props.setPosts(updatedPosts);
                  setAcceptBtnTxt(false);
                  props.callBack(selectedPost);
                })
                .catch(function(error) {
                  // The document probably doesn't exist.
                  setAcceptBtnTxt(false);
                  console.error("Error updating document: ", error);
                  setAlertParams((prevState) => ({
                    ...prevState,
                    title: "OOPS!!",
                    body:
                      "We are facing issues to reach remote server please try again.",
                    btnOk: "OKEY",
                    btnCancel: "",
                  }));
                  // console.log("Internet Disconnected");
                  setConfirmAlert(true);
                });
            },
            function(error) {
              setAcceptBtnTxt(false);
              setAlertParams((prevState) => ({
                ...prevState,
                title: "Internet Disconnected",
                body: "Please check your internet connection and try again.",
                btnOk: "ACCEPT",
                btnCancel: "CANCEL",
              }));
              // console.log("Internet Disconnected");
              setConfirmAlert(true);
            }
          );
        }
      }
    }
  };
  const sendNotification = (data, type) => {
    let title = "";
    let body = "";
    if (type == "ACCEPT_REQUEST") {
      title = "Congratulation!!!";
      body =
        "Your request is accepted by " +
        userInfo.firstName +
        ". Please log in to get contact details.";
    } else if (type == "SENT_REQUEST") {
      title = "Congratulation!!!";
      body =
        "Your have new request from " +
        userInfo.firstName +
        ". Please log in to accept.";
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "key=AAAAQSRIrt8:APA91bGd9RJyHwREUG4Uyi4YQXBXzRleH7wSgX2k_zdhvhrcAGL0zcxFdlAPa7xjpPPOYWuHcmNM2HetsOJyCBlu2UAGvo-SG61KPfe8AW3YMYRGT5xUG66-JU-0Sl2Z3iTC0VT7d94A",
      },
      body: JSON.stringify({
        to: data.pushToken,
        notification: {
          title: title,
          body: body,
          mutable_content: true,
          sound: "Tri-tone",
        },
      }),
    };
    fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log("SUCCESS");
      });
  };
  const viewContact = (selectedPost) => {
    setAlertParams((prevState) => ({
      ...prevState,
      title: "Hurray!!",
      body: (
        <p style={{ textAlign: "center" }}>
          The wait is over now. We wish you all the best to connect with your
          loved ones.
          <br />
          <br />
          Full Name : {selectedPost.firstName} {selectedPost.lastName}
          <br />
          Mobile No : {selectedPost.mobileNumber}
          <br />
          Email ID : {selectedPost.email}
        </p>
      ),
      btnOk: "CHEERS!!",
      btnCancel: "",
    }));
    setConfirmAlert(true);
  };
  const removeFromArray = (array, id) => {
    const index = array.indexOf(id);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  };
  const callBackProfileView = () => {
    setShowUserProfile(false);
  };
  const successCallBackData = (data) => {
    // console.log(data);
    setConfirmAlert(false);
    if (data == "SEND") {
      sendRequest(selectedPost);
    } else if (data == "ACCEPT") {
      acceptRequest(selectedPost);
    } else if (data == "TRY AGAIN") {
      //   updateUserPhoto();
    } else if (data == "Account Setting") {
      history.push("/user-setting");
    } else if (data == "Update") {
      history.push("/profile-page");
    }
  };
  return (
    // <ul className='list-group mb-4'>
    //   {posts.map(post => (
    //     <li key={post.id} className='list-group-item'>
    //       {post.title}
    //     </li>
    //   ))}
    // </ul>
    // <hr />
    <Row style={{ textTransform: "capitalize" }}>
      <Col className="ml-auto mr-auto r" md="10">
        <ul className="list-unstyled follows">
          {" "}
          {props.posts
            .sort((a, b) => (a.lastName > b.lastName ? 1 : -1))
            .map((post) => (
              <li
                key={post.id}
                style={{
                  textShadow: "2px 2px 4px #c3c3c3",
                  boxShadow: "0px 10px 10px #d7d7d7",
                  marginBottom: "60px",
                  borderRadius: "10px",
                  marginTop: "90px",
                }}
              >
                {/* <hr  /> */}
                <Row>
                  <Col
                    className="ml-auto mr-auto text-center"
                    lg="6"
                    md="6"
                    xs="6"
                  >
                    <div className="owner">
                      <div className="avatar">
                        {/* <img
                    alt="..."
                    className="img-circle img-no-padding img-responsive"
                    src={require("assets/img/faces/joe-gardner-2.jpg")}
                  /> */}
                        {!post.photoURL && post.gender == "male" ? (
                          <img
                            alt={post.firstName}
                            className="img-circle img-no-padding img-responsive"
                            // src={ post.photoURL}
                            src={require("assets/image/default_User_Icon_Men.png")}
                            style={{ width: "90%", height: "80%" }}
                          />
                        ) : null}
                        {!post.photoURL && post.gender == "female" ? (
                          <img
                            alt={post.firstName}
                            className="img-circle img-no-padding img-responsive"
                            // src={ post.photoURL}
                            style={{ width: "90%", height: "70%" }}
                            src={require("assets/image/default_User_Icon_Women.png")}
                          />
                        ) : null}
                        {post.photoURL ? (
                          <img
                            alt={post.firstName}
                            style={{ width: "160px", height: "140px" }}
                            className="img-circle img-no-padding img-responsive d-none d-xl-block d-lg-block"
                            src={post.photoURL}
                          />
                        ) : null}
                        {post.photoURL ? (
                          <img
                            alt={post.firstName}
                            style={{
                              width: "110px",
                              height: "90px",
                              margin: "5px",
                            }}
                            className="img-circle img-no-padding img-responsive d-xl-none d-lg-none"
                            src={post.photoURL}
                          />
                        ) : null}
                      </div>
                    </div>
                    <p style={{ overflowX: "hidden" }}>{post.firstName}</p>
                  </Col>
                </Row>
                <Row>
                  {/* <Col className="ml-auto mr-auto text-center" lg="3" md="3" xs="3">
                <div className="avatar">
               
                  {!post.photoURL && post.gender == 'male' ? <img
                    alt={post.firstName}
                    className="img-circle img-no-padding img-responsive"
                    src={require("assets/image/default_User_Icon_Men.png")}
                    style={{ width: '90%', height: '80%' }}
                  /> : null}
                  {!post.photoURL && post.gender == 'female' ? <img
                    alt={post.firstName}
                    className="img-circle img-no-padding img-responsive"
                    style={{ width: '90%', height: '80%' }}
                    src={require("assets/image/default_User_Icon_Women.png")}
                  /> : null}
                  {post.photoURL ? <img
                    alt={post.firstName}
                    style={{ width: '120px', height: '120px' }}
                    className="img-circle img-no-padding img-responsive d-none d-xl-block d-lg-block"
                    src={post.photoURL}
                  /> : null}
                  {post.photoURL ? <img
                    alt={post.firstName}
                    style={{ width: '80px', height: '65px', margin: '5px' }}
                    className="img-circle img-no-padding img-responsive d-xl-none d-lg-none"
                    src={post.photoURL}
                  /> : null}
                </div>
                <p style={{ overflowX: 'hidden' }}>{post.firstName}</p>
                <br />
                <br />
              </Col> */}
                  <Col
                    className="ml-auto mr-auto  text-center1"
                    lg="6"
                    md="6"
                    xs="6"
                  >
                    <div>
                      <p style={{ overflowX: "hidden" }}>
                        Job : <small>{post.profession}</small>
                      </p>
                      <br />
                      <p style={{ overflowX: "hidden" }}>
                        Place : <small> {post.currentPlace}</small>
                      </p>
                      <br />
                      <p style={{ overflowX: "hidden" }}>
                        M/S : <small> {post.maritalStatus}</small>
                      </p>
                      <br />
                    </div>
                    <br />
                  </Col>
                  <Col
                    className="ml-auto mr-auto text-center1"
                    lg="6"
                    md="6"
                    xs="6"
                  >
                    <div>
                      <p style={{ overflowX: "hidden" }}>
                        Age : <small>{post.age}</small>
                      </p>
                      <br />
                      <p style={{ overflowX: "hidden" }}>
                        H : <small> {post.height}</small>
                      </p>
                      <br />
                      <p style={{ overflowX: "hidden" }}>
                        W : <small> {post.weight}</small>
                      </p>
                      <br />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="ml-auto mr-auto text-center"
                    lg="6"
                    md="6"
                    xs="6"
                  >
                    <Button
                      style={{ fontSize: "10px" }}
                      className="btn-round "
                      color="default"
                      outline
                      onClick={(event) => navigateTo(post)}
                    >
                      {/* <i className="fa fa-cog" /> Fav */} View Profile 👀
                    </Button>
                    <br />
                  </Col>
                  <Col
                    className="ml-auto mr-auto text-center"
                    lg="6"
                    md="6"
                    xs="6"
                  >
                    {inArray(post.id, userInfo.acceptedProfileId) ? (
                      <Button
                        style={{ fontSize: "10px" }}
                        className="btn-round no-hover"
                        color="default"
                        onClick={(event) => viewContact(post)}
                        outline
                      >
                        View Contact
                      </Button>
                    ) : (
                      <div>
                        {inArray(post.id, userInfo.receivedProfileId) ? (
                          <div>
                            <Button
                              className="btn-round"
                              color="default"
                              style={{ fontSize: "10px" }}
                              onClick={(event) => acceptRequest(post)}
                              outline
                            >
                              {acceptBtnTxt && requID == post.id
                                ? "Accepting.."
                                : "Accept"}
                            </Button>
                          </div>
                        ) : (
                          <div>
                            {inArray(post.id, userInfo.requstedProfileId) ? (
                              <Button
                                style={{ fontSize: "10px" }}
                                className="btn-round"
                                color="default"
                                outline
                              >
                                Request Submitted ✅
                              </Button>
                            ) : (
                              <Button
                                className="btn-round"
                                color="default"
                                style={{ fontSize: "10px" }}
                                onClick={(event) => sendRequest(post)}
                                outline
                              >
                                {sendBtnTxt && requID == post.id
                                  ? "Sending.."
                                  : "Send Request 💖"}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    <br />
                    <br />
                    {/* <Button className="btn-round" color="default" outline>
                  <i className="fa fa-cog" /> Fav
                 </Button> */}
                    <br />
                  </Col>
                </Row>
                {/* <Row>
              <Col className="ml-auto mr-auto" lg="4" md="4" xs="4">
              </Col>
              <Col className="ml-auto mr-auto" lg="4" md="4" xs="4">
                <Button className="btn-round" color="default" outline>
                  <i className="fa fa-cog" /> Fav
                 </Button>
              </Col>
              <Col className="ml-auto mr-auto" lg="4" md="4" xs="4">
                <Button className="btn-round" color="default" outline>
                  <i className="fa fa-cog" /> Fav
                 </Button>
              </Col>

            </Row> */}
                {/* <hr /> */}
              </li>
            ))}
        </ul>
      </Col>
      {showUserProfile ? (
        <ProfileViewModal
          data={showUserProfileParams}
          callBack={callBackProfileView}
        />
      ) : null}
      {showConfirmAlert ? (
        <AlertModal data={alertParams} callBack={successCallBackData} />
      ) : null}
    </Row>
  );
};

export default Posts;
