export const GLOBLE_CONSTANT = {
  appName: "Hatkar Matrimony",
  appVersion: "2.0.3",
  appId: "hatkarmatrimony.com",
  env: "dev",
  webUrl: "https://www.hatkarmatrimony.com",
  banner_Add_ID: "ca-app-pub-1143950516558594/9506312943", // dummy ca-app-pub-3940256099942544/6300978111
  interstitial_Add_ID: "ca-app-pub-1143950516558594/1360293253", // dummy ca-app-pub-3940256099942544/1033173712
  rewardVideoAd_ID: "ca-app-pub-1143950516558594/5102251235", // dummy ca-app-pub-3940256099942544/5224354917
  defaultRegistrationWelcomeImageURL:
    "https://firebasestorage.googleapis.com/v0/b/hatkarmatrimony-e55e5.appspot.com/o/community_storage%2F%E0%A4%B9%E0%A4%9F%E0%A4%95%E0%A4%B0%20%E0%A4%B8%E0%A4%AE%E0%A4%BE%E0%A4%9C%20%E0%A4%AE%E0%A4%B9%E0%A4%BE%E0%A4%B8%E0%A4%82%E0%A4%98%20%E0%A4%AE%E0%A4%B9%E0%A4%BE%E0%A4%B0%E0%A4%BE%E0%A4%B7%E0%A5%8D%E0%A4%9F%E0%A5%8D%E0%A4%B0%20%E0%A4%B0%E0%A4%BE%E0%A4%9C%E0%A5%8D%E0%A4%AF%20%E0%A4%AF%E0%A4%BE%E0%A4%82%E0%A4%9A%E0%A5%8D%E0%A4%AF%E0%A4%BE%20%E0%A4%B5%E0%A4%A4%E0%A5%80%E0%A4%A8%E0%A5%87%20%E0%A4%86%E0%A4%AF%E0%A5%8B%E0%A4%9C%E0%A4%BF%E0%A4%A4%20%E0%A4%95%E0%A4%82%E0%A4%AE%E0%A5%81%E0%A4%A8%E0%A4%BF%E0%A4%9F%E0%A5%80%20%E0%A4%95%E0%A4%A8%E0%A5%87%E0%A4%95%E0%A5%8D%E0%A4%9F%20%E0%A4%AE%E0%A5%8B%E0%A4%B9%E0%A4%BF%E0%A4%AE%E0%A5%87%E0%A4%85%E0%A4%82%E0%A4%A4%E0%A4%B0%E0%A5%8D%E0%A4%97%E0%A4%A4%20%E0%A4%B8%E0%A5%81.png?alt=media&token=f327b01e-c857-412b-b54c-dcf00279cb78",
};
