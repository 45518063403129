/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";

// reactstrap components

import {
  Button,
  Label,
  FormGroup,
  Input,
  Collapse,
  CardBody,
  Card,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  CardTitle,
  CardText,
  CardHeader,
  CardFooter,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import * as firebase from "firebase/app";
import AlertModal from "../../components/Modals/Modals";

import { db, auth, storage } from "../../firebase/firebase";
import MyLoader from "../../components/Modals/Spinner";
import LoadingOverlay from "react-loading-overlay";
import TroubleFetching from "components/TroubleFetching/TroubleFetching";
import { userInfoContext, userListContext } from "../../context/userContext";
import {
  NetworkDetector,
  fetchUserProfile,
  EncodeData,
  DecodeData,
} from "../../components/Modals/AsyncTask";
import { Capacitor } from "@capacitor/core";
import { GLOBLE_CONSTANT } from "config/constant";
import { AdMob, AdOptions } from "@capacitor-community/admob";

function SettingPage() {
  const COLLAPSE_INITIATION = {
    changePassword: false,
    logout: false,
    accountDisable: false,
    deleteAccount: false,
  };
  const CHANGE_PASSWORD_INITIAL_STATE = {
    password: "",
    confirmPassword: "",
    oldPassword: "",
    error: null,
  };
  const ALERT_PARAMS_INITIAL_STATE = {
    title: "",
    body: "",
    btnOk: "",
    btnCancel: "",
  };
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(COLLAPSE_INITIATION);
  const [getChangePasswordDetails, setChangePasswordDetails] = useState(
    CHANGE_PASSWORD_INITIAL_STATE
  );
  const [alertParams, setAlertParams] = useState(ALERT_PARAMS_INITIAL_STATE);
  const [showConfirmAlert, setConfirmAlert] = useState(false);
  const { userInfo, setUserInfo } = useContext(userInfoContext);
  const [poorConnection, setPoorConnection] = useState(false);
  const { userList, setUserList } = useContext(userListContext);
  const [activeTab, setActiveTab] = useState("1");
  const user = auth.currentUser;
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  var resetNetwork;
  var ntwokChk = false;
  const toggle = (tab) => {
    setActive((prevState) => ({
      ...prevState,
    }));
    setIsOpen(!isOpen);
  };
  const onHandleChangePassword = (event) => {
    // setRegistrationDetails({ [event.target.name]: event.target.value });
    const { name, value } = event.target;
    setChangePasswordDetails((prevState) => ({
      ...prevState,
      [name]: value,
      error: null,
    }));
  };
  const onHandleChange = (event) => {
    setChangePasswordDetails(CHANGE_PASSWORD_INITIAL_STATE);

    setActive((prevState) => ({
      ...prevState,
      [event]: !isOpen,
    }));

    setIsOpen(!isOpen);
  };
  const setNetworkTimeOut = (timer) => {
    ntwokChk = false;
    resetNetwork = setTimeout(() => {
      if (ntwokChk) {
        // console.log("INTERNET WORKING");
        setIsLoading(false);
        // setSkeleton(false)
      } else {
        // console.log("SHOW NETWORK ERROR");
        setPoorConnection(true);
        setIsLoading(false);
        // setSkeleton(false)
      }
    }, timer);
  };
  const changeAccountSetting = () => {
    setAlertParams((prevState) => ({
      ...prevState,
      title: "Account Setting",
      body: "Are you sure you want to change account setting?.",
      btnOk: userInfo.isDisableAccount ? "Enable" : "Disable",
      btnCancel: "NO",
    }));
    setConfirmAlert(true);
  };
  const doChangePassword = () => {
    // user.updateProfile({
    //     displayName: "Prakash Bhusanoor",
    //     photoURL: "https://example.com/jane-q-user/profile.jpg"
    //   }).then(function(profile) {
    //     console.log(profile)
    //     // Update successful.
    //   }).catch(function(error) {
    //       console.log(error)
    //     // An error happened.
    //   });
    // user.providerData.forEach(function (profile) {
    //     console.log(profile)
    //     console.log("Sign-in provider: " + profile.providerId);
    //     console.log("  Provider-specific UID: " + profile.uid);
    //     console.log("  Name: " + profile.displayName);
    //     console.log("  Email: " + profile.email);
    //     console.log("  Photo URL: " + profile.photoURL);
    //   });

    if (getChangePasswordDetails.oldPassword == "") {
      setChangePasswordDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Old password should not be blank",
        },
      }));
      return;
    }
    if (getChangePasswordDetails.password == "") {
      setChangePasswordDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Password should not be blank",
        },
      }));
      return;
    }
    if (
      getChangePasswordDetails.password.length < 8 ||
      getChangePasswordDetails.password.length > 14
    ) {
      setChangePasswordDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Password lenght should be in between 8 to 14 characters",
        },
      }));
      return;
    }
    if (
      getChangePasswordDetails.password !==
      getChangePasswordDetails.confirmPassword
    ) {
      setChangePasswordDetails((prevState) => ({
        ...prevState,
        error: {
          message:
            "Password not matching. Confirm password should be same as password.",
        },
      }));
      return;
    }
    setAlertParams((prevState) => ({
      ...prevState,
      title: "Change Password",
      body: "Are you sure you want to change your password?.",
      btnOk: "YES",
      btnCancel: "NO",
    }));
    setIsLoading(true);
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      getChangePasswordDetails.oldPassword
    );
    user
      .reauthenticateWithCredential(credential)
      .then(function() {
        // User re-authenticated.
        setIsLoading(false);
        setConfirmAlert(true);
      })
      .catch(function(error) {
        // An error happened.
        console.log(error);
        setIsLoading(false);
        setChangePasswordDetails((prevState) => ({
          ...prevState,
          error: {
            message: error.message,
          },
        }));
      });
  };
  const successCallBackData = (data) => {
    let result = NetworkDetector();

    setConfirmAlert(false);
    if (data == "YES") {
      setIsLoading(true);
      result.then(
        (result) => {
          user
            .updatePassword(getChangePasswordDetails.password)
            .then(function() {
              // Update successful.
              auth
                .signOut()
                .then(function() {
                  // Sign-out successful.
                  setIsLoading(false);
                  localStorage.setItem("isLoggedIn", false);
                  setAlertParams((prevState) => ({
                    ...prevState,
                    title: "Success",
                    body:
                      "Password changed successfully. Please login with your new password.",
                    btnOk: "OK",
                    btnCancel: "",
                  }));
                  setTimeout(() => setConfirmAlert(true), 500);
                })
                .catch(function(error) {
                  // An error happened
                  console.log(error);
                });
            })
            .catch(function(error) {
              // An error happened.
              setIsLoading(false);
              console.log(error);
            });
        },
        function(error) {
          setIsLoading(false);
          setAlertParams((prevState) => ({
            ...prevState,
            title: "Internet Disconnected",
            body: "Please check your internet connection and try again.",
            btnOk: "YES",
            btnCancel: "CANCEL",
          }));
          setTimeout(() => setConfirmAlert(true), 500);

          console.log("Internet Disconnected");
        }
      );
    } else if (data == "OK") {
      window.location.href = "/";
    } else if (data == "DELETE") {
      setIsLoading(true);
      db.collection("ENJOYER_PFOFILE")
        .doc(userInfo.id)
        .delete()
        .then(function() {
          console.log("Document successfully deleted!");
          user
            .delete()
            .then(function() {
              // User deleted.
              setIsLoading(false);
              setUserInfo(null);
              setUserList(null);
              setUserData(null);
              localStorage.setItem("isLoggedIn", false);
              setAlertParams((prevState) => ({
                ...prevState,
                title: "Success",
                body:
                  "You are no longer active user now. Thank you for being with us.",
                btnOk: "OK",
                btnCancel: "",
              }));
              setTimeout(() => setConfirmAlert(true), 500);
            })
            .catch(function(error) {
              // An error happened.
              setIsLoading(false);
              setAlertParams((prevState) => ({
                ...prevState,
                title: "Internet Disconnected",
                body: "Please check your internet connection and try again.",
                btnOk: "DELETE",
                btnCancel: "CANCEL",
              }));
              setTimeout(() => setConfirmAlert(true), 500);
            });
        })
        .catch(function(error) {
          setIsLoading(false);
          console.error("Error removing document: ", error);
        });
    } else if (data == "Enable" || data == "Disable") {
      setIsLoading(true);
      setNetworkTimeOut(40000);
      userData.isDisableAccount = !userInfo.isDisableAccount;
      // let response = fetchUserProfile();
      db.collection("ENJOYER_PFOFILE")
        .doc(userInfo.id)
        .update({
          userData,
        })
        .then(
          function(docRef) {
            setUserInfo(userData);
            setIsLoading(false);
            setPoorConnection(false);
            // setSkeleton(false)
            ntwokChk = true;
            clearTimeout(resetNetwork);
            setAlertParams((prevState) => ({
              ...prevState,
              title: "Success",
              body: "Account setting has been changed successfully.",
              btnOk: "Okey",
              btnCancel: "",
            }));
            setTimeout(() => setConfirmAlert(true), 500);
          },
          function(error) {
            setIsLoading(false);
            ntwokChk = false;
            console.log("Internet Disconnected");
            // setSkeleton(false)
            clearTimeout(resetNetwork);
            if (error == "Internet") {
              setPoorConnection(true);
            }
          }
        );
    }
  };
  useEffect(() => {
    initialFetch();
    window.scrollTo(0, 0);
    if (Capacitor.isNativePlatform()) {
      // adMobIn();
    }
  }, []);
  const adMobIn = async () => {
    try {
      await AdMob.initialize();
    } catch (error) {
      console.log("ADMOB INITIALIZE ERROR");
    }

    const options: AdOptions = {
      adId: GLOBLE_CONSTANT.rewardVideoAd_ID,
    };
    await AdMob.prepareRewardVideoAd(options);
    // const options_: AdOptions = {
    //   adId: GLOBLE_CONSTANT.interstitial_Add_ID,
    // };
    // // Prepare interstitial banner
    // await AdMob.prepareInterstitial(options_);

    setTimeout(() => {
      showRrewardVideo();
    }, 4000);
  };
  const showRrewardVideo = async () => {
    await AdMob.showRewardVideoAd();
  };
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  function initialFetch() {
    var ntwokChk = false;
    var resetNetwork = setTimeout(() => {
      if (ntwokChk) {
        console.log("INTERNET WORKING");
        setIsLoading(false);
      } else {
        console.log("SHOW NETWORK ERROR");
        setPoorConnection(true);
        setIsLoading(false);
      }
    }, 40000);
    setPoorConnection(false);
    ntwokChk = false;
    setIsLoading(true);
    if (userInfo) {
      console.log("userInfo has data");
      // console.log(userInfo);
      ntwokChk = true;
      setUserData(userInfo);
      setIsLoading(false);
      clearTimeout(resetNetwork);
    } else {
      console.log("userInfo data not found");
      let response = fetchUserProfile();
      response.then(
        (resp) => {
          // console.log(resp);
          setUserInfo(resp);
          setUserData(resp);
          setPoorConnection(false);
          ntwokChk = true;
          setIsLoading(false);
          clearTimeout(resetNetwork);
          console.log("userInfo data loaded");
        },
        function(error) {
          ntwokChk = false;
          clearTimeout(resetNetwork);
          console.log("Internet Disconnected");
          setIsLoading(false);
          if (error == "Internet") {
            setPoorConnection(true);
          }
        }
      );
    }
  }
  const DoDeleteAccount = () => {
    if (getChangePasswordDetails.oldPassword == "") {
      setChangePasswordDetails((prevState) => ({
        ...prevState,
        error: {
          message: "Old password should not be blank",
        },
      }));
      return;
    }
    if (localStorage.getItem("DELETE_ACCOUNT_FLAG") !== null) {
      if (localStorage.getItem("DELETE_ACCOUNT_FLAG")) {
        alert(
          "Sorry!!  Unable to delete your account. Please drop an email to us on : hatkarmatrimony@gmail.com"
        );
      }
      return true;
    }
    setAlertParams((prevState) => ({
      ...prevState,
      title: "Warning!!!",
      body: "Are you sure you want to delete your account permanently?",
      btnOk: "DELETE",
      btnCancel: "CANCEL",
    }));

    setIsLoading(true);
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      getChangePasswordDetails.oldPassword
    );
    user
      .reauthenticateWithCredential(credential)
      .then(function() {
        // User re-authenticated.
        setIsLoading(false);
        setConfirmAlert(true);
      })
      .catch(function(error) {
        // An error happened.
        console.log(error);
        setIsLoading(false);
        setAlertParams((prevState) => ({
          ...prevState,
          title: "Oops! Re-authentication failed.",
          body:
            "Please make sure you've entered the correct password to proceed. Double-check for any typos and try again.",
          btnOk: "OKEY",
          // btnCancel: 'CANCEL'
        }));
        setConfirmAlert(true);
      });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      {isLoading ? <MyLoader /> : null}
      {poorConnection ? (
        <TroubleFetching callBack={initialFetch} />
      ) : (
        <div>
          <ExamplesNavbar />
          <ProfilePageHeader />
          <div className="section profile-content">
            <Container>
              {userInfo ? (
                <div className="owner">
                  <div className="avatar">
                    {!userInfo.photoURL && userInfo.gender == "male" ? (
                      <img
                        alt={userInfo.firstName}
                        className="img-circle img-no-padding img-responsive"
                        src={require("assets/image/default_User_Icon_Men.png")}
                        style={{ width: "150px", height: "150px" }}
                      />
                    ) : null}
                    {!userInfo.photoURL && userInfo.gender == "female" ? (
                      <img
                        alt={userInfo.firstName}
                        className="img-circle img-no-padding img-responsive"
                        style={{ width: "150px", height: "150px" }}
                        src={require("assets/image/default_User_Icon_Women.png")}
                      />
                    ) : null}
                    {userInfo.photoURL ? (
                      <img
                        alt={userInfo.firstName}
                        style={{ width: "150px", height: "150px" }}
                        className="img-circle img-no-padding img-responsive"
                        src={userInfo.photoURL}
                      />
                    ) : null}
                  </div>
                  <div className="name">
                    <h4
                      className="title"
                      style={{ textTransform: "capitalize" }}
                    >
                      {userInfo.firstName} {userInfo.lastName}
                      <br />
                    </h4>
                  </div>
                </div>
              ) : null}

              <br />
              <br />
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <Nav role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        User Settings
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <Row>
                <Col className="ml-auto mr-auto r" md="8">
                  <CardHeader
                    onClick={(event) => onHandleChange("accountDisable")}
                    style={{ backgroundColor: "#fff" }}
                  >
                    Account Setting{" "}
                    <i
                      style={{
                        position: "absolute",
                        float: "right",
                        right: "5%",
                      }}
                      className={
                        active.accountDisable
                          ? "fa fa-chevron-up"
                          : "fa fa-chevron-down"
                      }
                      aria-hidden="true"
                    ></i>
                  </CardHeader>
                  <div className="text-center">
                    <Collapse isOpen={active.accountDisable}>
                      {userInfo ? (
                        <Card>
                          <CardBody>
                            {userInfo.isDisableAccount ? (
                              <CardTitle>
                                Account Status : Disabled (Not visible to all)
                              </CardTitle>
                            ) : (
                              <CardTitle>
                                Account Status : Active (Visible to all)
                              </CardTitle>
                            )}

                            {userInfo.isDisableAccount ? (
                              <CardText>
                                Your account is disabled now. You are not
                                visible to all. If you would like to visible it
                                to all. Please click on the button below to
                                enable it.
                              </CardText>
                            ) : (
                              <CardText>
                                Your account is visible to all now. If you would
                                like to disable it. Please click on the button
                                below to disable it. Once disabled you are no
                                longer visible to all until you enable it again.
                              </CardText>
                            )}
                            <Button
                              className="btn-round"
                              color="danger"
                              onClick={() => changeAccountSetting()}
                            >
                              {userInfo.isDisableAccount
                                ? "Enable Now"
                                : "Disable Now"}
                            </Button>
                          </CardBody>
                        </Card>
                      ) : (
                        ""
                      )}
                    </Collapse>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="ml-auto mr-auto r" md="8">
                  <CardHeader
                    onClick={(event) => onHandleChange("deleteAccount")}
                    style={{ backgroundColor: "#fff" }}
                  >
                    Delete Account{" "}
                    <i
                      style={{
                        position: "absolute",
                        float: "right",
                        right: "5%",
                      }}
                      className={
                        active.deleteAccount
                          ? "fa fa-chevron-up"
                          : "fa fa-chevron-down"
                      }
                      aria-hidden="true"
                    ></i>
                  </CardHeader>
                  <Collapse isOpen={active.deleteAccount}>
                    <Card>
                      <CardBody>
                        {/* <CardTitle>
                          Are you sure you want to delete your account
                          permanently?
                        </CardTitle> */}
                        <CardText>
                          Your profile, settings, and any associated content
                          will be deleted. This process cannot be undone.Are you
                          sure you want to delete your account permanently?.
                          <br />
                          <br />
                          {/* <p style={{ color: "#c52e2e" }}>
                            {
                              "Please mail us on hatkarmatrimony@gmail.com to delete your account in case of any technical error.  "
                            }
                          </p> */}
                          <p>
                            If you encounter any technical issues or need
                            assistance with account deletion, please don't
                            hesitate to contact us:
                          </p>
                          <p class="contact-info">
                            Email us at{" "}
                            <a href="mailto:hatkarmatrimony@gmail.com">
                              hatkarmatrimony@gmail.com
                            </a>
                          </p>
                          <p>
                            We're here to help ensure a smooth process for you.
                          </p>
                        </CardText>
                        <br />
                        <label>Please enter your password</label>
                        <Input
                          placeholder="Old Password"
                          type="password"
                          name="oldPassword"
                          value={getChangePasswordDetails.oldPassword}
                          onChange={onHandleChangePassword}
                        />

                        <br />
                        <Button
                          className="btn-round"
                          color="danger"
                          onClick={() => DoDeleteAccount()}
                        >
                          Delete My Account
                        </Button>
                        {getChangePasswordDetails.error && (
                          <p style={{ color: "#c52e2e" }}>
                            {getChangePasswordDetails.error.message}
                          </p>
                        )}
                      </CardBody>
                    </Card>
                  </Collapse>
                </Col>
              </Row>

              <Row>
                <Col className="ml-auto mr-auto r " md="8">
                  <CardHeader
                    onClick={(event) => onHandleChange("changePassword")}
                    style={{ backgroundColor: "#fff" }}
                  >
                    Change Password{" "}
                    <i
                      style={{
                        position: "absolute",
                        float: "right",
                        right: "5%",
                      }}
                      className={
                        active.changePassword
                          ? "fa fa-chevron-up"
                          : "fa fa-chevron-down"
                      }
                      aria-hidden="true"
                    ></i>
                  </CardHeader>
                  <div className="text-center">
                    <Collapse isOpen={active.changePassword}>
                      <Card>
                        <CardBody>
                          <label> Old Password</label>
                          <Input
                            placeholder="Old Password"
                            type="password"
                            name="oldPassword"
                            value={getChangePasswordDetails.oldPassword}
                            onChange={onHandleChangePassword}
                          />
                          <label> New Password</label>
                          <Input
                            placeholder="New Password"
                            type="password"
                            name="password"
                            value={getChangePasswordDetails.password}
                            onChange={onHandleChangePassword}
                          />
                          <label> Confirm New Password</label>
                          <Input
                            placeholder="Confirm New Password"
                            type="password"
                            name="confirmPassword"
                            value={getChangePasswordDetails.confirmPassword}
                            onChange={onHandleChangePassword}
                          />
                          <br />

                          <Button
                            className="btn-round"
                            color="danger"
                            onClick={() => doChangePassword()}
                          >
                            Submit
                          </Button>
                          {getChangePasswordDetails.error && (
                            <p style={{ color: "#c52e2e" }}>
                              {getChangePasswordDetails.error.message}
                            </p>
                          )}
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <DemoFooter />
        </div>
      )}
      {showConfirmAlert ? (
        <AlertModal data={alertParams} callBack={successCallBackData} />
      ) : null}
    </>
  );
}

export default SettingPage;
