/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// nodejs library that concatenates strings
import classnames from "classnames";
import { db, auth } from "../../firebase/firebase";
import { useHistory } from "react-router-dom";
import { userInfoContext, userListContext } from "../../context/userContext";
import {
  NetworkDetector,
  fetchUserProfile,
} from "../../components/Modals/AsyncTask";

import { Capacitor } from "@capacitor/core";

// reactstrap components

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function ExamplesNavbar() {
  const history = useHistory();
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { userInfo, setUserInfo } = useContext(userInfoContext);
  const { userList, setUserList } = useContext(userListContext);
  const [backBtn, setBackBtn] = useState(true);
  const [navBarBtn, setNavBarBtn] = useState(false);
  const [navBarBrand, setNavBarBtnBrand] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const isLoggedin = JSON.parse(localStorage.getItem("isLoggedIn"));
  const user = auth.currentUser;

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };
  const doLogout = () => {
    auth
      .signOut()
      .then(function() {
        // Sign-out successful.
        // setUserInfo(null);
        // setUserList(null);
        localStorage.setItem("isLoggedIn", false);
        window.localStorage.setItem("index", 1);
        // history.push('/secure-auth');
        history.push("/");
      })
      .catch(function(error) {
        // An error happened
        // console.log(error);
      });
  };
  useEffect(() => {
    // let location = useLocation();
    var path = window.location.pathname;
    if (path == "/profile-views" || path == "/user-details") {
      setBackBtn(true);
    } else {
      setBackBtn(false);
    }
    if (path == "/user-details") {
      setNavBarBtn(false);
    } else {
      if (isLoggedin) setNavBarBtn(true);
      else setNavBarBtn(false);
    }
    if (path == "/" || path == "/secure-auth") {
      setNavBarBtnBrand(true);
    } else {
      setNavBarBtnBrand(false);
    }
    if (!Capacitor.isNativePlatform() && isLoggedin) {
      doLogout();
    }

    // if(userInfo){
    //     console.log('userInfo has data')
    // }else{
    //     console.log('userInfo data not found')
    //     let response = fetchUserProfile();
    //     response.then(resp =>{
    //         setUserInfo(resp);
    //       }, function(error) {
    //       })
    // }
  }, []);
  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
      style={{ height: "70px" }}
    >
      <Container>
        {/* {backBtn ? <i className="fa fa-arrow-left d-none d-xl-block d-lg-block" aria-hidden="true" onClick={()=>history.goBack()}  style={{position:'absolute',marginLeft:'25px',fontSize:'large'}}/> : null} */}
        {/* <i className="fa fa-arrow-left" aria-hidden="true" style={{margin:'20px'}}></i> */}
        <div className="navbar-translate">
          {backBtn && isLoggedin ? (
            <i
              className="fa fa-arrow-left d-none d-xl-block d-lg-block"
              aria-hidden="true"
              onClick={() => history.goBack()}
              style={{
                position: "absolute",
                marginLeft: "25px",
                fontSize: "large",
                marginTop: "40px",
              }}
            />
          ) : null}
          {backBtn && isLoggedin ? (
            <i
              className="fa fa-arrow-left d-xl-none d-lg-none"
              aria-hidden="true"
              onClick={() => history.goBack()}
              style={{
                position: "absolute",
                marginLeft: "25px",
                fontSize: "large",
              }}
            />
          ) : null}
          {/* <i className="fa fa-arrow-left" aria-hidden="true"></i> */}
          {navBarBrand ? (
            <NavbarBrand
              data-placement="bottom"
              // to="/"
              // target="#"
              // style={{padding:'0px'}}
              title="Hatkar Matrimony"
              // tag={Link}
            >
              {/* {userInfo && !userInfo.photoURL &&  userInfo.gender == 'male' ? <img
                    alt={ userInfo.firstName}
                    // src={  userInfo.photoURL}
                    src={require("assets/image/default_User_Icon_Men.png")}
                    style={{ width: '150px', height: '150px' }}
                  /> : null} */}
              {/* {userInfo && !userInfo.photoURL &&   userInfo.gender == 'female' ? <img
                    alt={  userInfo.firstName}
                    // src={  userInfo.photoURL}
                    style={{ width: '150px', height: '150px' }}
                    src={require("assets/image/default_User_Icon_Women.png")}
                  /> : null}
                  { userInfo && userInfo.photoURL ? <img
                    alt={  userInfo.firstName}
                    style={{ width: '150px', height: '150px' }}
                    src={  userInfo.photoURL}
                  /> : null} */}
              <img
                alt="Hatkar Matrimony "
                style={{ width: "50%" }}
                onClick={() => history.push("/")}
                src={require("assets/logo/HatkarMatrimonyLogo.png")}
              />
            </NavbarBrand>
          ) : (
            <NavbarBrand
              data-placement="bottom"
              // to="/"
              // target="#"
              className="text-center"
              style={{ paddingLeft: "20%", paddingRight: "20%" }}
              title="Hatkar Matrimony"
              // tag={Link}
            >
              <img
                alt="Hatkar Matrimony "
                style={{ width: "90%" }}
                onClick={() => history.push("/")}
                src={require("assets/logo/HatkarMatrimonyLogo.png")}
              />
            </NavbarBrand>
          )}

          {navBarBtn && isLoggedin ? (
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          ) : null}
        </div>
        {isLoggedin ? (
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={navbarCollapse}
          >
            <Nav navbar>
              {/* <NavItem>
              <NavLink to="/index" tag={Link}>
                <i className="nc-icon nc-layout-11" /> Components
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://demos.creative-tim.com/paper-kit-react/#/documentation?ref=pkr-examples-navbar"
                target="_blank"
              >
                <i className="nc-icon nc-book-bookmark" /> Documentation
              </NavLink>
            </NavItem> */}
              {/* <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.linkedin.com/company/hatkar-matrimony"
                target="_blank"
                title="Follow us on Linkedin"
              >
                <i className="fa fa-linkedin" />
                <p className="d-lg-none">Linkedin</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/Hatkar-Matrimony-104730247584615/?modal=admin_todo_tour"
                target="_blank"
                title="Like us on Facebook"
              >
                <i className="fa fa-facebook-square" />
                <p className="d-lg-none">Facebook</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/hatkarmatrimony/"
                target="_blank"
                title="Follow us on Instagram"
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </NavLink>
            </NavItem> */}
              {/* <NavItem>
               <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/hatkarmatrimony/"
                target="_blank"
                title="Follow us on Instagram"
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </NavLink>
              <div className="avatar" >
                <img
                  alt="..."
                  style={{maxWidth: '40%', marginTop:'15px'}}
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/faces/joe-gardner-2.jpg")}
                />
                </div>
            </NavItem> */}
              <NavItem className="d-xl-none d-lg-none">
                <Link
                  className="nav-link"
                  to={{
                    pathname: "/",
                  }}
                >
                  Home
                </Link>
              </NavItem>
              <NavItem className="d-xl-none d-lg-none">
                <Link
                  className="nav-link"
                  to={{
                    pathname: "/dashboard",
                  }}
                >
                  Dashboard
                </Link>
              </NavItem>
              <NavItem className="d-xl-none d-lg-none">
                <Link
                  className="nav-link"
                  to={{
                    pathname: "/profile-page",
                  }}
                >
                  User Profile
                </Link>
              </NavItem>
              <NavItem className="d-xl-none d-lg-none">
                <Link
                  className="nav-link"
                  to={{
                    pathname: "/user-setting",
                  }}
                >
                  Settings
                </Link>
              </NavItem>
              <NavItem className="d-xl-none d-lg-none">
                <Link
                  className="nav-link"
                  to={{
                    pathname: "/About-us",
                  }}
                >
                  About us
                </Link>
              </NavItem>
              <NavItem className="d-xl-none d-lg-none">
                <NavLink
                  data-placement="bottom"
                  onClick={(event) => doLogout()}
                >
                  {/* <i className="fa fa-instagram" /> */}
                  <p>Logout</p>
                </NavLink>
              </NavItem>
              <NavItem className="d-none d-xl-block d-lg-block">
                {/* <NavLink> */}

                {/* <i className="fa fa-instagram" /> */}
                {/* <p >Logout</p> */}
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle
                    caret
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      className="avatar"
                      style={{ width: "50px", height: "50px", margin: "1px" }}
                    >
                      {/* <img
                  alt="Hatkar matrimony"
                 
                  style={{maxWidth: '30%', margin:'1px'}}
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/faces/joe-gardner-2.jpg")}
                 
                /> */}

                      {userInfo &&
                      !userInfo.photoURL &&
                      userInfo.gender == "male" ? (
                        <img
                          alt="Hatkar matrimony"
                          // style={{width: '50px', height:'50px', margin:'1px'}}
                          // src={  userInfo.photoURL}
                          className="img-circle img-no-padding img-responsive"
                          src={require("assets/image/default_User_Icon_Men.png")}
                          style={{ marginTop: "5px" }}
                        />
                      ) : null}
                      {userInfo &&
                      !userInfo.photoURL &&
                      userInfo.gender == "female" ? (
                        <img
                          alt="Hatkar matrimony"
                          // style={{width: '50px', height:'50px', margin:'1px'}}
                          // src={  userInfo.photoURL}
                          style={{ marginTop: "5px" }}
                          className="img-circle img-no-padding img-responsive"
                          src={require("assets/image/default_User_Icon_Women.png")}
                        />
                      ) : null}
                      {userInfo && userInfo.photoURL ? (
                        <img
                          alt="Hatkar matrimony"
                          style={{
                            marginTop: "5px",
                            width: "50px",
                            height: "50px",
                          }}
                          // style={{width: '50px', height:'50px', margin:'1px'}}
                          className="img-circle img-no-padding img-responsive"
                          src={userInfo.photoURL}
                        />
                      ) : null}
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>Header</DropdownItem>
                    {/* <DropdownItem header><Link to={{
                    pathname: '/secure-auth'
                  }}> Login</Link></DropdownItem> */}
                    <DropdownItem>
                      <Link
                        to={{
                          pathname: "/dashboard",
                        }}
                      >
                        {" "}
                        Dashboard
                      </Link>
                    </DropdownItem>
                    {/* <DropdownItem disabled>Action (disabled)</DropdownItem> */}
                    <DropdownItem divider />
                    <DropdownItem>
                      <Link
                        to={{
                          pathname: "/profile-page",
                        }}
                      >
                        {" "}
                        User Profile
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <Link
                        to={{
                          pathname: "/user-setting",
                        }}
                      >
                        {" "}
                        Settings
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <a onClick={(event) => doLogout()}>Logout</a>
                    </DropdownItem>
                    {/* <DropdownItem>Quo Action</DropdownItem> */}
                  </DropdownMenu>
                </Dropdown>
                {/* </NavLink> */}
              </NavItem>
            </Nav>
          </Collapse>
        ) : null}
      </Container>
      <div></div>
    </Navbar>
  );
}

export default ExamplesNavbar;
