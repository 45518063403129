/* eslint-disable */
import React, { useState, Component } from 'react';
import { func } from 'prop-types';
import { db, auth, storage } from '../../firebase/firebase';
import { Capacitor } from "@capacitor/core";
// reactstrap components


 export  const IPInfoFetcher = () => {

  var promise = new Promise((resolve, reject) => {
    // try {
    //   let response = await fetch('https://ipinfo.io?token=608557a31a6548');
    //   let user = await response.json();
    //   resolve(user)
    // } catch (err) {
    //   // catches errors both in fetch and response.json
    //   reject(err)
    // }
    // fetch('https://ipinfo.io?token=608557a31a6548', {
    // })
    //   .then((resp) => {
    //     resolve(resp)
    //   }).catch((err) => {
    //     reject(err)
    //   })
   
    async function f() {

      try {
        let response = await fetch('https://ipinfo.io?token=608557a31a6548');
      let user = await response.json();
      resolve(user)
      } catch(err) {
        reject(err)
      }
    }
    f()
  });

  return (
    //ajax stuff here
    promise
  )
}
export const NetworkDetector = () => {

  var promise = new Promise((resolve, reject) => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      fetch('https://www.google.com/', {
        mode: 'no-cors',
      })
        .then(() => {
          resolve(true)
        }).catch(() => {
          reject(false)
        })

    } else {
      reject(false)
    }

  });

  return (
    //ajax stuff here
    promise
  )
}
export const CryptoEncryption = (key, plainText) => {

  var promise = new Promise((resolve, reject) => {
    let CryptoJS = require("crypto-js");
    let ciphertext = CryptoJS.AES.encrypt(plainText, key);
    resolve(ciphertext.toString())
  });

  return (
    //ajax stuff here
    promise
  )
}
export const CryptoDecryption = (key, ciphertext) => {

  var promise = new Promise((resolve, reject) => {
    let CryptoJS = require("crypto-js");
    let bytes = CryptoJS.AES.decrypt(ciphertext.toString(), key);
    let plaintext = bytes.toString(CryptoJS.enc.Utf8);
    resolve(plaintext);
  });

  return (
    //ajax stuff here
    promise
  )
}
export const EncodeData = (id, email) => {

  var promise = new Promise((resolve, reject) => {
    var initialID = id[0].substring(0, 1).toLowerCase();
    var initialEmail = email[0].substring(0, 1).toUpperCase();
    var newId = initialEmail + id + initialID
    CryptoEncryption(email, newId).then(resp => {
      resolve(resp);
    })

  });

  return (
    //ajax stuff here
    promise
  )
}
export const DecodeData = (newId, email) => {

  var promise = new Promise((resolve, reject) => {

    CryptoDecryption(email, newId).then(resp => {
      var existingID = resp.slice(1, -1)
      resolve(existingID);
    })
  });

  return (
    //ajax stuff here
    promise
  )
}

export const fetchUserProfile = () => {

  var promise = new Promise((resolve, reject) => {
    let result = NetworkDetector();
    result.then(result => {
      auth.onAuthStateChanged(function (user) {
        if (user != null) {
          // User is signed in.
          db.collection('ENJOYER_PFOFILE').doc(user.uid).get()
            .then(function (doc) {
              if (doc.exists) {
                var userData = doc.data().userData;
               
                // console.log(userData)
                // if(!userData.ipInfo){
                //   let response = IPInfoFetcher();
                //   response.then(resp =>{
                //     userData.ipInfo = resp;
                //     db.collection("ENJOYER_PFOFILE").doc(user.uid).update({
                //       userData
                //     }).then(function (docRef) {
                //       resolve(userData);
                //     })
                //     .catch(function (error) {
                //       resolve(doc.data().userData);
                //     });
                //     }, function(error) {
                //       // console.log(error);
                //     })
                // }else{
                //    resolve(doc.data().userData);
                // }
                if (Capacitor.isNativePlatform()) {
                  let pushToken = "";
                  if (localStorage.getItem("registrationToken") !== null) {
                    pushToken = localStorage.getItem("registrationToken");
                  }
               
                   if(pushToken && userData.pushToken){
                    if(userData.pushToken.localeCompare(pushToken)!==0 ){
                   //  alert("New FCM Token Found")
                      userData.pushToken=pushToken;
                      db.collection("ENJOYER_PFOFILE").doc(userData.id).update({
                        userData
                        }).then(function (docRef) {
                      resolve(userData);
                    })
                    .catch(function (error) {
                      resolve(userData);
                    });
                    }else{
                      resolve(userData);
                     // alert("No New Token Found")
                    }
                   }else{
                   // alert("Pushing FCM Token")
                    userData.pushToken=pushToken;
                    db.collection("ENJOYER_PFOFILE").doc(userData.id).update({
                      userData
                    }).then(function (docRef) {
                      resolve(userData);
                    })
                    .catch(function (error) {
                      resolve(userData);
                    });
                   }
                  
          
                }else{
                  resolve(userData);
                }
              } else {
                // doc.data() will be undefined in this case
                reject('No such document!');
              }
            });
        } else {
          // No user is signed in.
          console.log(' No user is signed in.');
          reject('No user is signed in');

        }
      });

    }, function (error) {
      reject('Internet');
    });

  });

  return (
    //ajax stuff here
    promise
  )
}
// const fakeAuth = {
//     isAuthenticated: true,
//     authenticate(cb) {
//     //   this.isAuthenticated = true
//       const condition = navigator.onLine ? 'online' : 'offline';
//       if (condition === 'online') {
//         console.log('Hello')
//         fetch('https://www.google.com/', {
//             mode: 'no-cors',
//             })
//         .then(() => {
//             console.log('Hello1')
//             return 'online'
//         }).catch(() => {
//             console.log('Hello2')
//             return 'offline'
//         }  )

//     }else{
//         console.log('Hello3')
//         return 'offline'
//     }
//       setTimeout(cb, 100) // fake async
//     },
//     signout(cb) {
//       this.isAuthenticated = false
//       setTimeout(cb, 100) // fake async
//     }
//   }
//   fakeAuth.authenticate();

// function checkInternet() {
//     // return navigator.onLine ? 'online' : 'offline';
//     const condition = navigator.onLine ? 'online' : 'offline';
//     if (condition === 'online') {

//         fetch('https://www.google.com/', {
//             mode: 'no-cors',
//             })
//         .then(() => {
//             return 'online'
//         }).catch(() => {
//             return 'offline'
//         }  )

//     }else{
//         return 'offline'
//     } 
// }
//   const resp =   checkInternet();
// export default  NetworkDetector ;

// export default function (ComposedComponent) {
//   class NetworkDetector extends Component {
//     state = {
//       isDisconnected: false
//     }


//     componentDidMount() {
//       this.handleConnectionChange();
//       window.addEventListener('online', this.handleConnectionChange);
//       window.addEventListener('offline', this.handleConnectionChange);
//     }

//     componentWillUnmount() {
//       window.removeEventListener('online', this.handleConnectionChange);
//       window.removeEventListener('offline', this.handleConnectionChange);
//     }


//     handleConnectionChange = () => {
//       const condition = navigator.onLine ? 'online' : 'offline';
//       if (condition === 'online') {
//         const webPing = setInterval(
//           () => {
//             fetch('https://www.google.com/', {
//               mode: 'no-cors',
//               })
//             .then(() => {
//               this.setState({ isDisconnected: false }, () => {
//                 return clearInterval(webPing)
//               });
//             }).catch(() => this.setState({ isDisconnected: true }) )
//           }, 2000);
//         return;
//       }

//       return this.setState({ isDisconnected: true });
//     }

//     render() {
//       const { isDisconnected } = this.state;
//       return (
//         <div>

//           { isDisconnected && (<div className="internet-error">
//               <p>Internet connection lost</p>
//             </div>)
//           }
//           <ComposedComponent {...this.props} />
//         </div>
//       );
//     }
//   }

//   return NetworkDetector;
// }