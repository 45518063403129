/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { db } from "../../firebase/firebase";
// import Firebase from 'firebase';

// reactstrap components

import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from "reactstrap";
import { Router, Route, Switch, Redirect, Link } from "react-router-dom";
import { userInfoContext, userListContext } from "../../context/userContext";
import {
  NetworkDetector,
  fetchUserProfile,
} from "../../components/Modals/AsyncTask";
// import { Link } from '@material-ui/core';

// core components
import { Capacitor } from "@capacitor/core";
function LandingPageHeader(props) {
  let pageHeader = React.createRef();
  const [isRegistration, setRegistration] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [defaultContainer, setDefaultContainer] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [getSubscriberList, setSubscriberList] = useState([]);
  const [getSubscribeClick, setSubscribeClick] = useState(false);
  const [modal, setModal] = useState(false);
  const [unmountOnClose, setUnmountOnClose] = useState(false);
  const [getSubscriberName, setSubscriberName] = useState("");
  const [getSubscriberNumber, setSubscriberNumber] = useState("");
  const [getSubscriberEmail, setSubscriberEmail] = useState("");
  const isLoggedin = JSON.parse(localStorage.getItem("isLoggedIn"));
  const { userInfo, setUserInfo } = useContext(userInfoContext);
  const { userList, setUserList } = useContext(userListContext);

  const toggle = () => setModal(!modal);
  const changeUnmountOnClose = (e) => {
    let value = e.target.value;
    setUnmountOnClose(JSON.parse(value));
  };
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  const onLoginClick = () => {
    // setLogin(true)
    // setDefaultContainer(false)
    // setRegistration(false)
    // setForgotPassword(false)
  };
  const setSubscriber = () => {};

  const openSubscribeModal = () => {
    setSubscribeClick(true);
    setModal(true);
  };
  const emailValidation = (value) => {
    return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value);
  };
  const onSubscribeClick = () => {
    //  alert(getSubscriberName);
    if (getSubscriberName == "") {
      alert("Name should not be blank.");
      return;
    }
    if (getSubscriberName.length > 30) {
      alert("Name should not be too longer.");
      return;
    }
    if (getSubscriberNumber == "") {
      alert("Mobile number should not be blank.");
      return;
    }
    if (getSubscriberNumber.length > 12 || getSubscriberNumber.length < 10) {
      alert("Mobile number is not valid.");
      return;
    }
    if (getSubscriberEmail == "") {
      alert("Email should not be blank.");
      return;
    }
    if (!emailValidation(getSubscriberEmail)) {
      alert("Email id is not valid.");
      return;
    }
    db.collection("subscriber")
      .doc(getSubscriberNumber)
      .set({
        name: getSubscriberName,
        number: getSubscriberNumber,
        email: getSubscriberEmail,
      })
      .then(function(docRef) {
        alert("Congratulations!! Your membership has been confirmed.");
        setSubscriberName("");
        setSubscriberNumber("");
        setSubscriberEmail("");
        setModal(false);
        // console.log("Document written with ID: ", docRef);
        //   db.collection("subscriber").get().then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //         console.log(`${doc.id} => ${doc.data()}`);
        //     });
        // });
      })
      .catch(function(error) {
        // console.error("Error adding document: ", error);
        // alert('OOPS!! At this moment we are facing some issues. Please try after some time.')
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log("useEffect subscriber");
    // firebase.initializeApp(config);
    // db.collection("subscriber").add({

    // firebase
    //   .firestore()
    //   .collection('subscriber')
    //   .doc('aHz74ZvpBq9z5LOdYPCw')
    //   .get()
    //   .then(snapshot => {
    //     // allowed for an admin user
    //     // let subscriber = snapshot.val();
    //                  console.log(snapshot)
    //   })
    //   .catch(error => {
    //     console.log(error)
    //     // a non-admin user is denied list permission
    //   });
    //     firebase.database().ref('subscriber/aHz74ZvpBq9z5LOdYPCw').set(
    //       {
    //         name: 'noor adam',
    //         email: 'm@m.com',
    //         mobile:"90934"
    //       }).then(() => { console.log('inserted'); }).catch((error) =>
    //  { console.log(error) })
    //       .then(() => {
    //         firebase.database().ref('subscriber/aHz74ZvpBq9z5LOdYPCw').on('value', (snapshot) => {
    //           let subscriber = snapshot.val();
    //              console.log(subscriber)
    //         })
    //       })
    // const dbData=firebase.database().ref('subscriber').on('value')
    // console.log(dbData);
    //  const dbRef = firebase.database().ref('subscriber').on('value');
    //  console.log('useEffect subscriber'+dbRef)
    //  dbRef.on('value', (snapshot => {
    //    let subscriber = snapshot.val();
    //    console.log(subscriber)
    //  }))
    if (isLoggedin) {
      if (userInfo) {
        // console.log("userInfo has data");
      } else {
        // console.log("userInfo data not found");
        let response = fetchUserProfile();
        response.then(
          (resp) => {
            setUserInfo(resp);
          },
          function(error) {}
        );
      }
    } else {
      // console.log("User is not logged in");
      window.localStorage.setItem("index", 1);
      setUserInfo(null);
      setUserList(null);
    }
  }, []);

  const style = {
    margin: "20px",
  };
  const backgroundColor = {
    backgroundColor: "#f5f5f5",
  };
  const Textcolor = {
    color: "#f5593d",
  };
  var w = window.innerWidth;
  var h = window.innerHeight;
  var marginTopForLogo = h > 700 ? "15%" : "22%";
  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/image/pageHeader_BG.jpg") + ")",
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />

        {!isLoggedin ? (
          <Container>
            <div
              className="motto text-center"
              style={{ marginTop: marginTopForLogo }}
            >
              <img
                alt=""
                className=" img-no-padding img-responsive"
                src={require("assets/logo/HatkarMatrimony.png")}
                style={{ width: "150px", height: "150px" }}
              />

              <h3>Hatkar Matrimony</h3>
              {h > 100 ? <h5>मोफत वधू-वर सूचक नोंदणी</h5> : null}

              {Capacitor.isNativePlatform() ? (
                <div>
                  <Link
                    to={{
                      pathname: "/secure-auth",
                      state: {
                        authType: "LOGIN",
                      },
                    }}
                  >
                    <Button
                      style={style}
                      className="btn-round"
                      color="neutral"
                      type="button"
                      outline
                    >
                      Login
                    </Button>
                  </Link>

                  <Link
                    to={{
                      pathname: "/secure-auth",
                      state: {
                        authType: "REGISTER",
                      },
                    }}
                  >
                    <Button
                      style={style}
                      className="btn-round"
                      color="neutral"
                      type="button"
                      outline
                    >
                      Register
                    </Button>
                  </Link>
                </div>
              ) : (
                <div>
                  <Button
                    style={style}
                    className="btn-round"
                    color="neutral"
                    type="button"
                    href="https://play.google.com/store/apps/details?id=hatkarmatrimony.com"
                    outline
                  >
                    Click to Download App Now
                  </Button>
                </div>
              )}
              <hr />
              <h3>हटकर समाज महाराष्ट्र राज्य.</h3>

              <Button
                style={style}
                className="btn-round"
                color="neutral"
                type="button"
                outline
                onClick={() => props.callBack()}
              >
                हटकर समाज सदस्य नोंदणी
              </Button>
            </div>
          </Container>
        ) : (
          <Container>
            <div
              className="motto text-center"
              style={{ marginTop: marginTopForLogo }}
            >
              <img
                alt=""
                className=" img-no-padding img-responsive"
                src={require("assets/logo/HatkarMatrimony.png")}
                style={{ width: "150px", height: "150px" }}
              />

              <h3>Hatkar Matrimony</h3>
              {Capacitor.isNativePlatform() ? (
                <div>
                  <h5>Welcome Back!!</h5>
                  {/* <br /> */}

                  <Link
                    to={{
                      pathname: "/dashboard",
                    }}
                  >
                    <Button
                      style={style}
                      className="btn-round"
                      color="neutral"
                      type="button"
                      outline
                    >
                      Go To Matrimony Dashboard
                    </Button>
                  </Link>
                </div>
              ) : (
                <div>
                  <Button
                    style={style}
                    className="btn-round"
                    color="neutral"
                    type="button"
                    href="https://play.google.com/store/apps/details?id=hatkarmatrimony.com"
                    outline
                  >
                    Click to Download App Now
                  </Button>
                </div>
              )}
              <hr />
              <h3>हटकर समाज महाराष्ट्र राज्य.</h3>

              <Button
                style={style}
                className="btn-round"
                color="neutral"
                type="button"
                outline
                onClick={() => props.callBack()}
              >
                हटकर समाज सदस्य नोंदणी
              </Button>
            </div>
          </Container>
        )}
      </div>
      {getSubscribeClick ? (
        <div>
          {/* <Form inline onSubmit={(e) => e.preventDefault()}>
                <FormGroup>
                    <Label for="unmountOnClose">UnmountOnClose value</Label>{' '}
                    <Input type="select" name="unmountOnClose" id="unmountOnClose" onChange={changeUnmountOnClose}>
                        <option value="true">true</option>
                        <option value="false">false</option>
                    </Input>
                </FormGroup>
                {' '}
                <Button color="danger" onClick={toggle}>{buttonLabel}</Button>
            </Form> */}
          <Modal isOpen={modal} toggle={toggle} unmountOnClose={unmountOnClose}>
            <ModalHeader toggle={toggle} style={backgroundColor}>
              Subscribe Now
            </ModalHeader>
            <ModalBody style={backgroundColor}>
              <Form className="register-form">
                <label style={Textcolor}>Full Name</label>
                <Input
                  placeholder="Full Name"
                  value={getSubscriberName}
                  onChange={(event) => setSubscriberName(event.target.value)}
                  type="text"
                />
                <br />
                <label style={Textcolor}>Mobile Number</label>
                <Input
                  placeholder="Mobile Number"
                  value={getSubscriberNumber}
                  onChange={(event) => setSubscriberNumber(event.target.value)}
                  type="number"
                />
                <br />
                <label style={Textcolor}>Email</label>
                <Input
                  placeholder="Email"
                  value={getSubscriberEmail}
                  onChange={(event) => setSubscriberEmail(event.target.value)}
                  type="email"
                />
              </Form>
            </ModalBody>
            <ModalFooter style={backgroundColor}>
              <Button
                className="btn-round"
                color="danger"
                style={{ margin: "15px" }}
                onClick={onSubscribeClick}
              >
                Subscribe
              </Button>
              {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button> */}
            </ModalFooter>
          </Modal>
        </div>
      ) : null}
    </>
  );
}

export default LandingPageHeader;
