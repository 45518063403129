/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect } from "react";

// reactstrap components

import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  Form,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function SitemapPage(match) {
  const [activeTab, setActiveTab] = React.useState("1");
  const [getGender, setGender] = React.useState({
    selectedOption: "",
  });
  const [isRegistration, setRegistration] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [defaultContainer, setDefaultContainer] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [authType, setAuthType] = useState("LOGIN");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    if (match.location.state !== undefined) {
      console.log("ProfilePage useEffect Called");
      if (match.location.state.authType == "LOGIN") {
        setLogin(true);
      }
      if (match.location.state.authType == "REGISTER") {
        setRegistration(true);
      }
      setAuthType(match.location.state.authType);
    }

    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  }, []);

  const onLoginClick = () => {
    setLogin(true);
    setDefaultContainer(false);
    setRegistration(false);
    setForgotPassword(false);
  };

  const onRegisterClick = () => {
    setLogin(false);
    setDefaultContainer(false);
    setRegistration(true);
    setForgotPassword(false);
  };
  const clickOnForgotPassword = () => {
    setLogin(false);
    setDefaultContainer(false);
    setRegistration(false);
    setForgotPassword(true);
  };
  const Textcolor = {
    color: "#424242",
  };
  const radioButtonMargin = {
    margin: "0 10px 0 10px",
  };
  const handleOptionChange = (changeEvent) => {
    // console.log(changeEvent.target.value);
    setGender({
      selectedOption: changeEvent.target.value,
    });
  };

  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="name">
              <h4 className="title" style={{ fontSize: "1.8em" }}>
                Sitemap <br />
              </h4>
            </div>
          </div>
          <Row className="description  ">
            {/* <h4 className="title text-center">History of Hatkars</h4> */}
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <div className="description">
                  <h4 style={{ fontSize: "1.1em" }}>
                    <a
                      href="/"
                      style={{ color: "#424242", textTransform: "none" }}
                    >
                      Home
                    </a>
                  </h4>
                </div>
                <div className="description">
                  <h4 style={{ fontSize: "1.1em" }}>
                    <a
                      href="/About-us"
                      style={{ color: "#424242", textTransform: "none" }}
                    >
                      About us
                    </a>
                  </h4>
                </div>
                <div className="description">
                  <h4 style={{ fontSize: "1.1em" }}>
                    <a
                      href="/Hatkar-Committee-Maharashtra-State"
                      style={{ color: "#424242", textTransform: "none" }}
                    >
                      Hatkar-Committee
                    </a>
                  </h4>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div style={{ marginLeft: "15%" }}>
                <div className="description">
                  <h4 style={{ fontSize: "1.1em" }}>
                    <a
                      href="/privacy-policy"
                      style={{ color: "#424242", textTransform: "none" }}
                    >
                      Privacy-Policy
                    </a>
                  </h4>
                </div>
                <div className="description">
                  <h4 style={{ fontSize: "1.1em" }}>
                    <a
                      href="/terms-conditions"
                      style={{ color: "#424242", textTransform: "none" }}
                    >
                      Terms & Conditions
                    </a>
                  </h4>
                </div>
                <div className="description">
                  <h4 style={{ fontSize: "1.1em" }}>
                    <a
                      href="/sitemap"
                      style={{ color: "#424242", textTransform: "none" }}
                    >
                      Sitemap
                    </a>
                  </h4>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row>
                        <Col className="ml-auto mr-auto" md="12">


                            <div className="info">
                                <div className="description">
                                    <h4 style={{ fontSize: '1.1em' }}>
                                        <a href="/" style={{ color: '#424242', textTransform: 'none' }}>Home</a>
                                    </h4>

                                </div>
                                <div className="description">
                                    <h4 style={{ fontSize: '1.1em' }}>
                                        <a href="/About-us" style={{ color: '#424242', textTransform: 'none' }}>About us</a>
                                    </h4>

                                </div>
                                <div className="description">
                                    <h4 style={{ fontSize: '1.1em' }}>
                                        <a href="/Hatkar-Committee-Maharashtra-State" style={{ color: '#424242', textTransform: 'none' }}>Hatkar-Committee</a>
                                    </h4>

                                </div>
                                <div className="description">
                                    <h4 style={{ fontSize: '1.1em' }}>
                                        <a href="/privacy-policy" style={{ color: '#424242', textTransform: 'none' }}>Privacy-Policy</a>
                                    </h4>

                                </div>
                                <div className="description">
                                    <h4 style={{ fontSize: '1.1em' }}>
                                        <a href="/terms-conditions" style={{ color: '#424242', textTransform: 'none' }}>Terms & Conditions</a>
                                    </h4>

                                </div>
                                <div className="description">
                                    <h4 style={{ fontSize: '1.1em' }}>
                                        <a href="/sitemap" style={{ color: '#424242', textTransform: 'none' }}>Sitemap</a>
                                    </h4>

                                </div>
                            </div>
                        </Col>
                    </Row> */}
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default SitemapPage;
