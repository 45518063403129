/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Capacitor } from "@capacitor/core";

// reactstrap components
/* eslint-disable */
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container style={{ maxWidth: "100%" }}>
        <div className="section section-dark text-center">
          <Container>
            {!Capacitor.isNativePlatform() &&
            window.location.pathname === "/" ? (
              <Row>
                <Col md="4">
                  <Card
                    className="card-profile card-plain"
                    style={{ marginTop: "15px" }}
                  >
                    <div
                      className="card-avatar"
                      style={{ borderRadius: "0px" }}
                    >
                      <a href="/" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          src={require("assets/logo/HatkarMatrimony.png")}
                        />
                      </a>
                    </div>
                    <CardBody>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <div className="author">
                          <CardTitle tag="h4">Hatkar Matrimony</CardTitle>
                        </div>
                      </a>
                      <p className="card-description text-center">
                        Connecting Hearts, Building Community.
                      </p>
                      <Button
                        className="btn-just-icon btn-neutral"
                        color="link"
                        href="https://www.instagram.com/hatkarmatrimony/"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-instagram" />
                      </Button>
                      <Button
                        className="btn-just-icon btn-neutral ml-1"
                        color="link"
                        href="https://www.facebook.com/Hatkar-Matrimony-104730247584615/?modal=admin_todo_tour"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-facebook" />
                      </Button>
                      <Button
                        className="btn-just-icon btn-neutral ml-1"
                        color="link"
                        href="https://www.linkedin.com/company/hatkar-matrimony"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-linkedin" />
                      </Button>
                    </CardBody>
                    {/* <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="https://www.instagram.com/hatkarmatrimony/"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-instagram" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="https://www.facebook.com/Hatkar-Matrimony-104730247584615/?modal=admin_todo_tour"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-facebook" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="https://www.linkedin.com/company/hatkar-matrimony"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter> */}
                  </Card>
                </Col>
                <Col md="3">
                  <div className="info">
                    <h2 className="title" style={{ fontSize: "1.8em" }}>
                      Latest Info
                    </h2>
                    <div className="description">
                      <h4 style={{ fontSize: "1.1em" }}>
                        <a
                          href="/"
                          style={{ color: "#fff", textTransform: "none" }}
                        >
                          Home
                        </a>
                        <br />
                        <br />
                        <a
                          href="/About-us"
                          style={{ color: "#fff", textTransform: "none" }}
                        >
                          About us
                        </a>
                        <br />
                        <br />
                        <a
                          href="/Hatkar-Committee-Maharashtra-State"
                          style={{ color: "#fff", textTransform: "none" }}
                        >
                          Hatkar-Committee
                        </a>
                        <br />
                        <br />
                        <a
                          href="/privacy-policy"
                          style={{ color: "#fff", textTransform: "none" }}
                        >
                          Privacy-Policy
                        </a>
                        <br />
                        <br />
                        <a
                          href="/terms-conditions"
                          style={{ color: "#fff", textTransform: "none" }}
                        >
                          Terms & Conditions
                        </a>
                        <br />
                        <br />
                        <a
                          href="/sitemap"
                          style={{ color: "#fff", textTransform: "none" }}
                        >
                          Sitemap
                        </a>
                        <br />
                        <br />
                      </h4>
                    </div>
                    {/* <div className="description">
                  <h4 style={{fontSize:'1.1em'}}>
                  <a href="/privacy-policy" style={{color:'#fff',  textTransform:'none'}}>Privacy-Policy</a>
                    </h4>
                 
                  </div>
                  <div className="description">
                  <h4 style={{fontSize:'1.1em'}}>
                  <a href="/privacy-policy" style={{color:'#fff',  textTransform:'none'}}>Privacy-Policy</a>
                    </h4>
                 
                  </div> */}
                    {/* <div className="description">
                  <h4 style={{fontSize:'1.1em'}}>
                  <a href="/privacy-policy" style={{color:'#fff',  textTransform:'none'}}>Privacy-Policy</a>
                    </h4>
                 
                  </div>
                  <div className="description">
                  <h4 style={{fontSize:'1.1em'}}>
                  <a href="/terms-conditions" style={{color:'#fff',  textTransform:'none'}}>Terms & Conditions</a>
                    </h4>
                 
                  </div>
                  <div className="description">
                  <h4 style={{fontSize:'1.1em'}}>
                  <a href="/sitemap" style={{color:'#fff',  textTransform:'none'}}>Sitemap</a>
                    </h4>
                 
                  </div> */}
                  </div>
                </Col>
                <br />
                <Col md="5">
                  <div className="info">
                    <h2 className="title" style={{ fontSize: "1.8em" }}>
                      Contact Information
                    </h2>
                    {/* <div className="description">
                      <h4 style={{ fontSize: "1.1em" }}>
                        Mobile Number <br /> <br />{" "}
                        <p className="card-description text-center">
                          +918779796716
                        </p>
                      </h4>
                    </div> */}
                    <div className="description">
                      <h4 style={{ fontSize: "1.1em" }}>
                        Email <br /> <br />
                        <a
                          href="mailto:hatkarmatrimony@gmail.com"
                          style={{ color: "#76daff", textTransform: "none" }}
                        >
                          hatkarmatrimony@gmail.com
                        </a>
                      </h4>
                    </div>
                    <div className="description">
                      <h4 style={{ fontSize: "1.1em" }}>
                        Location <br /> <br />{" "}
                        <p className="card-description text-center">
                          Mumbai, Maharashtra, India
                        </p>
                      </h4>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col md="12" style={{ marginTop: "-40px" }}>
                <p
                  style={{
                    color: "#ffffff",
                    textTransform: "none",
                    marginTop: "30px",
                  }}
                >
                  Copyright © {new Date().getFullYear()}, Hatkar Matrimony. All
                  rights reserved | Design by{" "}
                  <a
                    href="https://hatkarmatrimony.com/"
                    style={{ color: "#76daff", textTransform: "none" }}
                  >
                    Hatkar Matrimony Team
                  </a>
                </p>
              </Col>

              {/* <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()}, Online Artemis Team
            </span>
          </div> */}
            </Row>
          </Container>
        </div>
      </Container>
    </footer>
  );
}

export default DemoFooter;
