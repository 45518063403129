/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect } from "react";
// import Firebase from 'firebase';
import MyLoader from "../../components/Modals/Spinner";
import * as firebase from "firebase/app";
// reactstrap components

import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from "reactstrap";
// import { Link } from '@material-ui/core';

// core components

const MobileVerificationModal = (props) => {
  let pageHeader = React.createRef();

  const [modal, setModal] = useState(true);
  const [modalParams, setModalParams] = useState(props.data);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [captchaLabel, setCaptchaLabel] = useState(false);
  const toggle = () => setModal(!modal);
  const LOGIN_INITIAL_STATE = {
    mobileNumber: "",
    otp: "",
    error: null,
    success: null,
  };
  const [mobileAuth, setMobileAuth] = useState(LOGIN_INITIAL_STATE);
  // console.log(props);
  // {title, body, btnOk } = ;
  React.useEffect(() => {
    // if (window.innerWidth < 991) {
    //   const updateScroll = () => {
    //     let windowScrollTop = window.pageYOffset / 3;
    //     pageHeader.current.style.transform =
    //       "translate3d(0," + windowScrollTop + "px,0)";
    //   };
    //   window.addEventListener("scroll", updateScroll);
    //   return function cleanup() {
    //     window.removeEventListener("scroll", updateScroll);
    //   };
    // }
  });
  const captchaVerifier = () => {
    setCaptchaVerified(false);
    if (
      mobileAuth.mobileNumber == "" ||
      mobileAuth.mobileNumber.length !== 10
    ) {
      setMobileAuth((prevState) => ({
        ...prevState,
        error: {
          message:
            "Mobile Number is not valid. Please enter 10 Digit Mobile Number.",
        },
      }));
      return;
    }
    setTimeout(function() {
      setCaptchaLabel(true);
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "normal",
          callback: function(response) {
            // console.log("success", response);
            setCaptchaVerified(true);
            getOTP();
            setCaptchaLabel(false);
          },
          "expired-callback": function() {
            // console.log("expired-callback");
            setCaptchaVerified(false);
          },
        }
      );

      recaptchaVerifier.render().then(function(widgetId) {
        window.recaptchaWidgetId = widgetId;
      });
    }, 2000);
  };
  const getOTP = () => {
    setIsLoading(true);
    setOtpSent(false);
    const phoneNumber = "+91" + mobileAuth.mobileNumber;
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // console.log("confirmationResult : ", confirmationResult);
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setOtpSent(true);
        setMobileAuth((prevState) => ({
          ...prevState,
          success: {
            message: "OTP Sent Successfully",
          },
        }));
        setIsLoading(false);
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        // console.log("confirmationResult error: ", error);
        setMobileAuth((prevState) => ({
          ...prevState,
          error: {
            message: "Error while sending OTP. Please try again",
          },
        }));
        setIsLoading(false);
        setOtpSent(false);
      });
  };
  const verifyOTP = () => {
    if (mobileAuth.otp == "") {
      setMobileAuth((prevState) => ({
        ...prevState,
        error: {
          message: "Please enter OTP",
        },
      }));
      return;
    }
    const code = mobileAuth.otp;
    // confirmationResult.confirm(code).then((result) => {
    //   // User signed in successfully.
    //   const user = result.user;
    //   // ...
    // }).catch((error) => {
    //   // User couldn't sign in (bad verification code?)
    //   // ...
    // });
    var credential = firebase.auth.PhoneAuthProvider.credential(
      confirmationResult.verificationId,
      code
    );
    // console.log("credential : ", credential);
    var user = firebase.auth().currentUser;
    // console.log("current user : ", user);
    if (!user) {
      setMobileAuth((prevState) => ({
        ...prevState,
        error: {
          message: "Error While Verifying Mobile Number",
        },
      }));
      return;
    }
    setIsLoading(true);

    user
      .linkWithCredential(credential)
      .then((success) => {
        // console.log("linkWithCredential : ", success);
        setOtpSent(false);
        closeModal("numberVerified");
        setIsLoading(false);
        // ...
      })
      .catch((error) => {
        //   closeModal("Error While Verifying Mobile Number.");
        setOtpSent(true);
        setIsLoading(false);
        setMobileAuth((prevState) => ({
          ...prevState,
          error: {
            message: error
              ? error.message
              : "Error While Verifying Mobile Number",
          },
        }));
        // console.log("linkWithCredential error: ", error);
      });
  };

  const closeModal = (returnParam) => {
    setModal(false);
    props.callBack(returnParam);
  };
  const onHandleChangeOTP = (event) => {
    // setRegistrationDetails({ [event.target.name]: event.target.value });
    const { name, value } = event.target;
    setMobileAuth((prevState) => ({
      ...prevState,
      [name]: value,
      error: null,
      success: null,
    }));
  };

  const style = {
    margin: "20px",
  };
  const backgroundColor = {
    backgroundColor: "#f5f5f5",
    justifyContent: "space-around",
  };

  const Textcolor = {
    color: "#f5593d",
  };
  var w = window.innerWidth;
  return (
    <Modal isOpen={modal} size={w > 600 ? "lg" : "sm"} centered>
      {/* <MyLoader/> */}
      {isLoading ? <MyLoader /> : null}
      <div>
        <ModalHeader style={backgroundColor}>
          Verify Your Mobile Number
        </ModalHeader>
        <ModalBody
          style={backgroundColor}
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
        >
          <Form className="register-form">
            {captchaLabel ? <label>Please Verify Captcha</label> : null} <br />
            <div id="recaptcha-container"></div> <br />
          </Form>
          {captchaVerified ? (
            <p style={{ color: "green" }}>Captcha is verified</p>
          ) : null}
          <br />
          <Form className="register-form">
            <label>Enter Mobile Number</label>
            <Input
              placeholder="10 Digit Mobile Number"
              type="number"
              name="mobileNumber"
              value={mobileAuth.mobileNumber}
              onChange={onHandleChangeOTP}
            />{" "}
            <br />
            {mobileAuth.success && (
              <p style={{ color: "green" }}>{mobileAuth.success.message}</p>
            )}
            <br />
            {otpSent ? (
              <div>
                <label>Enter OTP</label>
                <Input
                  placeholder="Enter OTP to Verify"
                  type="number"
                  name="otp"
                  value={mobileAuth.otp}
                  onChange={onHandleChangeOTP}
                />
              </div>
            ) : null}
            {mobileAuth.error && (
              <p style={{ color: "red" }}>{mobileAuth.error.message}</p>
            )}
            <br />
          </Form>
        </ModalBody>
        <ModalFooter style={backgroundColor}>
          <Button
            className="btn-round"
            color="default"
            type="button"
            outline
            style={{ margin: "15px" }}
            onClick={(event) => closeModal("goBack")}
          >
            Cancel
          </Button>
          {!otpSent ? (
            <Button
              className="btn-round"
              color="info"
              type="button"
              outline
              onClick={() => captchaVerifier()}
            >
              Send OTP
            </Button>
          ) : (
            <Button
              className="btn-round"
              color="info"
              type="button"
              outline
              onClick={() => verifyOTP()}
            >
              Verify OTP
            </Button>
          )}
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default MobileVerificationModal;
