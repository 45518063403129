/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect } from "react";

// reactstrap components

import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  Form,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import { AdMob, AdOptions } from "@capacitor-community/admob";
import { Capacitor } from "@capacitor/core";
import { GLOBLE_CONSTANT } from "config/constant";
function AboutPage(match) {
  const [activeTab, setActiveTab] = React.useState("1");
  const [getGender, setGender] = React.useState({
    selectedOption: "",
  });
  const [isRegistration, setRegistration] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [defaultContainer, setDefaultContainer] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [authType, setAuthType] = useState("LOGIN");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (Capacitor.isNativePlatform()) {
      // adMobIn();
    }
  }, []);
  const adMobIn = async () => {
    try {
      await AdMob.initialize();
    } catch (error) {
      // console.log("ADMOB INITIALIZE ERROR");
    }

    const options: AdOptions = {
      adId: GLOBLE_CONSTANT.rewardVideoAd_ID,
    };
    await AdMob.prepareRewardVideoAd(options);
    const options_: AdOptions = {
      adId: GLOBLE_CONSTANT.interstitial_Add_ID,
    };
    // Prepare interstitial banner
    await AdMob.prepareInterstitial(options_);

    setTimeout(() => {
      if (getRandomInt(2) == "0") {
        showRrewardVideo();
      } else {
        showInterstitial();
      }
    }, 4000);
  };
  const showInterstitial = async () => {
    await AdMob.showInterstitial();
  };
  const showRrewardVideo = async () => {
    await AdMob.showRewardVideoAd();
  };
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  const onLoginClick = () => {
    setLogin(true);
    setDefaultContainer(false);
    setRegistration(false);
    setForgotPassword(false);
  };

  const onRegisterClick = () => {
    setLogin(false);
    setDefaultContainer(false);
    setRegistration(true);
    setForgotPassword(false);
  };
  const clickOnForgotPassword = () => {
    setLogin(false);
    setDefaultContainer(false);
    setRegistration(false);
    setForgotPassword(true);
  };
  const Textcolor = {
    color: "#fff",
  };
  const radioButtonMargin = {
    margin: "0 10px 0 10px",
  };
  const handleOptionChange = (changeEvent) => {
    // console.log(changeEvent.target.value);
    setGender({
      selectedOption: changeEvent.target.value,
    });
  };

  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="name">
              <h4 className="title">
                About us <br />
              </h4>
            </div>
          </div>

          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <h5 className="description text-center">
                <marquee
                  direction="left"
                  width="400"
                  style={{ fontWeight: "800", color: "green" }}
                >
                  संकेतस्थळावर जाहिरात करण्यासाठी संपर्क करा :
                  hatkarmatrimony@gmail.com
                </marquee>
              </h5>
              <hr />
              <h5 className="description">
                Inspired by{" "}
                <a href="https://en.wikipedia.org/wiki/Atmanirbhar_Bharat">
                  Atmanirbhar Bharat Abhiyan.
                </a>{" "}
                We have created a free digital platform initiative towards{" "}
                Social work. Come join us to serve our community better.
              </h5>
              <h5
                className="title"
                style={{ color: "#604f43", fontWeight: 600 }}
              >
                <b>Connecting Hearts, Building Community</b>
              </h5>
              <h5 className="description">
                At Hatkar Matrimony, we're not just about finding life partners;
                we're about creating a community where Hatkar hearts can
                connect, laugh, and thrive together.
              </h5>
              <br />
              <h5
                className="title"
                style={{ color: "#604f43", fontWeight: 600 }}
              >
                <b>Our Mission: Connecting People, Building Bonds</b>
              </h5>
              <h5 className="description">
                Our mission is simple: to build a Hatkar community where people
                can connect, collaborate, and find joy in each other's company.
              </h5>
              <br />
              <h5
                className="title"
                style={{ color: "#604f43", fontWeight: 600 }}
              >
                <b>Vision: Uniting Hatkar Community</b>
              </h5>
              <h5 className="description">
                Inspired by Atmanirbhar Bharat Abhiyan, we've created a platform
                to unite the Hatkar community. Let's join hands for events,
                social work, and the joy of knowing our community.
              </h5>
              <br />
              <h5
                className="title"
                style={{ color: "#604f43", fontWeight: 600 }}
              >
                <b>Together Through Tough Times</b>
              </h5>
              <h5 className="description">
                In these challenging times, we're committed to bringing the
                Hatkar community together. Let's support each other and find
                solace in our togetherness.
              </h5>
              <br />
              <h5
                className="title"
                style={{ color: "#604f43", fontWeight: 600 }}
              >
                <b>Connect, Collaborate, Celebrate</b>
              </h5>
              <h5 className="description">
                Find perfect matches within your Hatkar community and connect
                for events, social work, and digital initiatives. Because a
                connected community is a happy community!
              </h5>
              <br />
              <h5
                className="title"
                style={{ color: "#604f43", fontWeight: 600 }}
              >
                <b>Join Hatkar Matrimony: Simple, Free, and Heartfelt</b>
              </h5>
              <h5 className="description">
                Register for free and become part of a community that cares.
                Let's create a space where Hatkar community members can connect,
                collaborate, and build lasting memories.
              </h5>
              <br />
              <h5
                className="title"
                style={{ color: "#604f43", fontWeight: 600 }}
              >
                <b>Community Connect Initiatives</b>
              </h5>
              <h5 className="description">
                Embark on a journey of community connect initiatives with Hatkar
                Matrimony. From events to social work, let's make a positive
                impact together.
              </h5>
              <br />
              <h5
                className="title"
                style={{ color: "#604f43", fontWeight: 600 }}
              >
                <b>Know Your Community</b>
              </h5>
              <h5 className="description">
                Explore the rich tapestry of the Hatkar community. Discover
                stories, traditions, and the incredible diversity that makes us
                who we are.
              </h5>
              <br />
              <h5
                className="title"
                style={{ color: "#604f43", fontWeight: 600 }}
              >
                <b>Hatkar Matrimony: Connecting Hearts, Building Community</b>
              </h5>
              <h5 className="description">
                Because at Hatkar Matrimony, we believe that a connected
                community is a happy and thriving community. Join us in the
                celebration of love, laughter, and community spirit!
              </h5>
              <br />
              <h5
                className="title"
                style={{ color: "#604f43", fontWeight: 600 }}
              >
                <b>History of Hatkars</b>
              </h5>
              <h5 className="description">
                <b style={{ fontWeight: "600" }}>हटकर</b> हा शब्द हट्टीकारा या
                कन्नड शब्दावरुन आला आहे. कन्नड मराठी शब्दकोशा मधे हट्टी म्हणजे
                गाई-बैल असा अर्थ दिलेला अढळतो. हट्टीकारा म्हणजे गाईबैलांचा मालक.
                हटकर हे पशुपालक असले तरी चालुक्य ,होयसळ राजघराण्यांचे सेनापती
                सैनिक हे हटकर असल्याचे उल्लेख कन्नड शिलालेखांमधे सापडतात.
                महाराष्ट्रात असलेले हटकर ही एक लढाऊ जमात आहे. परंपरेने लढाई करणे
                हा त्यांचा प्रमुख व्यवसाय होता. यातूनच त्यांच्यात अनेक मोठी
                सरदार घराणी उदयाला आल्याचे दिसते यात सरदार धायगुडे, राजेपांढरे,
                बळंवतराव किताब आसलेले देवकाते, राजे सरगर ,मासाळ,हंडे इ. घराणी
                सापडतात यांत नेमाजी शिंदे या हटकर मराठी वीराने सर्वप्रथम नर्मनदी
                ओलांडली,त्याच काळात दामाजी थोरात हे एक पराक्रमी सरदार होते. .
                हटकरांना हट्टीकारा, बाराहट्टी, बरहट्टी, झेंडे, बंडगर, बंडे,
                बर्गी धनगर, बारगीर या ना्वांनीसुद्धा ओळखले जाते.
              </h5>
              <h5 className="description">
                तसेच काही हटकरांना पाटील, राव, नाईक, देशमुख, राजे या उपाध्या
                आहेत.
              </h5>
              <h5 className="description">
                Captain Fitzgerald यांच्या मते उत्तर हिंदुस्थानातून १३ व्या आणि
                १४ व्या शतकात महाराष्ट्रात बारा कुळींचे लोक एकत्र आले आणि
                त्यांनी एक बारा-हट्टी गाव तयार केले. त्याला बारा हट्टीचा देश
                म्हणू लागले. सध्या या भागाला हिंगोली आणि आजूबाजूचा परिसर म्हणून
                ओळखले जाते, आणि यावरूनच पुढे हटकर असे नाव पडले. "१४ व्या शतकात
                जेव्हा निजाम दख्खनचा सुभेदार म्हणून आला होता त्याच काळात
                हटकरसुद्धा आले आहेत असे ते म्हणतात" सर्व हटकर हे मेंढीपालन
                करतात, ते जेव्हा कधी मोहिमेवर जातात तेव्हा हातात एक भाला आणि सात
                हात लांब घोंगडी घेऊनच निघतात.त्यामुळे त्यांना बर्गी धनगर आणि
                बारगीर सुद्धा म्हटले जाते. हटकरांचा स्वभाव हा हट्टी आणि भांडखोर
                आहे.
              </h5>
              <h5 className="description">
                अकबराने 'ऐन-ए-अकबरी' नामक ग्रंथात हटकरांचा उल्लेख केला आहे तो
                असा "हटकर हे स्वाभिमानी आहेत, ही राजपुतांची पराक्रमी आणि घमंडी
                प्रकारची जात आहे. त्यांनी बाशीम (सध्याचे वाशीम) येथे सशस्त्र
                सेना तयार केली आहे त्यांच्याकडे १००० घोडदळ आणि ५००० पायदळ सैन्य
                आहे. त्यांनी आजूबाजूचे राज्य आणि किल्ले कब्जात ठेवले आहेत.
                त्यांना धनगर पण म्हणतात पण ते राजपूत आहेत आणि हे खरे आहे.
              </h5>
              <h5 className="description">
                निजामाच्या राज्यात यांचा दरारा आहे. आणि ब्रिटिशांच्या काळात
                त्यांना सतत युद्धाला उभे राहणारे आणि बंड करण्यात कुख्यात आहेत,
                असेच समजले जाते. हटकरांमध्ये युद्धात छातीवर वार घेणे आणि शहीद
                होणे ही अभिमानाची गोष्ट समजली जाते. हटकरांमध्ये मिश्या कधी कापत
                नाहीत. मिश्या ठेवणाऱ्यांना मान दिला जातो. हटकर पुरुष हे शारीरिक
                दृष्ट्या धष्ट-पुष्ट, स्वतंत्र राहणारे, आणि स्वाभिमानी असतात.
                हटकरांना भटके असेही म्हटले जाते. महाराष्ट्रात आल्यावर यांचा
                मुख्य व्यवसाय मेंढपाळ हा आहे. पण मुळचेच शूर असल्यामुळे यातील
                काही सैन्यात भरती झाले आणि काहींनी गावातील प्रशासनात सहभाग
                घेतला.
              </h5>
              <h5 className="description">
                हटकरांचा ध्वज: हटकरांचा स्वतःचा वेगळा ध्वज असतो, निजामाच्या
                काळात हटकरांची संख्या अधिक होती. त्यांच्या लष्करी पेशाला शोभेल
                असा झेंडा त्यांच्याकडे असे, झेंड्याचा वरील भाग हा पिवळा असून
                खालच्या बाजूला तो लालसर रंगाचा असे. समाजातील कुठल्याही सामाजिक
                कार्यात, किंवा युद्धाच्या वेळ हटकर स्वतःचा झेंडा घेऊनच निघतात.
                इतिहासात अशाच प्रकारचे झेंडे दोन साम्राज्यांचे आहेत. एक म्हणजे
                दक्षिणेतील विजयनगरच्या साम्राज्यातील वडियार घराण्याचे म्हैसूरचे
                राज्य. त्यांचा झेंडा तंतोतंत असाच आहे. आणि दुसरे म्हणजे राजस्थान
                आणि पंजाब मधील भाटी राजपुतांच्या राज्याचे झेंडे. हे झेंडे रंगाने
                मिळते जुळते आहेत.
              </h5>
              <h5 className="description">
                Source -{" "}
                <a href="https://mr.wikipedia.org/wiki/%E0%A4%B9%E0%A4%9F%E0%A4%95%E0%A4%B0#cite_note-2">
                  Wikipedia
                </a>
              </h5>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h5 className="title text-center">
                To advertise on the website, kindly contact us via email at{" "}
                <a
                  href="mailto:hatkarmatrimony@gmail.com"
                  style={{ color: "#76daff", textTransform: "none" }}
                >
                  hatkarmatrimony@gmail.com
                </a>
              </h5>
            </Col>
          </Row>
          <br />
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <Button className="btn-round" color="info" href="/">
                Back to Home Page
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default AboutPage;
