/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { db, auth, storage } from "../../firebase/firebase";
import imageCompression from "browser-image-compression";
import MyLoader from "../../components/Modals/Spinner";
import LoadingOverlay from "react-loading-overlay";
import {
  NetworkDetector,
  fetchUserProfile,
  IPInfoFetcher,
  EncodeData,
  DecodeData,
} from "../../components/Modals/AsyncTask";
import { Capacitor } from "@capacitor/core";
import { AdMob, AdOptions } from "@capacitor-community/admob";
// reactstrap components

import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardTitle,
  CardText,
  Badge,
  CardHeader,
  CardFooter,
  Progress,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import AlertModal from "../../components/Modals/Modals";
import { userInfoContext } from "../../context/userContext";
import SkeletonCard from "components/SkeletonCard/SkeletonCard";
import TroubleFetching from "components/TroubleFetching/TroubleFetching";
import ImgsViewer from "react-images-viewer";
import { GLOBLE_CONSTANT } from "config/constant";
import ImageViewerModal from "components/Modals/imageVieweModal";

function ProfilePage() {
  const userDataInitialState = {
    data: {},
  };
  const ALERT_PARAMS_INITIAL_STATE = {
    title: "",
    body: "",
    btnOk: "",
    btnCancel: "",
  };

  const [activeTab, setActiveTab] = useState("1");
  const [userData, setUserData] = useState({});
  const [userDataEditCancel, setUserDataEditCancel] = useState({});
  const [UID, setUID] = useState("");
  const [editEnabled, setEditStatus] = useState(false);
  const [editPhoto, setEditPhoto] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const [photoStream, setPhotoStream] = useState("");
  const [getProgress, setProgress] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const [photoUploadStatus, setPhotoUploadStatus] = useState(null);
  const [getError, setError] = useState(null);
  const [alertParams, setAlertParams] = useState(ALERT_PARAMS_INITIAL_STATE);
  const [showConfirmAlert, setConfirmAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, setUserInfo } = useContext(userInfoContext);
  const [isSkeleton, setSkeleton] = useState(true);
  const [poorConnection, setPoorConnection] = useState(false);
  const [showProflePicture, setShowProflePicture] = useState(false);
  const [imgSRC, setImgSRC] = useState("");
  const [imageViewParams, setImageViewParams] = useState("");
  const [imageViewerModal, setImageViewerModal] = useState(false);
  var resetNetwork;
  var ntwokChk = false;
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 400,
      // useWebWorker: true
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      setPhotoStream(compressedFile);
      setError(null);
      //  const urlLink = await uploadToServer(compressedFile);
      //  setPhotoURL(urlLink)
      // setImage( event.target.files[0]); // write your own logic
    } catch (error) {
      // console.log(error);
    }
  }
  const successCallBackData = (data) => {
    // console.log(data);
    setConfirmAlert(false);
    if (data == "RECONNECT") {
      updateUserInfo();
    } else if (data == "RELOAD") {
      // fetchData();
      initialFetch();
    } else if (data == "TRY AGAIN") {
      updateUserPhoto();
    }
  };
  const updateUserPhoto = () => {
    setNetworkTimeOut(60000);
    const fileName =
      userData.firstName + "_" + userData.lastName + "_" + userData.timestamp;
    if (photoStream == "") {
      errorUpdate("Please select photo");
      return;
    }

    setShowProgress(true);
    let result = NetworkDetector();
    result.then(
      (result) => {
        const uploadTask = storage.ref(`images/${fileName}`).put(photoStream);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // progress function ...
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // this.setState({ progress });
            setProgress(progress);
            // console.log(progress)
          },
          (error) => {
            // Error function ...
            setProgress(null);
            // console.log(error);
            ntwokChk = false;
            clearTimeout(resetNetwork);
          },
          () => {
            // complete function ...
            storage
              .ref("images")
              .child(fileName)
              .getDownloadURL()
              .then((url) => {
                setPhotoURL(url);
                //   setUserData(prevState => ({
                //     ...prevState,
                //     photoURL: url
                // }))

                userData.photoURL = url;
                // console.log(url);
                ntwokChk = true;
                clearTimeout(resetNetwork);
                setNetworkTimeOut(40000);
                db.collection("ENJOYER_PFOFILE")
                  .doc(userInfo.id)
                  .update({
                    userData,
                  })
                  .then(function(docRef) {
                    ntwokChk = true;
                    clearTimeout(resetNetwork);
                    // console.log(docRef)
                    // setEditPhoto(false);
                    // setUserData(userData);
                    fetchData();
                    setPhotoUploadStatus(
                      "Congratulations!! Your photo has been uploaded succcessfully."
                    );
                    // alert('Congratulations!! Your photo has been updated succcessfully');
                  })
                  .catch(function(error) {
                    setPhotoUploadStatus(
                      "Sorry!! We are facing issue while uploading your photo. Please try again."
                    );
                    console.error("Error adding document: ", error);
                    ntwokChk = false;
                    clearTimeout(resetNetwork);
                    // alert('OOPS!! At this moment we are facing some issues. Please try after some time.')
                  });
                // increment(url);
                // auth.onAuthStateChanged(function(user) {
                //   console.log(user)
                // user.updateProfile({
                //   photoURL: url
                // }).then(function() {
                //   // Update successful.
                //   console.log(' Update successful')
                // }).catch(function(error) {
                //   // An error happened.
                //   console.log(error)
                // });
                // })
                return url;
              });
          }
        );
      },
      function(error) {
        setAlertParams((prevState) => ({
          ...prevState,
          title: "Internet Disconnected",
          body: "Please check your internet connection and try again.",
          btnOk: "TRY AGAIN",
          btnCancel: "CANCEL",
        }));
        setIsLoading(false);
        setConfirmAlert(true);
        ntwokChk = false;
        clearTimeout(resetNetwork);
      }
    );
  };
  // const uploadToServer = (compressedFile)=>{
  //   console.log('uploadToServer success')
  //   console.log(compressedFile)
  // }

  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  useEffect(() => {
    // console.log(userInfo);

    initialFetch();
    // let result = NetworkDetector()
    // console.log(result);
    // result.then( result => {
    //   alert(result)
    //   // this.setState({name: result});
    //  }, function(error) {
    //   // this.setState({name: error});
    //  });
    // auth.onAuthStateChanged(function(user) {
    //  console.log(user)
    // })
    // setUserData(prevState => ({
    //   ...prevState, data:'dskasnd'
    // }));

    setPhotoURL("");
    window.scrollTo(0, 0);
    if (Capacitor.isNativePlatform()) {
      // adMobIn();
    }
  }, []);
  const adMobIn = async () => {
    try {
      await AdMob.initialize();
    } catch (error) {
      // console.log("ADMOB INITIALIZE ERROR");
    }

    const options: AdOptions = {
      adId: GLOBLE_CONSTANT.rewardVideoAd_ID,
    };
    await AdMob.prepareRewardVideoAd(options);
    // const options_: AdOptions = {
    //   adId: GLOBLE_CONSTANT.interstitial_Add_ID,
    // };
    // // Prepare interstitial banner
    // await AdMob.prepareInterstitial(options_);

    setTimeout(() => {
      showRrewardVideo();
    }, 4000);
  };
  const showRrewardVideo = async () => {
    await AdMob.showRewardVideoAd();
  };
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  const setNetworkTimeOut = (timer) => {
    ntwokChk = false;
    resetNetwork = setTimeout(() => {
      if (ntwokChk) {
        // console.log("INTERNET WORKING");
        setIsLoading(false);
        setSkeleton(false);
      } else {
        // console.log("SHOW NETWORK ERROR");
        setPoorConnection(true);
        setIsLoading(false);
        setSkeleton(false);
      }
    }, timer);
  };
  function initialFetch() {
    setNetworkTimeOut(40000);
    if (userInfo) {
      // console.log("userInfo has data");
      clearTimeout(resetNetwork);
      setUserData(userInfo);
      setUserDataEditCancel(userInfo);
      setIsLoading(false);
      setPoorConnection(false);
      setSkeleton(false);
      ntwokChk = true;
    } else {
      // console.log("userInfo data not found");
      fetchData();
    }
  }
  useEffect(() => {}, [userData], [photoURL]);
  const fetchData = () => {
    let response = fetchUserProfile();
    // setIsLoading(true)
    response.then(
      (resp) => {
        // console.log(resp.id);
        // EncodeData(resp.id, resp.email).then(resp1 =>{
        //   console.log(resp1)
        //   DecodeData(resp1, resp.email).then(resp2 =>{
        //     console.log(resp2)
        //   })
        // })
        setUserData(resp);
        setUserInfo(resp);
        setUserDataEditCancel(resp);
        setIsLoading(false);
        setPoorConnection(false);
        setSkeleton(false);
        ntwokChk = true;
        clearTimeout(resetNetwork);
      },
      function(error) {
        setIsLoading(false);
        ntwokChk = false;
        console.log("Internet Disconnected");
        setSkeleton(false);
        clearTimeout(resetNetwork);
        if (error == "Internet") {
          //   setAlertParams(prevState => ({
          //     ...prevState,
          //     title: 'Internet Disconnected',
          //     body: 'Please check your internet connection and try again.',
          //     btnOk: 'RELOAD',
          //     btnCancel:'CANCEL'

          // }))
          // setConfirmAlert(true);
          setPoorConnection(true);
        }
      }
    );
  };

  // const callbackRef = useRef('');
  // useEffect(() => {
  //   // callbackRef.current = callback;
  //   console.log(callbackRef.current)
  // }, []);
  const onHandleChange = (event) => {
    //   setUserData(prevState => ({
    //     ...prevState, data:'Hello'
    // }));
    // setUserData(prevState => [{ ...prevState,  allowedState}]);
    const { name, value } = event.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
      error: null,
    }));
  };
  const cancelUpdate = () => {
    setEditStatus(false);
    setEditPhoto(false);
    setUserData(userDataEditCancel);
    setPhotoUploadStatus(null);
    setProgress(null);
    setPhotoStream("");
    setShowProgress(false);
  };
  const updateUserInfo = () => {
    // let result = NetworkDetector()
    // result.then( result => {
    //   setAlertParams(prevState => ({
    //     ...prevState,
    //     title: 'Internet Disconnected from success',
    //     body: 'Please check your internet connection and try again.',
    //     btnOk: 'RECONNECT',
    //     btnCancel:'CANCEL'

    // }))
    // setConfirmAlert(true);
    //   console.log('Internet Disconnected')
    // }, function(error) {
    //   setAlertParams(prevState => ({
    //     ...prevState,
    //     title: 'Internet Disconnected',
    //     body: 'Please check your internet connection and try again.',
    //     btnOk: 'RECONNECT',
    //     btnCancel:'CANCEL'

    // }))
    // setIsLoading(false);
    // setConfirmAlert(true);
    //   console.log('Internet Disconnected')
    // });

    // return;
    // if(!userData.ipInfo){
    //   let response = IPInfoFetcher();
    //   response.then(resp =>{
    //       // console.log(resp);
    //       userData.ipInfo = resp;
    //     }, function(error) {
    //       // console.log(error);
    //     })
    // }
    if (!userData.firstName || userData.firstName == "") {
      errorUpdate("First name should not be blank");
      return;
    }
    if (userData.firstName.length > 20) {
      errorUpdate("First name should not be too longer");
      return;
    }
    if (!userData.lastName || userData.lastName == "") {
      errorUpdate("Last name should not be blank");
      return;
    }
    if (userData.lastName.length > 20) {
      errorUpdate("Last name should not be too longer");
      return;
    }
    if (!userData.height || userData.height == "") {
      errorUpdate("Height should not be blank");
      return;
    }
    if (userData.height.length > 15) {
      errorUpdate("Please enter valid height");
      return;
    }
    if (!userData.weight || userData.weight == "") {
      errorUpdate("Weight should not be blank");
      return;
    }
    if (userData.weight.length > 3) {
      errorUpdate("Please enter valid weight");
      return;
    }

    if (
      !userData.maritalStatus ||
      userData.maritalStatus == "" ||
      userData.maritalStatus == "NA"
    ) {
      errorUpdate("Please select Marital status");
      return;
    }
    if (userData.maritalStatus.length > 20) {
      errorUpdate("Marital status should not be too longer");
      return;
    }

    if (
      !userData.bodyType ||
      userData.bodyType == "" ||
      userData.bodyType == "NA"
    ) {
      errorUpdate("Please select Body type");
      return;
    }
    if (userData.bodyType.length > 20) {
      errorUpdate("Body type should not be too longer");
      return;
    }

    if (!userData.diet || userData.diet == "" || userData.diet == "NA") {
      errorUpdate("Please select diet");
      return;
    }
    if (userData.diet.length > 15) {
      errorUpdate("Diet should not be too longer");
      return;
    }

    if (!userData.nativePlace || userData.nativePlace == "") {
      errorUpdate("Native place should not be blank");
      return;
    }
    if (userData.nativePlace.length > 20) {
      errorUpdate("Native place should  not be too longer");
      return;
    }

    if (!userData.currentPlace || userData.currentPlace == "") {
      errorUpdate("Current place should not be blank");
      return;
    }
    if (userData.currentPlace.length > 20) {
      errorUpdate("Current place should  not be too longer");
      return;
    }

    if (!userData.profession || userData.profession == "") {
      errorUpdate("Profession should not be blank");
      return;
    }
    if (userData.profession.length > 20) {
      errorUpdate("Profession should  not be too longer");
      return;
    }

    if (
      !userData.annualIncome ||
      userData.annualIncome == "" ||
      userData.annualIncome == "NA"
    ) {
      errorUpdate("Please select Annual income");
      return;
    }
    if (userData.annualIncome.length > 20) {
      errorUpdate("Annual income should not be too longer");
      return;
    }
    if (
      !userData.employedIn ||
      userData.employedIn == "" ||
      userData.employedIn == "NA"
    ) {
      errorUpdate("Please select Employed in");
      return;
    }

    if (
      !userData.fatherStatus ||
      userData.fatherStatus == "" ||
      userData.fatherStatus == "NA"
    ) {
      errorUpdate("Please select Father status");
      return;
    }
    if (userData.fatherStatus.length > 20) {
      errorUpdate("Father status should  not be too longer");
      return;
    }

    if (
      !userData.motherStatus ||
      userData.motherStatus == "" ||
      userData.motherStatus == "NA"
    ) {
      errorUpdate("Please select Mother status");
      return;
    }
    if (userData.motherStatus.length > 20) {
      errorUpdate("Mother status should  not be too longer");
      return;
    }

    if (!userData.brothers || userData.brothers == "") {
      errorUpdate("Brothers field should not be blank");
      return;
    }
    if (userData.brothers.length > 2) {
      errorUpdate("Brothers count should  not be too longer");
      return;
    }

    if (!userData.sisters || userData.sisters == "") {
      errorUpdate("Sisters field should not be blank");
      return;
    }
    if (userData.sisters.length > 2) {
      errorUpdate("Sisters count should not be too longer");
      return;
    }

    if (!userData.mobileNumber || userData.mobileNumber == "") {
      errorUpdate("Mobile number should not be blank");
      return;
    }
    // if (userData.mobileNumber.length > 12 || userData.mobileNumber.length < 10) {
    if (userData.mobileNumber.length !== 10) {
      errorUpdate("Mobile number is not valid");
      return;
    }

    setIsLoading(true);
    setNetworkTimeOut(40000);
    let result = NetworkDetector();
    result.then(
      (result) => {
        // console.log(userData);
        db.collection("ENJOYER_PFOFILE")
          .doc(userInfo.id)
          .update({
            userData,
          })
          .then(function(docRef) {
            // console.log(docRef)
            setEditStatus(false);
            setError(null);
            ntwokChk = true;
            setUserData(userData);
            setUserInfo(userData);
            clearTimeout(resetNetwork);
            // alert('Congratulations!! Your membership has been confirmed.')
            setAlertParams((prevState) => ({
              ...prevState,
              title: "Success",
              body: "Your profile has been successfully updated.",
              // btnOk: 'YES',
              btnCancel: "OK",
            }));
            setConfirmAlert(true);
            setIsLoading(false);
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
            setIsLoading(false);
            ntwokChk = false;
            clearTimeout(resetNetwork);
            // alert('OOPS!! At this moment we are facing some issues. Please try after some time.')
          });
      },
      function(error) {
        setAlertParams((prevState) => ({
          ...prevState,
          title: "Internet Disconnected",
          body: "Please check your internet connection and try again.",
          btnOk: "RECONNECT",
          btnCancel: "CANCEL",
        }));
        setIsLoading(false);
        setConfirmAlert(true);

        console.log("Internet Disconnected");
        ntwokChk = false;
        clearTimeout(resetNetwork);
      }
    );
  };
  async function errorUpdate(msg) {
    await setError({
      message: msg,
    });
  }
  async function increment(url) {
    await setPhotoURL(url);
    // console.log(photoURL)
  }

  const editDetailsClick = () => {
    setEditStatus(true);
    setError(null);
  };
  const editPhotoClick = () => {
    setEditPhoto(true);
    setError(null);
  };
  function closeViewer() {
    setShowProflePicture(false);
  }
  function showProfilePic() {
    let imageData = {};
    if (userData.photoURL) {
      imageData.title = userData.firstName + " " + userData.lastName;
      imageData.image_URL = userData.photoURL;
      setImageViewParams(imageData);
      setImageViewerModal(false);
      setTimeout(() => {
        setImageViewerModal(true);
      }, 100);
    }
  }

  const cntImage = 0;
  const imgWidth = 220;
  const open = true;
  return (
    <>
      {imageViewerModal ? <ImageViewerModal data={imageViewParams} /> : null}
      {isLoading ? <MyLoader /> : null}
      {isSkeleton ? (
        <SkeletonCard />
      ) : (
        <div>
          {poorConnection ? (
            <TroubleFetching callBack={initialFetch} />
          ) : (
            <div>
              <ExamplesNavbar />
              <ProfilePageHeader />
              {/* <NetworkDetector/> */}
              {/* <LoadingOverlay
      active={!showProgress}
      spinner
      text='Loading your content...'
      >
      <p>Some content or children or something.</p>
    </LoadingOverlay> */}

              <div
                className="section profile-content"
                style={{ textTransform: "capitalize" }}
              >
                <Container>
                  <div className="owner">
                    <div className="avatar">
                      {!userData.photoURL && userData.gender == "male" ? (
                        <img
                          alt={userData.firstName}
                          className="img-circle img-no-padding img-responsive"
                          src={require("assets/image/default_User_Icon_Men.png")}
                          style={{ width: "150px", height: "150px" }}
                          // onClick={showProfilePic}
                        />
                      ) : null}
                      {!userData.photoURL && userData.gender == "female" ? (
                        <img
                          alt={userData.firstName}
                          className="img-circle img-no-padding img-responsive"
                          style={{ width: "150px", height: "150px" }}
                          src={require("assets/image/default_User_Icon_Women.png")}
                          // onClick={showProfilePic(require("assets/image/default_User_Icon_Women.png"))}
                        />
                      ) : null}
                      {userData.photoURL ? (
                        <img
                          alt={userData.firstName}
                          style={{ width: "150px", height: "150px" }}
                          className="img-circle img-no-padding img-responsive"
                          src={userData.photoURL}
                          onClick={showProfilePic}
                        />
                      ) : null}
                      {!editEnabled && !editPhoto ? (
                        <Badge
                          color="secondary"
                          onClick={(event) => editPhotoClick()}
                        >
                          {" "}
                          <i className="fa fa-pencil" />
                          Edit Photo
                        </Badge>
                      ) : null}
                    </div>
                    <div className="name">
                      <h4 className="title">
                        {userData.firstName} {userData.lastName} <br />
                      </h4>
                      {/* <h6 className="description">Hello Producer</h6> */}
                      {/* <input type="file"  accept="image/*" onChange={event => setImage( event.target.files[0])} /> */}
                    </div>
                  </div>
                  {/* <input type="file" accept="image/*" onChange={event => handleImageUpload(event)}/> */}

                  {/* <Button className="btn-round" color="default" outline  style={{ position:"absolute", float:"right",right:'0px'}} onClick={ event => uploadPhoto()}>
                <i className="fa fa-pencil"  /> Upload
           </Button> */}
                  {/* {!editEnabled && !editPhoto ? <Row >
            <Col className="ml-auto mr-auto" md="8" >
            
              <br />
              <Button className="btn-round" color="default" outline  style={{ position:"absolute", float:"right",right:'0px'}} onClick={event => editPhotoClick() }>
                <i className="fa fa-pencil"  /> Uplpad Photo
              </Button>
              <br />
               <br />
            </Col>
          </Row>: null}  */}
                  {editPhoto ? (
                    <Container>
                      <Row>
                        <Col className="ml-auto mr-auto r" md="8">
                          <br />
                          <div className="nav-tabs-navigation">
                            <div className="nav-tabs-wrapper">
                              <Nav role="tablist" tabs>
                                <NavItem>
                                  <NavLink
                                    className={
                                      activeTab === "1" ? "active" : ""
                                    }
                                    onClick={() => {
                                      toggle("1");
                                    }}
                                  >
                                    Upload Profile Picture
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </div>
                          </div>
                          <Card>
                            {/* <CardHeader>
                  <p className='title'>Edit Details</p>
                </CardHeader> */}
                            <CardBody>
                              <div className="d-flex justify-content-around">
                                <div
                                  className="p-2 col-example"
                                  style={{ width: "90%" }}
                                >
                                  <label> Upload Photo</label>
                                  <Input
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) =>
                                      handleImageUpload(event)
                                    }
                                  />{" "}
                                  <br />
                                  {showProgress ? (
                                    <div>
                                      {getProgress < 100 ? (
                                        <label className="text-center">
                                          Uploading...{getProgress}%
                                        </label>
                                      ) : (
                                        <label className="text-center">
                                          Photo Uploaded.
                                        </label>
                                      )}
                                      <Progress value={getProgress} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </CardBody>
                            <CardFooter>
                              <div className="d-flex justify-content-center">
                                {!showProgress ? (
                                  <div className="p-2 col-example">
                                    <Button
                                      block
                                      className="btn-round"
                                      size="sm"
                                      color="danger"
                                      onClick={updateUserPhoto}
                                    >
                                      Upload
                                    </Button>
                                  </div>
                                ) : null}
                                {!showProgress ? (
                                  <div className="p-2 col-example">
                                    <Button
                                      block
                                      className="btn-round"
                                      size="sm"
                                      color="danger"
                                      onClick={cancelUpdate}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                ) : null}
                                {photoUploadStatus ? (
                                  <div className="p-2 col-example">
                                    <p className="title">{photoUploadStatus}</p>
                                    <Button
                                      block
                                      className="btn-round"
                                      size="sm"
                                      color="danger"
                                      onClick={cancelUpdate}
                                    >
                                      Okey
                                    </Button>
                                  </div>
                                ) : null}
                              </div>

                              {getError && (
                                <p style={{ color: "#c52e2e" }}>
                                  {getError.message}
                                </p>
                              )}
                              {/* {getChangePasswordDetails.error && <p  style={{ color: '#c52e2e' }}>{getChangePasswordDetails.error.message}</p>} */}
                            </CardFooter>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                  ) : null}
                  {!editEnabled && !editPhoto ? (
                    <Row>
                      <Col className="ml-auto mr-auto" md="8">
                        {/* <Button className="btn-round" color="default" outline  style={{ position:"absolute", float:"right",right:'0px'}} onClick={event => editDetailsClick()}>
                <i className="fa fa-pencil"  /> Edit
              </Button> */}
                        {!editEnabled && !editPhoto ? (
                          <Badge
                            color="secondary"
                            style={{
                              position: "absolute",
                              float: "right",
                              right: "10px",
                            }}
                            onClick={(event) => editDetailsClick()}
                          >
                            {" "}
                            <i className="fa fa-pencil" />
                            Edit Info
                          </Badge>
                        ) : null}
                      </Col>
                    </Row>
                  ) : null}
                </Container>

                {!editEnabled ? (
                  <Container>
                    <br />
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav role="tablist" tabs>
                          <NavItem>
                            <NavLink
                              className={activeTab === "1" ? "active" : ""}
                              onClick={() => {
                                toggle("1");
                              }}
                            >
                              Personal Details
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                    {/* Tab panes */}
                    <TabContent className="following" activeTab={activeTab}>
                      <TabPane tabId="1" id="follows">
                        <Row>
                          <Col className="ml-auto mr-auto" md="8">
                            <ul className="list-unstyled follows">
                              <li>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Full Name</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>
                                        {userData.firstName} {userData.lastName}
                                      </small>
                                    </p>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Date of Birth</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.dob}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Gender</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.gender}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Height</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.height}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Weight</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.weight}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Marital Status</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.maritalStatus}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Body Type</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.bodyType}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Diet</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.diet}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Native Place</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.nativePlace}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Current Place</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.currentPlace}</small>
                                    </p>
                                  </Col>
                                </Row>
                              </li>

                              {/* <hr /> */}
                            </ul>
                          </Col>
                        </Row>
                      </TabPane>
                      {/* <TabPane className="text-center" tabId="2" id="following">
                      <h3 className="text-muted">Not following anyone yet :(</h3>
                      <Button className="btn-round" color="warning">
                        Find artists
              </Button>
                    </TabPane> */}
                    </TabContent>
                    <br />
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav role="tablist" tabs>
                          <NavItem>
                            <NavLink
                              className={activeTab === "1" ? "active" : ""}
                              onClick={() => {
                                toggle("1");
                              }}
                            >
                              Professional Details
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                    {/* Tab panes */}
                    <TabContent className="following" activeTab={activeTab}>
                      <TabPane
                        tabId="1"
                        id="follows"
                        style={{ minHeight: "100px" }}
                      >
                        <Row>
                          <Col className="ml-auto mr-auto" md="8">
                            <ul className="list-unstyled follows">
                              <li>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Profession</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.profession}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Annual Income</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.annualIncome}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Employed In</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.employedIn}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Education</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.education}</small>
                                    </p>
                                  </Col>
                                </Row>
                              </li>

                              {/* <hr /> */}
                            </ul>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                    {/* <br />
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav role="tablist" tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Horoscope Details
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <TabContent className="following" activeTab={activeTab}>
            <TabPane tabId="1" id="follows" style={{ minHeight: '100px' }}>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <ul className="list-unstyled follows">
                    <li>
                      <Row>
                        <Col className="ml-auto mr-auto" lg="4" md="4" xs="4">
                          <p>Time of Birth</p>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="1" md="1" xs="1">
                          <p>:</p>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="7" md="7" xs="7">
                          <p> <small>Musical Producer</small></p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="ml-auto mr-auto" lg="4" md="4" xs="4">
                          <p>Place of Birth</p>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="1" md="1" xs="1">
                          <p>:</p>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="7" md="7" xs="7">
                          <p> <small>Musical Producer</small></p>
                        </Col>
                      </Row>

                    </li>
                  </ul>
                </Col>
              </Row>
            </TabPane>


          </TabContent> */}
                    <br />
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav role="tablist" tabs>
                          <NavItem>
                            <NavLink
                              className={activeTab === "1" ? "active" : ""}
                              onClick={() => {
                                toggle("1");
                              }}
                            >
                              Family Details
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                    {/* Tab panes */}
                    <TabContent className="following" activeTab={activeTab}>
                      <TabPane
                        tabId="1"
                        id="follows"
                        style={{ minHeight: "100px" }}
                      >
                        <Row>
                          <Col className="ml-auto mr-auto" md="8">
                            <ul className="list-unstyled follows">
                              <li>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Father Status</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.fatherStatus}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Mother Status</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.motherStatus}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Brothers</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.brothers}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Sisters</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.sisters}</small>
                                    </p>
                                  </Col>
                                </Row>
                              </li>

                              {/* <hr /> */}
                            </ul>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                    <br />
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav role="tablist" tabs>
                          <NavItem>
                            <NavLink
                              className={activeTab === "1" ? "active" : ""}
                              onClick={() => {
                                toggle("1");
                              }}
                            >
                              Contact Details
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                    {/* Tab panes */}
                    <TabContent className="following" activeTab={activeTab}>
                      <TabPane
                        tabId="1"
                        id="follows"
                        style={{ minHeight: "100px" }}
                      >
                        <Row>
                          <Col className="ml-auto mr-auto" md="8">
                            <ul className="list-unstyled follows">
                              <li>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Email Address</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.email}</small>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="4"
                                    md="4"
                                    xs="4"
                                  >
                                    <p>Mobile Number</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="1"
                                    md="1"
                                    xs="1"
                                  >
                                    <p>:</p>
                                  </Col>
                                  <Col
                                    className="ml-auto mr-auto"
                                    lg="7"
                                    md="7"
                                    xs="7"
                                  >
                                    <p>
                                      {" "}
                                      <small>{userData.mobileNumber}</small>
                                    </p>
                                  </Col>
                                </Row>
                              </li>

                              {/* <hr /> */}
                            </ul>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </Container>
                ) : null}

                {editEnabled ? (
                  <Container>
                    <Row>
                      <Col className="ml-auto mr-auto r" md="8">
                        <br />
                        <div className="nav-tabs-navigation">
                          <div className="nav-tabs-wrapper">
                            <Nav role="tablist" tabs>
                              <NavItem>
                                <NavLink
                                  className={activeTab === "1" ? "active" : ""}
                                  onClick={() => {
                                    toggle("1");
                                  }}
                                >
                                  Edit Details
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </div>
                        <Card>
                          {/* <CardHeader>
                  <p className='title'>Edit Details</p>
                </CardHeader> */}
                          <CardBody>
                            <div className="d-flex justify-content-around">
                              <div
                                className="p-2 col-example"
                                style={{ width: "90%" }}
                              >
                                <label> First Name</label>
                                <Input
                                  placeholder="First Name"
                                  type="text"
                                  name="firstName"
                                  value={userData.firstName || ""}
                                  onChange={onHandleChange}
                                />
                                <br />
                                <label> Last Name</label>
                                <Input
                                  placeholder="Last Name"
                                  type="text"
                                  name="lastName"
                                  value={userData.lastName || ""}
                                  onChange={onHandleChange}
                                />
                                <br />
                                <label> Height</label>
                                <Input
                                  placeholder="Eg. 5.8 feet etc"
                                  type="text"
                                  name="height"
                                  value={userData.height || ""}
                                  onChange={onHandleChange}
                                />
                                <br />
                                <label> Weight</label>
                                <Input
                                  placeholder="Eg. In Killograms etc"
                                  type="number"
                                  name="weight"
                                  value={userData.weight || ""}
                                  onChange={onHandleChange}
                                />
                                <br />
                                {/* <label > Marital Status</label>
                  <Input placeholder="Eg. Never Married, Divorced, Widowed etc" type="text" name="maritalStatus" value={userData.maritalStatus || ''} onChange={onHandleChange} /><br />
                   */}
                                <form className="form-horizontal">
                                  <label> Marital Status</label>
                                  <select
                                    className="form-control"
                                    name="maritalStatus"
                                    value={userData.maritalStatus || "NA"}
                                    onChange={onHandleChange}
                                  >
                                    <option value="NA">
                                      Select Marital Status
                                    </option>
                                    <option value="Never Married">
                                      Never Married
                                    </option>
                                    <option value="Divorced">Divorced</option>
                                    <option value="Awaiting Divorce">
                                      Awaiting Divorce
                                    </option>
                                    <option value="Widowed">Widowed</option>
                                  </select>
                                </form>
                                <br />
                                {/* <label > Body Type</label>
                              <Input placeholder="Eg. Slim, Average, Athletic, Heavy etc" type="text" name="bodyType" value={userData.bodyType || ''} onChange={onHandleChange} /><br />
                               */}
                                <form className="form-horizontal">
                                  <label> Body Type</label>
                                  <select
                                    className="form-control"
                                    name="bodyType"
                                    value={userData.bodyType || "NA"}
                                    onChange={onHandleChange}
                                  >
                                    <option value="NA">Select Body Type</option>
                                    <option value="Slim">Slim</option>
                                    <option value="Average">Average</option>
                                    <option value="Athletic">Athletic</option>
                                    <option value="Heavy">Heavy</option>
                                  </select>
                                </form>
                                <br />
                                {/* <label > Diet</label>
                              <Input placeholder="Eg. Veg, Non-veg etc" type="text" name="diet" value={userData.diet || ''} onChange={onHandleChange} /><br />
                              */}
                                <form className="form-horizontal">
                                  <label> Diet</label>
                                  <select
                                    className="form-control"
                                    name="diet"
                                    value={userData.diet || "NA"}
                                    onChange={onHandleChange}
                                  >
                                    <option value="NA">Select Diet</option>
                                    <option value="Vegetarian">
                                      Vegetarian
                                    </option>
                                    <option value="Non Vegetarian">
                                      Non Vegetarian
                                    </option>
                                  </select>
                                </form>
                                <br />
                                <label> Native Place</label>
                                <Input
                                  placeholder="Native Place"
                                  type="text"
                                  name="nativePlace"
                                  value={userData.nativePlace || ""}
                                  onChange={onHandleChange}
                                />
                                <br />
                                <label> Current Place</label>
                                <Input
                                  placeholder="Current place"
                                  type="text"
                                  name="currentPlace"
                                  value={userData.currentPlace || ""}
                                  onChange={onHandleChange}
                                />
                                <br />
                                <label> Profession</label>
                                <Input
                                  placeholder="Eg. Engineer, Farmer, Doctor etc"
                                  type="text"
                                  name="profession"
                                  value={userData.profession || ""}
                                  onChange={onHandleChange}
                                />
                                <br />
                                {/* <label > Annual Income</label>
                              <Input placeholder="Eg. 2,30,000  etc" type="number" name="annualIncome" value={userData.annualIncome || ''} onChange={onHandleChange} /><br />
                               */}
                                <form className="form-horizontal">
                                  <label> Annual Income</label>
                                  <select
                                    className="form-control"
                                    name="annualIncome"
                                    value={userData.annualIncome || "NA"}
                                    onChange={onHandleChange}
                                  >
                                    <option value="NA">
                                      Select Annual Income
                                    </option>
                                    <option value="0 to 2 Lakh">
                                      0 to 2 Lakh
                                    </option>
                                    <option value="2 Lakh  to 5 Lakh">
                                      2 Lakh to 5 Lakh
                                    </option>
                                    <option value="5 Lakh to 7 Lakh">
                                      5 Lakh to 7 Lakh
                                    </option>
                                    <option value="7 lakh to 10 Lakh">
                                      7 lakh to 10 Lakh
                                    </option>
                                    <option value="10 Lakh to 12 Lakh">
                                      10 Lakh to 12 Lakh
                                    </option>
                                    <option value="12 Lakh to 15 Lakh">
                                      12 Lakh to 15 Lakh
                                    </option>
                                    <option value="15 lakh and Above">
                                      15 lakh and Above
                                    </option>
                                  </select>
                                </form>
                                <br />
                                {/* <label > Employed In</label>
                              <Input placeholder="Eg. Private Sector, Govt. Sector, Non Working etc" type="text" name="employedIn" value={userData.employedIn || ''} onChange={onHandleChange} /><br />
                              */}
                                <form className="form-horizontal">
                                  <label> Employed In</label>
                                  <select
                                    className="form-control"
                                    name="employedIn"
                                    value={userData.employedIn || "NA"}
                                    onChange={onHandleChange}
                                  >
                                    <option value="NA">
                                      Select Employed In
                                    </option>
                                    <option value="Government">
                                      Government
                                    </option>
                                    <option value="Private Sectors">
                                      Private Sectors
                                    </option>
                                    <option value="Business">Business </option>
                                    <option value="Defence">Defence </option>
                                    <option value="Self Employed">
                                      Self Employed{" "}
                                    </option>
                                    <option value="Not Working">
                                      Not Working{" "}
                                    </option>
                                  </select>
                                </form>
                                <br />
                                {/* <label > Education</label>
                              <Input placeholder="Eg. 12th, BTech, Bsc etc" type="text" name="education" value={userData.education || ''} onChange={onHandleChange} /><br />
                               */}
                                <form className="form-horizontal">
                                  <label> Education</label>
                                  <select
                                    className="form-control"
                                    name="education"
                                    value={userData.education || "NA"}
                                    onChange={onHandleChange}
                                  >
                                    <option value="NA">Select Education</option>
                                    <option value="Highschool">
                                      Highschool{" "}
                                    </option>
                                    <option value="Masters">Masters</option>
                                    <option value="Graduation">
                                      Graduation{" "}
                                    </option>
                                    <option value="Associate Degree">
                                      Associate Degree{" "}
                                    </option>
                                    <option value="P.H.D  ">P.H.D </option>
                                    <option value="Post Graduation">
                                      Post Graduation
                                    </option>
                                    <option value="MD">MD</option>
                                  </select>
                                </form>
                                <br />
                                {/* <label > Father Status</label>
                              <Input placeholder="Eg. Working, Non Working, Passed Away etc" type="text" name="fatherStatus" value={userData.fatherStatus || ''} onChange={onHandleChange} /><br />
                              */}
                                <form className="form-horizontal">
                                  <label> Father Status</label>
                                  <select
                                    className="form-control"
                                    name="fatherStatus"
                                    value={userData.fatherStatus || "NA"}
                                    onChange={onHandleChange}
                                  >
                                    <option value="NA">
                                      Select Father Status
                                    </option>
                                    <option value="Working">Working</option>
                                    <option value="Non Working">
                                      Non Working
                                    </option>
                                    <option value="Passed Away">
                                      Passed Away
                                    </option>
                                  </select>
                                </form>
                                <br />
                                {/* <label > Mother Status</label>
                              <Input placeholder="Eg. Working, Non Working, Passed Away etc" type="text" name="motherStatus" value={userData.motherStatus || ''} onChange={onHandleChange} /><br />
                               */}
                                <form className="form-horizontal">
                                  <label> Mother Status</label>
                                  <select
                                    className="form-control"
                                    name="motherStatus"
                                    value={userData.motherStatus || "NA"}
                                    onChange={onHandleChange}
                                  >
                                    <option value="NA">
                                      Select Mother Status
                                    </option>
                                    <option value="Working">Working</option>
                                    <option value="Non Working">
                                      Non Working
                                    </option>
                                    <option value="Passed Away">
                                      Passed Away
                                    </option>
                                  </select>
                                </form>
                                <br />
                                <label> Brothers</label>
                                <Input
                                  placeholder="Eg. Number of Brothers"
                                  type="number"
                                  name="brothers"
                                  value={userData.brothers || ""}
                                  onChange={onHandleChange}
                                />
                                <br />
                                <label> Sisters</label>
                                <Input
                                  placeholder="Eg. Number of Sisters"
                                  type="number"
                                  name="sisters"
                                  value={userData.sisters || ""}
                                  onChange={onHandleChange}
                                />
                                <br />
                                <label> Mobile Number</label>
                                <Input
                                  placeholder="Mobile Number"
                                  type="number"
                                  name="mobileNumber"
                                  value={userData.mobileNumber || ""}
                                  onChange={onHandleChange}
                                />{" "}
                                <br />
                              </div>
                            </div>
                          </CardBody>
                          <CardFooter>
                            <div className="d-flex justify-content-center">
                              <div className="p-2 col-example">
                                <Button
                                  block
                                  className="btn-round"
                                  size="sm"
                                  color="danger"
                                  onClick={updateUserInfo}
                                >
                                  Update Details
                                </Button>
                              </div>
                              <div className="p-2 col-example">
                                <Button
                                  block
                                  className="btn-round"
                                  size="sm"
                                  color="danger"
                                  onClick={cancelUpdate}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>

                            {getError && (
                              <p style={{ color: "#c52e2e" }}>
                                {getError.message}
                              </p>
                            )}
                            {/* {getChangePasswordDetails.error && <p  style={{ color: '#c52e2e' }}>{getChangePasswordDetails.error.message}</p>} */}
                          </CardFooter>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                ) : null}
              </div>

              <DemoFooter />
            </div>
          )}{" "}
        </div>
      )}
      {showConfirmAlert ? (
        <AlertModal data={alertParams} callBack={successCallBackData} />
      ) : null}
    </>
  );
}
// const NewComponent = NetworkDetector(ProfilePage);
// export default  NetworkDetector(ProfilePage);
export default ProfilePage;
