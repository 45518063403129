import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import ImageViewerModal from "components/Modals/imageVieweModal";
const CommunityMembers = (props) => {
  const [imageViewParams, setImageViewParams] = useState("");
  const [imageViewerModal, setImageViewerModal] = useState(false);
  const memberParams = props?.data;

  const itemsPerRow = props?.itemsPerRow ? props?.itemsPerRow : 3;

  // Create rows dynamically
  const rows = [];
  for (let i = 0; i < memberParams.length; i += itemsPerRow) {
    const rowItems = memberParams.slice(i, i + itemsPerRow);
    rows.push(rowItems);
  }
  let md = 4;
  if (memberParams.length === 1) {
    md = 12;
  } else if (memberParams.length === 2) {
    md = 6;
  } else {
    md = props?.itemsPerRow ? 6 : 4;
  }
  const imageStyle = "img-circle img-no-padding img-responsive";

  const showProfilePic = (userData) => {
    if (userData.image_URL) {
      setImageViewParams(userData);
      setImageViewerModal(false);
      setTimeout(() => {
        setImageViewerModal(true);
      }, 100);
    }
  };
  return (
    <Container>
      {imageViewerModal ? <ImageViewerModal data={imageViewParams} /> : null}
      {rows.map((row, rowIndex) => (
        <Row
          className="description text-center"
          key={rowIndex}
          style={{ padding: "30px" }}
        >
          {row.map((member, index) => (
            <Col md={md} key={index}>
              <div>
                {member?.image_URL ? (
                  <div
                    className="avatar"
                    onClick={() => showProfilePic(member)}
                  >
                    <img
                      alt={member?.title}
                      className={
                        // eslint-disable-next-line react/prop-types
                        props?.imageStyle ? props.imageStyle : imageStyle
                      }
                      src={member?.image_URL}
                      style={
                        props?.style
                          ? props?.style
                          : { width: "150px", height: "150px" }
                      }
                    />
                  </div>
                ) : null}
                <div className="name">
                  {member.link ? (
                    <h4 className="title">
                      <a href={member.link}>{member.title}</a> <br />
                    </h4>
                  ) : (
                    <h4
                      className="title"
                      onClick={() => showProfilePic(member)}
                    >
                      {member.title}
                    </h4>
                  )}

                  <h6 className="description">{member?.short_Description}</h6>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  );
};

export default CommunityMembers;
