/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect } from "react";

// reactstrap components

import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  Form,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import ImgsViewer from "react-images-viewer";
import { AdMob, AdOptions } from "@capacitor-community/admob";
import { GLOBLE_CONSTANT } from "config/constant";
import { Capacitor } from "@capacitor/core";
import ImageViewerModal from "components/Modals/imageVieweModal";
function NirmalGramPurskarPage(match) {
  const [activeTab, setActiveTab] = React.useState("1");
  const [getGender, setGender] = React.useState({
    selectedOption: "",
  });
  const [isRegistration, setRegistration] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [defaultContainer, setDefaultContainer] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [authType, setAuthType] = useState("LOGIN");
  const [imgSRC, setImgSRC] = useState("");
  const [showProflePicture, setShowProflePicture] = useState(false);
  const [imageViewParams, setImageViewParams] = useState("");
  const [imageViewerModal, setImageViewerModal] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const cntImage = 0;
  const imgWidth = 220;
  const open = true;
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (Capacitor.isNativePlatform()) {
      // adMobIn();
    }
  }, []);
  const adMobIn = async () => {
    try {
      await AdMob.initialize();
    } catch (error) {
      // console.log("ADMOB INITIALIZE ERROR");
    }

    const options: AdOptions = {
      adId: GLOBLE_CONSTANT.rewardVideoAd_ID,
    };
    await AdMob.prepareRewardVideoAd(options);
    // const options_: AdOptions = {
    //   adId: GLOBLE_CONSTANT.interstitial_Add_ID,
    // };
    // // Prepare interstitial banner
    // await AdMob.prepareInterstitial(options_);

    setTimeout(() => {
      showRrewardVideo();
    }, 4000);
  };
  const showRrewardVideo = async () => {
    await AdMob.showRewardVideoAd();
  };
  const Textcolor = {
    color: "#fff",
  };
  const radioButtonMargin = {
    margin: "0 10px 0 10px",
  };
  const handleOptionChange = (changeEvent) => {
    // console.log(changeEvent.target.value);
    setGender({
      selectedOption: changeEvent.target.value,
    });
  };
  function closeViewer() {
    setShowProflePicture(false);
  }
  function showPhoto(data) {
    let imageData = {};
    if (data == "Certificate") {
      imageData.image_URL = require("assets/image/Nirmal-Gram-Purskar-Certificate.jpeg");
      imageData.title = "प्रमाणपत्र";
    } else if (data == "IdentyCard") {
      imageData.image_URL = require("assets/image/Nirmal-Gram-Purskar-Identy-card.jpeg");
      imageData.title = "निमंत्रण पत्र";
    } else if (data == "AppriciationLetter") {
      imageData.image_URL = require("assets/image/Nirmal-Gram-Purskar-Pratik-Patil-Appriciation-letter.jpeg");
      imageData.title = "अभिनंदन पत्र";
    }
    if (imageData.image_URL) {
      setImageViewParams(imageData);
      setImageViewerModal(false);
      setTimeout(() => {
        setImageViewerModal(true);
      }, 100);
    }
  }

  return (
    <>
      {imageViewerModal ? <ImageViewerModal data={imageViewParams} /> : null}
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="name">
              <h4 className="title">
                Nirmal-Gram-Purskar-2007 <br />
              </h4>
              <h5 className="description text-center">
                <marquee
                  direction="left"
                  width="400"
                  style={{ fontWeight: "800", color: "green" }}
                >
                  संकेतस्थळावर जाहिरात करण्यासाठी संपर्क करा :
                  hatkarmatrimony@gmail.com
                </marquee>
              </h5>
              <hr />
            </div>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h4 className="title text-center" style={{ fontWeight: "800" }}>
                मा.श्री.गजानन गणपती भुसनुर{" "}
              </h4>
            </Col>
          </Row>
          <Row className="description  ">
            <Col md="6" className=" text-center ">
              <div>
                <img
                  alt=" राष्ट्रपती डॉ. ए.पि.जे. अब्दुल. कलाम यांच्या हस्ते निर्मल ग्राम पुरस्काराने सन्मानित माजी सरपंच लकडेवाडी"
                  className=" img-no-padding img-responsive"
                  src={require("assets/image/Nirmal-Gram-Purskar-Certificate-Gajanan-Bhusanur.jpeg")}
                  style={{ width: "70%", height: "60%" }}
                />
                <div className="name">
                  <h4 className="title">
                    मा.श्री.गजानन गणपती भुसनुर
                    <br />
                  </h4>
                  <h6 className="description">
                    राष्ट्रपती डॉ. ए.पि.जे. अब्दुल. कलाम यांच्या हस्ते निर्मल
                    ग्राम पुरस्काराने सन्मानित माजी सरपंच लकडेवाडी, ता-जत
                  </h6>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div>
                <h4 className="title">
                  <b style={{ fontWeight: "600" }}>
                    {" "}
                    निर्मल ग्राम पुरस्कार-2007
                  </b>
                  <br />
                </h4>
                <h5 className="description">
                  <b style={{ fontWeight: "600" }}>जत तालुक्यातील लकडेवाडी</b>{" "}
                  येथे ग्रामीण स्वच्छता कार्यक्रम ही केंद्र पुरस्कृत योजना
                  २००२-०३ ही योजना स्वतःच्या गावाला स्वतःचे कुटुंब समजून संपूर्ण
                  गावाला <b style={{ fontWeight: "600" }}>निर्मलग्राम</b>{" "}
                  करण्याचे वज्रनिर्धार करून ही योजना प्रभावीपणाने राबवून या
                  योजनेअंतर्गत गावातील प्रत्येक कुटुंबाला वैयक्तिक शौचालये
                  (दारिद्रय रेषेखालील लाभार्थी), शालेय शौचालय, अंगणवाडी शौचालय
                  तसेच सामुहिक शौचालय बांधकाम यासाठी शासन आणि बँकेकडून अनुदान
                  मिळवून देऊन कायमस्वरूपी दुष्काळी समजल्या जाणाऱ्या लकडेवाडी
                  सारख्या गावाला जिल्हा आणि तालुका प्रशासन आणि स्थानिक
                  ग्रामस्थांच्या सहकार्याने जत तालुक्यात सर्वप्रथम{" "}
                  <b style={{ fontWeight: "600" }}>
                    निर्मल ग्राम पुरस्कार मिळवून देण्यात आणि तीनवेळा सरपंच पदावर
                    राहून गावासाठी आणि सर्व समाजाच्या सर्वांगीण विकासासाठी
                    प्रयत्न करणारे, तरुण तडफदार आणि शांत संयमी अभ्यासू असे माजी
                    सरपंच मा. गजानन गणपती भुसनूर यांचा मोलाचा वाटा आहे.
                  </b>{" "}
                  <br /> याशिवाय संत गाडगेबाबा ग्रामस्वच्छता अभियानात सहभाग घेत
                  तालुक्यात उल्लेखनीय कामगिरी करत सांगली जिल्हा परिषदेकडून
                  पारितोषिक मिळवले आहे.
                  <br />
                  या अतुलनीय कामाबद्दल मा. गजानन भुसनूर यांचे तत्कालीन दिवंगत
                  महामहिम{" "}
                  <b style={{ fontWeight: "600" }}>
                    राष्ट्रपती डॉ ए पी जे अब्दुल कलाम
                  </b>{" "}
                  यांच्याहस्ते नवी दिल्ली येथे २००७ मध्ये विशेष पुरस्कार देऊन
                  गौरविण्यात आले आहे.
                </h5>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h4 className="title text-center" style={{ fontWeight: "800" }}>
                पुरस्काराच्या प्रति{" "}
              </h4>
            </Col>
          </Row>
          <Row className="description text-center ">
            <Col md="4">
              <div>
                <img
                  alt="Gajanan Bhusanoor"
                  className="img-no-padding img-responsive"
                  src={require("assets/image/Nirmal-Gram-Purskar-Certificate.jpeg")}
                  style={{ width: "150px", height: "150px" }}
                  onClick={(event) => showPhoto("Certificate")}
                />
                <div className="name">
                  <h4 className="title">
                    निर्मल ग्राम प्रमाणपत्र
                    <br />
                  </h4>
                  {/* <h6 className="description">माजी सरपंच लकडेवाडी</h6> */}
                </div>
              </div>
            </Col>
            <Col md="4">
              <div>
                <img
                  alt="Gajanan Bhusanoor"
                  className="img-no-padding img-responsive"
                  src={require("assets/image/Nirmal-Gram-Purskar-Identy-card.jpeg")}
                  style={{ width: "150px", height: "150px" }}
                  onClick={(event) => showPhoto("IdentyCard")}
                />
                <div className="name">
                  <h4 className="title">
                    निमंत्रण पत्र
                    <br />
                  </h4>
                  {/* <h6 className="description">माजी सरपंच लकडेवाडी</h6> */}
                </div>
              </div>
            </Col>
            <Col md="4">
              <div>
                <img
                  alt="Gajanan Bhusanoor"
                  className="img-no-padding img-responsive"
                  src={require("assets/image/Nirmal-Gram-Purskar-Pratik-Patil-Appriciation-letter.jpeg")}
                  style={{ width: "150px", height: "150px" }}
                  onClick={(event) => showPhoto("AppriciationLetter")}
                />
                <div className="name">
                  <h4 className="title">
                    मा. खासदार प्रतीक पाटील यांच्या हस्ते अभिनंदन पत्र
                    <br />
                  </h4>
                  {/* <h6 className="description">माजी सरपंच लकडेवाडी</h6> */}
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h5 className="title text-center">
                To advertise on the website, kindly contact us via email at{" "}
                <a
                  href="mailto:hatkarmatrimony@gmail.com"
                  style={{ color: "#76daff", textTransform: "none" }}
                >
                  hatkarmatrimony@gmail.com
                </a>
              </h5>
            </Col>
          </Row>
          <br />
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <Button className="btn-round" color="info" href="/">
                Back to Home Page
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default NirmalGramPurskarPage;
