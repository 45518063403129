/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { AdMob, AdOptions } from "@capacitor-community/admob";

// reactstrap components

import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  Form,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import { Capacitor } from "@capacitor/core";
import { GLOBLE_CONSTANT } from "config/constant";

function PrivacyPolicy(match) {
  const [activeTab, setActiveTab] = React.useState("1");
  const [getGender, setGender] = React.useState({
    selectedOption: "",
  });
  const [isRegistration, setRegistration] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [defaultContainer, setDefaultContainer] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [authType, setAuthType] = useState("LOGIN");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (Capacitor.isNativePlatform()) {
      //   adMobIn();
    }
  }, []);
  const adMobIn = async () => {
    try {
      await AdMob.initialize();
    } catch (error) {
      // console.log("ADMOB INITIALIZE ERROR");
    }

    const options: AdOptions = {
      adId: GLOBLE_CONSTANT.rewardVideoAd_ID,
    };
    await AdMob.prepareRewardVideoAd(options);
    const options_: AdOptions = {
      adId: GLOBLE_CONSTANT.interstitial_Add_ID,
    };
    // Prepare interstitial banner
    await AdMob.prepareInterstitial(options_);
    setTimeout(() => {
      if (getRandomInt(2) == "0") {
        showRrewardVideo();
      } else {
        showInterstitial();
      }
    }, 4000);
  };
  const showInterstitial = async () => {
    await AdMob.showInterstitial();
  };
  const showRrewardVideo = async () => {
    await AdMob.showRewardVideoAd();
  };
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  const Textcolor = {
    color: "#fff",
  };
  const radioButtonMargin = {
    margin: "0 10px 0 10px",
  };
  const handleOptionChange = (changeEvent) => {
    // console.log(changeEvent.target.value);
    setGender({
      selectedOption: changeEvent.target.value,
    });
  };

  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="name">
              <h4 className="title">
                Privacy Policy <br />
              </h4>
            </div>
          </div>

          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <p className="description">
                HatkarMatrimony.com is an online matrimonial portal endeavouring
                constantly to provide you with matrimonial services. This
                privacy statement is common to all the matrimonial Website/apps
                operated under HatkarMatrimony.com Since we are strongly
                committed to your right to privacy, we have drawn out a privacy
                statement with regard to the information we collect from you.
                You acknowledge that you are disclosing information voluntarily.
                By accessing /using the website/apps and/or by providing your
                information, you consent to the collection and use of the info
                you disclose on the website/apps in accordance with this Privacy
                Policy. If you do not agree for use of your information, please
                do not use or access this website/apps.
              </p>

              <h4 className="title">What Information Do We Collect?</h4>
              <p className="description">
                The information we gather from members and visitors who apply
                for the various services our website/apps offers includes, but
                may not be limited to, email address, name, date of birth,
                educational qualifications a user-specified password, mailing
                address, zip/pin code and telephone/mobile number or fax number.
              </p>
              <h4 className="title">Email Information</h4>
              <p className="description">
                In addition to providing the foregoing information to our
                partners, if you choose to correspond further with us through
                email, we may retain the content of your email messages together
                with your email address and our responses. We provide the same
                protections for these electronic communications that we employ
                in the maintenance of information received by mail and
                telephone.
              </p>

              <h4 className="title">Web Site Use Information</h4>
              <p className="description">
                Similar to other commercial Web sites, our Web site utilizes a
                standard technology called "cookies" (see explanation below,
                "What Are Cookies?") and Web server logs to collect information
                about how our Web site is used. Information gathered through
                cookies and Web server logs may include the date and time of
                visits, the pages viewed, time spent at our Web site, and the
                Web sites visited just before and just after our Web site. This
                information is collected on an aggregate basis. None of this
                information is associated with you as an individual.
              </p>
              <h4 className="title">
                How the website/apps uses the information it collects/tracks?
              </h4>
              <p className="description">
                Hatkar Matrimony.com collects information for data analysis,
                identifying usage trends,determining the effectiveness of our
                promotional campaigns and to evaluate and improve our websites
                or apps, products, and services ,marketing research from our
                users primarily to ensure that we are able to fulfil your
                requirements and to deliver Personalised experience
              </p>
              <h4 className="title">
                How Do We Use the Information That You Provide to Us?
              </h4>
              <p className="description">
                Broadly speaking, we use personal information for purposes of
                administering our business activities, providing customer
                service and making available other products and services to our
                customers and prospective customers.
                <br />
                Occasionally, we may also use the information we collect to
                notify you about important changes to our Web site, new services
                and special offers we think you will find valuable. The lists
                used to send you product and service offers are developed and
                managed under our traditional corporate standards designed to
                safeguard the security and privacy of our customers’ personal
                information.
              </p>
              <h4 className="title">Cookies & Credit card information </h4>
              <p className="description">
                We use a secure server for credit card transactions to protect
                the credit card information of our users and Cookies are used to
                store the login information. Cookies are small files placed on
                your hard drive that will assist us in providing our services.
                You may also encounter Cookies or identical/related devices on
                certain pages of the website/apps that are placed by third
                parties. We do not control the use of cookies by third parties.
                <br /> If you establish a credit account with us to pay the fees
                we charge, some additional information, including a billing
                address, a credit/debit card number and a credit/debit card
                expiration date and tracking information from cheques or demand
                drafts is collected.
              </p>
              <h4 className="title">
                Do We Disclose Information to Outside Parties?
              </h4>
              <p className="description">
                We may provide aggregate information about our customers, sales,
                Web site traffic patterns and related Web site information to
                our affiliates or reputable third parties, but this information
                will not include personally identifying data, except as
                otherwise provided in this Privacy Policy.
              </p>
              <h4 className="title">
                What About Legally Compelled Disclosure of Information?
              </h4>
              <p className="description">
                We may disclose information when legally compelled to do so, in
                other words, when we, in good faith, believe that the law
                requires it or for the protection of our legal rights.
              </p>
              <h4 className="title">Change of Privacy Policy</h4>
              <p className="description">
                We may change this Privacy Policy without notice from time to
                time without any notice to you. However, changes will be updated
                in the Privacy Policy page.
              </p>
              <h4 className="title">User Data Deletion</h4>
              <p className="description">
                We respect your right to control your personal information.
                Hatkarmatrimony provides a user-friendly account deletion
                feature that allows you to delete your account along with
                associated data directly within the app.
              </p>
              <p className="description">
                If you encounter any difficulties or have questions regarding
                the account deletion process, please contact our support team at{" "}
                <a
                  href="mailto:hatkarmatrimony@gmail.com"
                  style={{ color: "#76daff", textTransform: "none" }}
                >
                  hatkarmatrimony@gmail.com
                </a>
              </p>
              {/* <h4 className="title">How to address your Grievance</h4>
                        <p className="description">The Grievance officer : Mr. Akilan Paneerselvam. Address; Matrimony.com Limited, Contact
                            details: 94, TVH Belciaa Towers,Tower-2, 10th Floor, MRC Nagar, Chennai 600 028 <br/><a
                                href="mailto:grievanceofficer@matrimony.com"
                                style={{textDecoration:'none'}}>grievanceofficer@matrimony.com</a></p>
                        <p className="description">The Grievance officer shall be available between 10 am to 6 pm IST from Monday to Saturday
                            excluding Sunday's and Public Holidays in India.</p>
                        <p className="description">The Grievance officer is appointed as per Section 5 (9) of the Information Technology (
                            Reasonable Security & Procedures and Sensitive Personal data or Information ) Rule, 2011.
                        </p> */}
            </Col>
          </Row>
          {/* <hr /> */}
          {/* <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h5 className="title text-center">
                To advertise on the website. Please write to us on an email
                address{" "}
                <a
                  href="mailto:hatkarmatrimony@gmail.com"
                  style={{ color: "#76daff", textTransform: "none" }}
                >
                  hatkarmatrimony@gmail.com
                </a>
              </h5>
            </Col>
          </Row>
          <br /> */}
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <Button className="btn-round" color="info" href="/">
                Back to Home Page
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default PrivacyPolicy;
