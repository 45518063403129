/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// import Firebase from 'firebase';
import LoadingOverlay from "react-loading-overlay";

// reactstrap components
/* eslint-disable */
import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from "reactstrap";
// import { Link } from '@material-ui/core';

// core components

const MyLoader = (props) => {
  let pageHeader = React.createRef();

  const [modal, setModal] = useState(true);
  const [modalParams, setModalParams] = useState(props.data);

  const toggle = () => setModal(!modal);
  //   console.log(props);
  // {title, body, btnOk } = ;
  React.useEffect(() => {
    // if (window.innerWidth < 991) {
    //   const updateScroll = () => {
    //     let windowScrollTop = window.pageYOffset / 3;
    //     pageHeader.current.style.transform =
    //       "translate3d(0," + windowScrollTop + "px,0)";
    //   };
    //   window.addEventListener("scroll", updateScroll);
    //   return function cleanup() {
    //     window.removeEventListener("scroll", updateScroll);
    //   };
    // }
  });

  return (
    <Modal isOpen={modal}>
      <LoadingOverlay
        active={true}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "100px",
            "& svg circle": {
              stroke: "rgba(255, 0, 0, 0.5)",
            },
          }),
        }}
      >
        {/* {children} */}
        {/* <p>Some content or children or something.</p> */}
      </LoadingOverlay>
    </Modal>
  );
};

export default MyLoader;
