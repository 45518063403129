/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
// import Firebase from 'firebase';
import MyLoader from "./Spinner";

// reactstrap components

import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from "reactstrap";
import ImgsViewer from "react-images-viewer";
import { userInfoContext } from "context/userContext";
// import { Link } from '@material-ui/core';

// core components

const ProfileViewModal = (props) => {
  // console.log(props);
  const [modal, setModal] = useState(true);
  const [modalParams, setModalParams] = useState(props.data);
  let pageHeader = React.createRef();
  const [activeTab, setActiveTab] = useState("1");
  const [userData, setUserData] = useState({});
  const [photoURL, setPhotoURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeleton, setSkeleton] = useState(true);
  const [poorConnection, setPoorConnection] = useState(false);
  const [showProflePicture, setShowProflePicture] = useState(false);
  const [imgSRC, setImgSRC] = useState("");
  const [isDataLoaded, setDataLoaded] = useState(false);
  const { userInfo, setUserInfo } = useContext(userInfoContext);
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  // const toggle = () => setModal(!modal);
  // {title, body, btnOk } = ;

  function closeViewer() {
    setShowProflePicture(false);
  }
  function showProfilePic() {
    //   setImgSRC(userData.photoURL)
    //  setShowProflePicture(true)
  }
  function inArray(needle, haystack) {
    if (haystack !== undefined) {
      var length = haystack.length;
      for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) return true;
      }
      return false;
    } else {
      return false;
    }
  }

  const cntImage = 0;
  const imgWidth = 220;
  const open = true;

  const closeModal = (returnParam) => {
    setModal(false);
    props.callBack(returnParam);
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    // console.log("useEffect subscriber");
    setUserData(props.data);
  }, []);

  const style = {
    margin: "20px",
  };
  const backgroundColor = {
    backgroundColor: "#f5f5f5",
    justifyContent: "space-around",
  };

  const Textcolor = {
    color: "#f5593d",
  };
  return (
    <Modal isOpen={modal} size="lg">
      {/* <MyLoader/> */}
      <div>
        {/* <Button color="danger" onClick={toggle}>Close</Button> */}
        <ModalHeader style={{ backgroundColor: "#f5f5f5", textAlign: "right" }}>
          {" "}
          <Button
            className="btn-round"
            color="info"
            onClick={(event) => closeModal("OK")}
          >
            Close
          </Button>
        </ModalHeader>
        <ModalBody style={backgroundColor}>
          <div
            className="section profile-content"
            style={{ textTransform: "capitalize" }}
          >
            <Container>
              <div className="owner">
                <div className="avatar">
                  {!userData.photoURL && userData.gender == "male" ? (
                    <img
                      alt={userData.firstName}
                      className="img-circle img-no-padding img-responsive"
                      // src={userData.photoURL}
                      src={require("assets/image/default_User_Icon_Men.png")}
                      style={{ width: "150px", height: "150px" }}
                    />
                  ) : null}
                  {!userData.photoURL && userData.gender == "female" ? (
                    <img
                      alt={userData.firstName}
                      className="img-circle img-no-padding img-responsive"
                      // src={userData.photoURL}
                      style={{ width: "150px", height: "150px" }}
                      src={require("assets/image/default_User_Icon_Women.png")}
                    />
                  ) : null}
                  {userData.photoURL ? (
                    <img
                      alt={userData.firstName}
                      style={{ width: "150px", height: "150px" }}
                      className="img-circle img-no-padding img-responsive"
                      src={userData.photoURL}
                      onClick={showProfilePic}
                    />
                  ) : null}
                </div>
                <div className="name">
                  <h4 className="title">
                    {userData.firstName} {userData.lastName} <br />
                  </h4>
                  {/* <h6 className="description">Hello Producer</h6> */}
                  {/* <input type="file"  accept="image/*" onChange={event => setImage( event.target.files[0])} /> */}
                </div>
              </div>
            </Container>

            <Container>
              <br />
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <Nav role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Personal Details
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              {/* Tab panes */}
              <TabContent className="following" activeTab={activeTab}>
                <TabPane tabId="1" id="follows">
                  <Row>
                    <Col className="ml-auto mr-auto" md="8">
                      <ul className="list-unstyled follows">
                        <li>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Full Name</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>
                                  {userData.firstName} {userData.lastName}
                                </small>
                              </p>
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Date of Birth</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.dob}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Gender</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.gender}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Height</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.height}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Weight</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.weight}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Marital Status</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.maritalStatus}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Body Type</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.bodyType}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Diet</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.diet}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Native Place</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.nativePlace}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Current Place</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.currentPlace}</small>
                              </p>
                            </Col>
                          </Row>
                        </li>

                        {/* <hr /> */}
                      </ul>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane className="text-center" tabId="2" id="following">
                  <h3 className="text-muted">Not following anyone yet :(</h3>
                  <Button className="btn-round" color="warning">
                    Find artists
                  </Button>
                </TabPane>
              </TabContent>
              <br />
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <Nav role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Professional Details
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              {/* Tab panes */}
              <TabContent className="following" activeTab={activeTab}>
                <TabPane tabId="1" id="follows" style={{ minHeight: "100px" }}>
                  <Row>
                    <Col className="ml-auto mr-auto" md="8">
                      <ul className="list-unstyled follows">
                        <li>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Profession</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.profession}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Annual Income</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.annualIncome}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Employed In</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.employedIn}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Education</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.education}</small>
                              </p>
                            </Col>
                          </Row>
                        </li>

                        {/* <hr /> */}
                      </ul>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>

              <br />
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <Nav role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Family Details
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              {/* Tab panes */}
              <TabContent className="following" activeTab={activeTab}>
                <TabPane tabId="1" id="follows" style={{ minHeight: "100px" }}>
                  <Row>
                    <Col className="ml-auto mr-auto" md="8">
                      <ul className="list-unstyled follows">
                        <li>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Father Status</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.fatherStatus}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Mother Status</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.motherStatus}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Brothers</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.brothers}</small>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="ml-auto mr-auto"
                              lg="4"
                              md="4"
                              xs="4"
                            >
                              <p>Sisters</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="1"
                              md="1"
                              xs="1"
                            >
                              <p>:</p>
                            </Col>
                            <Col
                              className="ml-auto mr-auto"
                              lg="7"
                              md="7"
                              xs="7"
                            >
                              <p>
                                {" "}
                                <small>{userData.sisters}</small>
                              </p>
                            </Col>
                          </Row>
                        </li>

                        {/* <hr /> */}
                      </ul>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
              <br />

              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <Nav role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Contact Details
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              {/* Tab panes */}

              <TabContent className="following" activeTab={activeTab}>
                <TabPane tabId="1" id="follows" style={{ minHeight: "100px" }}>
                  <Row>
                    <Col className="ml-auto mr-auto" md="8">
                      <ul className="list-unstyled follows">
                        <li>
                          {inArray(
                            props.data.id,
                            userInfo.acceptedProfileId
                          ) ? (
                            <div>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Full Name</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>
                                      {userData.firstName} {userData.lastName}
                                    </small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Email Address</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.email}</small>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="4"
                                  md="4"
                                  xs="4"
                                >
                                  <p>Mobile Number</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="1"
                                  md="1"
                                  xs="1"
                                >
                                  <p>:</p>
                                </Col>
                                <Col
                                  className="ml-auto mr-auto"
                                  lg="7"
                                  md="7"
                                  xs="7"
                                >
                                  <p>
                                    {" "}
                                    <small>{userData.mobileNumber}</small>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <div>
                              <Row>
                                <Col className="ml-auto mr-auto text-center">
                                  <p>
                                    Contact info is not visible to you unless
                                    you both accept each others requests.
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </li>

                        {/* <hr /> */}
                      </ul>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Container>
          </div>
        </ModalBody>
        <ModalFooter style={backgroundColor}>
          {modalParams.btnCancel ? (
            <Button
              className="btn-round"
              color="info"
              style={{ margin: "15px" }}
              onClick={(event) => closeModal(modalParams.btnCancel)}
            >
              {modalParams.btnCancel}
            </Button>
          ) : null}
          <Button
            className="btn-round"
            color="info"
            style={{ margin: "15px" }}
            onClick={(event) => closeModal("OK")}
          >
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default ProfileViewModal;
