/* eslint-disable */
import React, { useState, useEffect } from "react";
// import Firebase from 'firebase';

// reactstrap components

import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from "reactstrap";
// import { Link } from '@material-ui/core';

// core components

const ImageViewerModal = (props) => {
  const [modal, setModal] = useState(true);
  const [modalParams, setModalParams] = useState(props.data);

  const toggle = () => setModal(!modal);

  const closeModal = (returnParam) => {
    setModal(false);
    //     props.callBack(returnParam);
  };

  const backgroundColor = {
    backgroundColor: "#f5f5f5",
    justifyContent: "space-around",
  };

  const Textcolor = {
    color: "#f5593d",
  };
  var w = window.innerWidth;
  const externalCloseBtn = (
    <button
      type="button"
      className="close"
      style={{ position: "absolute", top: "15px", right: "15px" }}
      onClick={toggle}
    >
      &times;
    </button>
  );
  return (
    <Modal
      isOpen={modal}
      size={w > 600 ? "md" : "sm"}
      toggle={toggle}
      external={externalCloseBtn}
    >
      <div>
        <ModalHeader toggle={toggle}> {modalParams.title}</ModalHeader>
        <ModalBody
          style={{
            ...backgroundColor,
            maxHeight: "calc(100vh - 100px)",
            overflowY: "auto",
            width: "100%",
          }}
        >
          <Form className="register-form">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                {modalParams.short_Description ? (
                  <p className="title" style={{ textAlign: "center" }}>
                    {modalParams.short_Description}
                  </p>
                ) : null}
                <img
                  alt={modalParams.title}
                  className="img-thumbnail"
                  src={modalParams.image_URL}
                  // style={{ height: "60%" }}
                />
              </Col>
            </Row>{" "}
          </Form>
        </ModalBody>
        <ModalFooter style={backgroundColor}>
          <Button
            className="btn-round"
            color="default"
            type="button"
            outline
            style={{ margin: "15px" }}
            onClick={(event) => closeModal("")}
          >
            Cancel
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default ImageViewerModal;
