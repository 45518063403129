/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { isLoggedIn } from "actions";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
// import { Share } from '@capacitor/share';
import { PushNotifications } from "@capacitor/push-notifications";
import { Router, Route, Switch, Redirect, Link } from "react-router-dom";
import { SplashScreen } from "@capacitor/splash-screen";
import { Device } from "@capacitor/device";
import { GLOBLE_CONSTANT } from "../../config/constant";
import { AppLauncher } from "@capacitor/app-launcher";
import { Share } from "@capacitor/share";
// const AppEvents = App;

// reactstrap components
/* eslint-disable */
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import SectionCarousel from "views/index-sections/SectionCarousel.js";
import { db, remoteConfig } from "../../firebase/firebase";
import AlertModal from "components/Modals/Modals";
import { useHistory } from "react-router-dom";
import * as firebase from "firebase/app";
import MobileVerificationModal from "components/Modals/MobileVerificationModal";
import InquiryModal from "components/Modals/InquiryModal";
import CommunityRegistrationModal from "components/Modals/CommunityRegistrationModal";
import AppUpdateModal from "components/Modals/AppUpdateModal";
// import { AdMob, AdOptions, AdSize, AdPosition } from '@capacitor-community/admob';
import {
  AdMob,
  BannerAdOptions,
  BannerAdSize,
  BannerAdPosition,
  BannerAdPluginEvents,
  AdMobBannerSize,
  AdOptions,
  AdLoadInfo,
  InterstitialAdPluginEvents,
  RewardAdPluginEvents,
  AdMobRewardItem,
} from "@capacitor-community/admob";
import CommunityConnectModal from "components/Modals/CommunityConnectModal";
import { FileSharer } from "@byteowls/capacitor-filesharer";
import MyLoader from "components/Modals/Spinner";
import CommunityMembers from "./CommunityMembers";
const { PDFDocument, rgb, degrees } = PDFLib;
function LandingPage() {
  const ALERT_PARAMS_INITIAL_STATE = {
    title: "",
    body: "",
    btnOk: "",
    btnCancel: "",
  };
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    // document.body.classList.add("profile-page");
    // return function cleanup() {
    //   document.body.classList.remove("profile-page");
    // };
  });
  const isLoggedInVal = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [getFeedbackName, setFeedbackName] = useState("");
  const [getFeedbackText, setFeedbackText] = useState("");
  const [getFeedbackEmail, setFeedbackEmail] = useState("");
  const [alertParams, setAlertParams] = useState(ALERT_PARAMS_INITIAL_STATE);
  const [showConfirmAlert, setConfirmAlert] = useState(false);
  const history = useHistory();
  const [inquiryParams, setInquiryParams] = useState({});
  const [AppUpdateParams, setAppUpdateParams] = useState({});
  const [showMobileOtpValidation, setShowMobileOtpValidation] = useState(false);
  const [showInquiryModal, setShowInquiryModal] = useState(false);
  const [communityModal, setCommunityModal] = useState(false);
  const [showAppUpdateModal, setAppUpdateModal] = useState(false);
  const [showCommunityConnectModal, setCommunityConnectModal] = useState(false);
  const [communityConnectModalParam, setCommunityConnectModalParam] = useState(
    {}
  );
  const [shareData, setShareData] = useState({});
  const [trendingEvents, setTrendingEvents] = useState({});
  const [founderBioFlag, setFounderBioFlag] = useState(false);
  const [marketingHighlights, setMarketingHighlights] = useState([]);

  const [socialOrganisations, setSocialOrganisations] = useState([]);
  const [noticeBoard, setNoticeBoard] = useState({});
  const [marqueeText, setMarqueeText] = useState({});
  const [socialWorkers, setSocialWorkers] = useState([]);
  const [organisers, setOrganisers] = useState([]);
  const [founderBio, setFounderBio] = useState({});
  const [eventFeeds, setEventFeeds] = useState({});
  const [eventNews, setEventNews] = useState({});
  const [masterConfig, setMasterConfig] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // setMemberParams({
  //   name: "मा.श्री.भिमराव दशरथ भुसनर",
  //   imageUrl: require("assets/image/Bhimrao Bhusnar.jpg"),
  //   profileLink: "https://www.facebook.com/profile.php?id=100010526647712",
  //   role: "संस्थापक/अध्यक्ष : हटकर समाज महाराष्ट्र राज्य",
  // });
  useEffect(() => {
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        let showModalOncePerDayValue = "";
        let getDay = new Date().getDay().toString();
        if (localStorage.getItem("showModalOncePerDayValue") !== null) {
          showModalOncePerDayValue = localStorage.getItem(
            "showModalOncePerDayValue"
          );
        }
        let globle_config = JSON.parse(
          remoteConfig.getValue("globle_config").asString()
        );

        let marketingHighlights_ = remoteConfig
          .getValue("marketing_highlights")
          .asString();
        let marquee_text_ = remoteConfig.getValue("marquee_text").asString();
        if (marquee_text_) {
          const marqueeText = JSON.parse(marquee_text_);
          setMarqueeText(marqueeText);
        }

        let notice_board_ = remoteConfig.getValue("notice_board").asString();
        if (notice_board_) {
          const noticeBoard = JSON.parse(notice_board_);
          setNoticeBoard(noticeBoard);
        }

        let socialWorkers_ = remoteConfig.getValue("social_workers").asString();
        if (socialWorkers_) {
          const socialWorkers = JSON.parse(socialWorkers_);
          setSocialWorkers(socialWorkers);
        }

        let social_organisations_ = remoteConfig
          .getValue("social_organisations")
          .asString();
        if (social_organisations_) {
          const socialOrganisations = JSON.parse(social_organisations_);
          setSocialOrganisations(socialOrganisations);
        }
        let organisers_ = remoteConfig.getValue("organisers").asString();
        if (organisers_) {
          const organisers = JSON.parse(organisers_);
          setOrganisers(organisers);
        }

        if (marketingHighlights_) {
          const marketingHighlights = JSON.parse(marketingHighlights_);
          setMarketingHighlights(marketingHighlights);
        }

        let greetings_config = globle_config?.greetings_config;
        if (Capacitor.isNativePlatform()) {
          let appUpdate = globle_config.appUpdate;
          updateApp(appUpdate);
        }
        if (greetings_config && greetings_config.status) {
          if (showModalOncePerDayValue !== getDay) {
            // to show one post per day
            localStorage.setItem("showModalOncePerDayValue", getDay);
            onNotificationRecievedModal(greetings_config);
          }
        }
        let trending_events = globle_config?.trending_events;
        if (trending_events && trending_events.status) {
          setTrendingEvents(trending_events);
        }
        let master_config = globle_config?.master_config;
        setMasterConfig(master_config);
        if (master_config && master_config.OWNER_BIO_FLAG) {
          setFounderBioFlag(true);
        }

        if (master_config && master_config.DELETE_ACCOUNT_FLAG) {
          localStorage.setItem("DELETE_ACCOUNT_FLAG", true);
        } else {
          localStorage.setItem("DELETE_ACCOUNT_FLAG", false);
        }

        if (master_config && master_config.OWNER_BIO) {
          setFounderBio(master_config.OWNER_BIO);
        }

        let normal_events_feeds = globle_config?.normal_events_feeds;
        if (normal_events_feeds && normal_events_feeds.status) {
          setEventFeeds(normal_events_feeds);
        }
        let normal_events_news = globle_config?.normal_events_news;
        if (normal_events_news && normal_events_news.status) {
          setEventNews(normal_events_news);
        }
        let community_connects = globle_config?.community_connects;
        if (community_connects && community_connects.status) {
          setCommunityConnectModalParam(community_connects);
          if (showModalOncePerDayValue !== getDay) {
            localStorage.setItem("showModalOncePerDayValue", getDay);
            setTimeout(() => {
              showCommunityConnect();
            }, 100);
          }
        }
      })
      .catch((err) => {
        console.log("REMOTE CONFIG ERROR : ", err);
      });
    if (Capacitor.isNativePlatform()) {
      SplashScreen.hide();
      App.removeAllListeners();
      App.addListener("backButton", (e) => {
        if (
          window.location.pathname === "/" ||
          window.location.pathname === "/dashboard"
        ) {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Are you sure you want to exit?");
          if (ans) {
            App.exitApp();
          }
        } else {
          // alert("PATH HERE : ",window.location.pathname)
          history.goBack();
        }
      });

      //   Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting

      PushNotifications.requestPermissions().then((result) => {
        // alert("requestPermissions",result)
        PushNotifications.register();
        // if (result.granted) {
        //   // Register with Apple / Google to receive push via APNS/FCM
        //   PushNotifications.register();
        // } else {
        //   // Show some error
        //   // alert("ERROR")
        // }
      });

      PushNotifications.addListener("registration", (token) => {
        window.localStorage.setItem("registrationToken", token.value);
        // sendNotification();
        // alert('Push registration success, token: ' + token.value);
        // console.log('Push registration success, token: ' + token.value);
      });
      PushNotifications.addListener("registrationError", (error: any) => {
        // alert('Error on registration: ' + JSON.stringify(error));
      });

      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          console.log("Push received: " + JSON.stringify(notification));
        }
      );

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          console.log(
            "Push action performed data here : " + JSON.stringify(notification)
          );
          if (notification) {
            const notificationData = notification.notification;
            if (
              notificationData &&
              notificationData.data &&
              notificationData.data.notifyData
            ) {
              const data = JSON.parse(notificationData.data.notifyData);
              notificationData.data.title = data.title ? data.title : "";
              notificationData.data.subTitle = data.subTitle
                ? data.subTitle
                : "";
              onNotificationRecievedModal(notificationData.data);
            }
          }
        }
      );
      // adMobIn();
    } else {
      console.log("IN WEB VIEW");
    }
  }, []);
  // const sendNotification = () => {
  //   let pushToken = "";
  //   if (localStorage.getItem("registrationToken") !== null) {
  //     pushToken = localStorage.getItem("registrationToken");
  //   }
  //   alert(pushToken)
  //   let title = 'Congratulation!!!';
  //   let body = 'our request is accepted by Mahesh. Please log in to get contact details.';

  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json',
  //     "Authorization": "key=AAAAQSRIrt8:APA91bGd9RJyHwREUG4Uyi4YQXBXzRleH7wSgX2k_zdhvhrcAGL0zcxFdlAPa7xjpPPOYWuHcmNM2HetsOJyCBlu2UAGvo-SG61KPfe8AW3YMYRGT5xUG66-JU-0Sl2Z3iTC0VT7d94A" },
  //     body: JSON.stringify({
  //       "to": pushToken,
  //       "notification": {
  //         "title": title,
  //         "body": body,
  //         "mutable_content": true,
  //         "sound": "Tri-tone"
  //         }

  //     })
  //   };
  //     fetch('https://fcm.googleapis.com/fcm/send', requestOptions)
  //     .then(response => response.json())
  //     .then(data => {
  //       alert("SUCCESS")
  //     });
  // };
  const adMobIn = async () => {
    // AdMob.initialize();
    try {
      await AdMob.initialize();
    } catch (error) {
      console.log("ADMOB INITIALIZE ERROR");
    }
    registerBanner();
    registerInterstitial();
    registeRrewardVideo();
  };
  const showBannerAd = async () => {
    const options: BannerAdOptions = {
      adId: GLOBLE_CONSTANT.banner_Add_ID,
      adSize: BannerAdSize.ADAPTIVE_BANNER,
      position: BannerAdPosition.BOTTOM_CENTER,
      margin: 0,
      // isTesting: true
      // npa: true
    };
    const result = await AdMob.showBanner(options).catch((e) =>
      console.log("ADMOB BANNER ERROR : ", e)
    );
    console.log(result);
  };
  const registerBanner = async () => {
    AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
      // Subscribe Banner Event Listener
    });

    AdMob.addListener(
      BannerAdPluginEvents.SizeChanged,
      (size: AdMobBannerSize) => {
        // Subscribe Change Banner Size
      }
    );
    await showBannerAd();
  };
  const registerInterstitial = async () => {
    const interstitial_Add_ID_Options: AdOptions = {
      adId: GLOBLE_CONSTANT.interstitial_Add_ID,
    };
    AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) => {
      // Subscribe prepared interstitial
    });
    await AdMob.prepareInterstitial(interstitial_Add_ID_Options);
  };
  const showInterstitial = async () => {
    await AdMob.showInterstitial();
  };
  const registeRrewardVideo = async () => {
    AdMob.addListener(RewardAdPluginEvents.Loaded, (info: AdLoadInfo) => {
      // Subscribe prepared rewardVideo
    });

    AdMob.addListener(
      RewardAdPluginEvents.Rewarded,
      (rewardItem: AdMobRewardItem) => {
        // Subscribe user rewarded
        console.log(rewardItem);
      }
    );

    const options: AdOptions = {
      adId: GLOBLE_CONSTANT.rewardVideoAd_ID,
      // isTesting: true
      // npa: true
    };
    await AdMob.prepareRewardVideoAd(options);
  };
  const showRrewardVideo = async () => {
    await AdMob.showRewardVideoAd();
  };
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  const updateApp = async (updateAppParams) => {
    console.log("updateAppParams: ", updateAppParams);
    const info = await Device.getInfo();
    setAppUpdateModal(false);

    // console.log("updateApp", updateApp.message);
    let appVersion = parseInt(GLOBLE_CONSTANT.appVersion.split(".").join(""));

    // console.log("appVersion : ",appVersion);
    let minSupportedVersion = "";
    let forceUpgradeVersion = "";
    let forceUpdateMessage = updateAppParams.message.forceUpdate;
    let optionalUpdateMessage = updateAppParams.message.basicUpdate;
    minSupportedVersion =
      info.platform === "ios"
        ? updateAppParams.versions.iOS.minSupportedVersion
        : updateAppParams.versions.android.minSupportedVersion;
    forceUpgradeVersion =
      info.platform === "ios"
        ? updateAppParams.versions.iOS.forceUpgradeVersion
        : updateAppParams.versions.android.forceUpgradeVersion;

    let appMinSupportedVersion =
      minSupportedVersion && parseInt(minSupportedVersion.split(".").join(""));
    let appForceUpgradeVersion =
      forceUpgradeVersion && parseInt(forceUpgradeVersion.split(".").join(""));
    const APP_STORE_LINK_ID = updateAppParams.APP_STORE_LINK_ID
      ? updateAppParams.APP_STORE_LINK_ID
      : "";
    const link =
      info.platform === "android"
        ? `market://details?id=${GLOBLE_CONSTANT.appId}`
        : `itms-apps://itunes.apple.com/kw/app/apple-store/${APP_STORE_LINK_ID}?mt=8`;
    console.log(
      `link : ${link} , appForceUpgradeVersion : ${appForceUpgradeVersion}, appMinSupportedVersion : ${appMinSupportedVersion}, appVersion: ${appVersion}`
    );

    if (
      appForceUpgradeVersion > appMinSupportedVersion &&
      appVersion < appForceUpgradeVersion
    ) {
      if (forceUpdateMessage !== undefined) {
        setAppUpdateParams((prevState) => ({
          ...prevState,
          title: forceUpdateMessage,
          storeLink: link,
          isOptional: false,
          img: "app-store-icons.jpeg",
        }));

        setTimeout(() => {
          setAppUpdateModal(true);
        }, 100);
      }
    } else if (appVersion < appMinSupportedVersion) {
      // if (isForceUpgradeOptional()) {
      if (forceUpdateMessage !== undefined) {
        setAppUpdateParams((prevState) => ({
          ...prevState,
          title: optionalUpdateMessage,
          storeLink: link,
          isOptional: true,
          img: "app-store-icons.jpeg",
        }));

        setTimeout(() => {
          setAppUpdateModal(true);
        }, 100);
      }
      // }
    }
  };
  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        // console.log("base64data : ", base64data);
        const base64Img = base64data.replace("data:image/png;base64,", "");
        // console.log("base64Img : ", base64Img);
        FileSharer.share({
          filename: "HatkarMatrimony.png",
          base64Data: base64Img,
          contentType: "image/png",
        })
          .then(() => {
            // do sth
            console.log("FileSharer SUCCESS");
          })
          .catch((error) => {
            console.error("File sharing failed", error.message);
          });
        resolve(base64Img);
      };
    });
  };

  const generatePDF = async (name) => {
    setIsLoading(true);
    const existingPdfBytes = await fetch(
      // "https://www.hatkarmatrimony.com/cert.pdf"
      "./Certificate.pdf"
    ).then((res) => res.arrayBuffer());
    console.log("Here", existingPdfBytes);
    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    pdfDoc.registerFontkit(fontkit);

    //get font
    // const fontBytes = await fetch("./Sanchez-Regular.ttf").then((res) =>
    //   res.arrayBuffer()
    // );

    // Embed our custom font in the document
    // const SanChezFont = await pdfDoc.embedFont(fontBytes);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Draw a string of text diagonally across the first page
    var coX = 350;
    var coY = 200;
    if (name.length < 10) {
      coX = 420;
      coY = 200;
    }
    if (name.length > 15) {
      coX = 390;
      coY = 200;
    }
    if (name.length > 40) {
      coX = 310;
      coY = 200;
    }
    firstPage.drawText(name, {
      x: coX,
      y: coY,
      size: 18,
      // font: SanChezFont,
      // color: rgb(0.2, 0.84, 0.67),
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    // console.log("Done creating");

    // this was for creating uri and showing in iframe

    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
    // console.log("pdfDataUri: ", pdfDataUri);
    if (Capacitor.isNativePlatform()) {
      const base64Pdf = pdfDataUri.replace("data:application/pdf;base64,", "");

      // document.getElementById("pdf").src = pdfDataUri;
      // console.log("pdfDataUri : ",pdfDataUri);
      // const blob = new Blob([pdfBytes], { type: 'application/pdf'});
      // const url1 = await URL.createObjectURL(blob); application/pdf;base64
      setIsLoading(false);
      FileSharer.share({
        filename: name + ".pdf",
        base64Data: base64Pdf,
        contentType: "application/pdf",
      })
        .then(() => {
          // do sth
          console.log("FileSharer SUCCESS");
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("File sharing failed", error.message);
          console.error("File sharing failed", error.message);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      const downloadLink = document.createElement("a");
      const fileName = name + ".pdf";
      downloadLink.href = pdfDataUri;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };
  const onFeedbackClick = async () => {
    // generatePDF("MAHESH BHUSANOOR");

    // return false;
    // AdMob.showInterstitial();
    // AdMob.showRewardVideoAd();
    // setCommunityModal(false);
    // setTimeout(() => {
    //   setCommunityModal(true);
    // }, 100);

    // let shareRet = await Share.share({
    //   title: 'See cool stuff',
    //   text: 'Really awesome thing you need to see right meow',
    //   url: 'http://ionicframework.com/',
    //   dialogTitle: 'Share with buddies'
    // });
    // console.log(shareRet);

    if (getFeedbackName == "") {
      alert("Name should not be blank.");
      return;
    }
    if (getFeedbackName.length > 30) {
      alert("Name should not be too longer.");
      return;
    }
    if (getFeedbackEmail == "") {
      alert("Email should not be blank.");
      return;
    }
    if (!emailValidation(getFeedbackEmail)) {
      alert("Email id is not valid.");
      return;
    }
    if (getFeedbackText == "") {
      alert("Message should not be blank.");
      return;
    }
    if (getFeedbackText.length > 800) {
      alert("Message should not be too longer.");
      return;
    }

    db.collection("feedback")
      .doc(getFeedbackEmail)
      .set({
        name: getFeedbackName,
        message: getFeedbackText,
        email: getFeedbackEmail,
      })
      .then(function(docRef) {
        setFeedbackName("");
        setFeedbackEmail("");
        setFeedbackText("");
        setAlertParams((prevState) => ({
          ...prevState,
          title: "Thank You!!",
          body: (
            <p style={{ textAlign: "center" }}>
              {" "}
              Your query/feedback has been submitted successfully.
            </p>
          ),
          btnOk: "Okey",
          btnCancel: "",
        }));
        setTimeout(() => {
          setConfirmAlert(true);
        }, 100);
        // console.log("Document written with ID: ", docRef);
        //   db.collection("subscriber").get().then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //         console.log(`${doc.id} => ${doc.data()}`);
        //     });
        // });
      })
      .catch(function(error) {
        // console.error("Error adding document: ", error);
        // alert('OOPS!! At this moment we are facing some issues. Please try after some time.')
      });
  };
  const openInquiryModal = (title, type) => {
    setShowInquiryModal(false);
    setInquiryParams((prevState) => ({
      ...prevState,
      title: title,
      type: type,
    }));
    setTimeout(() => {
      setShowInquiryModal(true);
    }, 100);
  };
  const showCommunityConnect = () => {
    setCommunityConnectModal(false);
    setTimeout(() => {
      setCommunityConnectModal(true);
    }, 100);
  };
  const showOtpValidationModal = () => {
    setShowMobileOtpValidation(false);
    setTimeout(() => {
      setShowMobileOtpValidation(true);
    }, 100);
  };
  const onNotificationRecievedModal = (params) => {
    // const data = JSON.parse(params.notifyData);
    setConfirmAlert(false);
    setAlertParams((prevState) => ({
      ...prevState,
      title: params.title ? params.title : "",
      body: (
        <p style={{ textAlign: "center" }}>
          {" "}
          {params.subTitle ? params.subTitle : ""}
        </p>
      ),
      btnOk: params.btnText ? params.btnText : "Close",
      img: "",
      imageUrl: params.imageUrl ? params.imageUrl : "",
      btnDownloadPdf: params.btnDownloadPdf ? true : false,
      redirectUrl: params.redirectUrl ? params.redirectUrl : "",
      btnCancel: params.btnCancel ? params.btnCancel : "",
      btnShare: params.btnShare ? params.btnShare : "",
      clickHereLinkMessage: params.clickHereLinkMessage
        ? params.clickHereLinkMessage
        : "Click here to know more",
    }));
    setTimeout(() => {
      setConfirmAlert(true);
    }, 100);
  };
  const openDonate = () => {
    setConfirmAlert(false);
    setAlertParams((prevState) => ({
      ...prevState,
      title: "Donate!!",
      body: (
        <p style={{ textAlign: "center" }}>
          {" "}
          You can contribute through GooglePay, PhonePay or any other UPI ID.
        </p>
      ),
      btnOk: "Okey",
      img: "maheshbhusanoor@upi1593853002948.jpg",
      imageUrl: "",
      redirectUrl: "",
      btnCancel: "",
    }));
    setTimeout(() => {
      setConfirmAlert(true);
    }, 100);
  };

  const successCallBackData = async (data) => {
    if (data && data.name) {
      let notifyData = {
        title: "Registration Success",
        fullName: data.place ? data.name + " (" + data.place + ")" : data.name,
        subTitle:
          "धन्यवाद " +
          data.name.toUpperCase() +
          " ( " +
          data.place +
          " ),  हटकर समाज महाराष्ट्र राज्य यांच्या वतीने आयोजित कंमुनिटी कनेक्ट मोहिमेअंतर्गत सुरू असलेल्य सदस्य नोंदणी अभियानात सहभाग घेऊन सदस्य नोंदणी केल्याबद्दल हार्दिक अभिनंदन.",
        mobileNumber: data.mobileNumber,
        imageUrl: data.photoURL,
        btnText: Capacitor.isNativePlatform() ? "Share" : "",
        redirectUrl: "",
        btnCancel: Capacitor.isNativePlatform() ? "Close" : "",
        btnDownloadPdf: false,
      };
      if (Capacitor.isNativePlatform()) {
        // if(getRandomInt(2) == '0'){
        //   AdMob.showRewardVideoAd();
        // }else{
        //   AdMob.showInterstitial();
        // }
        // showRrewardVideo();
      }
      setShareData(notifyData);
      onNotificationRecievedModal(notifyData);
    } else if (data == "Share") {
      let shareRet = await Share.share({
        title: "हटकर समाज महाराष्ट्र राज्य.",
        text:
          shareData.subTitle.toUpperCase() +
          " हटकर समाज महाराष्ट्र राज्य यांच्या वतीने आयोजित कंमुनिटी कनेक्ट मोहिमेअंतर्गत सुरू असलेल्य सदस्य नोंदणी अभियानात सहभाग घेऊन सदस्य नोंदणी केल्याबद्दल हार्दिक अभिनंदन. खालील लिंकवर क्लिक करून अँप डाउनलोड करा.",
        url:
          "https://play.google.com/store/apps/details?id=" +
          GLOBLE_CONSTANT.appId,
        dialogTitle: "Share with community",
      });
      //  console.log("SHARE RESULT : ",shareRet)
    } else if (data == "UPDATE_APP") {
      // console.log("AppUpdateParams : ",AppUpdateParams.storeLink )
      // alert('Can open url: ', AppUpdateParams.storeLink );
      const { value } = await AppLauncher.canOpenUrl({
        url: AppUpdateParams.storeLink,
      });

      await AppLauncher.openUrl({ url: AppUpdateParams.storeLink });
    } else if (data == "COMMUNITY_CONNECTS") {
      history.push("/Community-Events", {
        eventsData: communityConnectModalParam,
      });
    } else if (data == "Share Now") {
      // if (shareData.imageUrl) getBase64FromUrl(shareData.imageUrl);
    } else if (data == "downloadCertificate") {
      // console.log("master_config : ",masterConfig)
      var sortName = shareData.fullName.trim();
      if (sortName) generatePDF(sortName);
      // if (Capacitor.isNativePlatform()) {
      //   // var newSort = sortName.split(" ").join("_");
      //   // var addUrl = "/Download/" + newSort + "_kdsjfhdkshfkdjshfk5dj8s";
      //   // await Browser.open({
      //   //   url:
      //   //     masterConfig && masterConfig.ACTIVE_WEB_URL
      //   //       ? masterConfig.ACTIVE_WEB_URL + addUrl
      //   //       : GLOBLE_CONSTANT.webUrl + addUrl,
      //   // });
      //   if (sortName) generatePDF(sortName);
      //   // await Browser.open({ url: 'https://pwapps-maheshvirus.web.app/Download/mahesh_patil_kdsjfhdkshfkdjshfk5dj8sz'});
      // } else {
      //   // history.push("/Download", {
      //   //   name: shareData.fullName,
      //   // });
      //   if (sortName) generatePDF(sortName);
      // }
    }
  };
  const emailValidation = (value) => {
    return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value);
  };
  const handleCommunityRegistration = () => {
    setCommunityModal(false);
    setTimeout(() => {
      setCommunityModal(true);
    }, 100);
  };
  // alert(isLoggedInVal)
  return (
    <>
      {isLoading ? <MyLoader /> : null}
      <ExamplesNavbar />
      <LandingPageHeader callBack={handleCommunityRegistration} />
      <div className="main">
        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                {marqueeText && marqueeText.message ? (
                  <h5 className="description text-center">
                    <marquee
                      direction="left"
                      width="400"
                      style={{ fontWeight: "800", color: "green" }}
                    >
                      {marqueeText.message}
                    </marquee>
                  </h5>
                ) : null}
              </Col>
            </Row>
            {/* <hr /> */}

            {noticeBoard && noticeBoard.title ? (
              <Row>
                <Col
                  className="ml-auto mr-auto text-center img-thumbnail img-responsive"
                  md="10"
                >
                  <h4 className="title  text-center">{noticeBoard.title}</h4>{" "}
                  <h5 className="description">{noticeBoard?.description}</h5>{" "}
                  <br />
                  <h5 className="description">
                    <a href={noticeBoard?.link} style={{ color: "#0056b3" }}>
                      {noticeBoard?.linkTitle} <br />
                    </a>{" "}
                  </h5>
                </Col>
              </Row>
            ) : (
              <hr />
            )}
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <h2 className="title  text-center">
                  Welcome to Hatkar Matrimony
                </h2>

                <h5 className="description">
                  Inspired by{" "}
                  <a href="https://en.wikipedia.org/wiki/Atmanirbhar_Bharat">
                    Atmanirbhar Bharat Abhiyan.
                  </a>{" "}
                  We have created a free digital platform initiative towards{" "}
                  Social work. Come join us to serve our community better.
                </h5>
                <br />
                <h5
                  className="title"
                  style={{ color: "#604f43", fontWeight: 600 }}
                >
                  <b>Connecting Hearts, Building Community</b>
                </h5>
                <h5 className="description">
                  At Hatkar Matrimony, we're not just about finding life
                  partners; we're about creating a community where Hatkar hearts
                  can connect, laugh, and thrive together.
                </h5>
                <br />
                {/* <h5
                  className="title"
                  style={{ color: "#604f43", fontWeight: 600 }}
                >
                  <b>Our Mission: Connecting People, Building Bonds</b>
                </h5>
                <h5 className="description">
                  Our mission is simple: to build a Hatkar community where
                  people can connect, collaborate, and find joy in each other's
                  company.
                </h5>
                <br /> */}
                <h5
                  className="title"
                  style={{ color: "#604f43", fontWeight: 600 }}
                >
                  <b>Vision: Uniting Hatkar Community</b>
                </h5>
                <h5 className="description">
                  Inspired by Atmanirbhar Bharat Abhiyan, we've created a
                  platform to unite the Hatkar community. Let's join hands for
                  events, social work, and the joy of knowing our community.
                </h5>
                <br />
                {/* <h5
                  className="title"
                  style={{ color: "#604f43", fontWeight: 600 }}
                >
                  <b>Together Through Tough Times</b>
                </h5>
                <h5 className="description">
                  In these challenging times, we're committed to bringing the
                  Hatkar community together. Let's support each other and find
                  solace in our togetherness.
                </h5>
                <br /> */}
                <h5
                  className="title"
                  style={{ color: "#604f43", fontWeight: 600 }}
                >
                  <b>Connect, Collaborate, Celebrate</b>
                </h5>
                <h5 className="description">
                  Find perfect matches within your Hatkar community and connect
                  for events, social work, and digital initiatives. Because a
                  connected community is a happy community!
                </h5>
                <br />
                <h5
                  className="title"
                  style={{ color: "#604f43", fontWeight: 600 }}
                >
                  <b>Join Hatkar Matrimony: Simple, Free, and Heartfelt</b>
                </h5>
                <h5 className="description">
                  Register for free and become part of a community that cares.
                  Let's create a space where Hatkar community members can
                  connect, collaborate, and build lasting memories.
                </h5>
                <br />
                <h5
                  className="title"
                  style={{ color: "#604f43", fontWeight: 600 }}
                >
                  <b>Community Connect Initiatives</b>
                </h5>
                <h5 className="description">
                  Embark on a journey of community connect initiatives with
                  Hatkar Matrimony. From events to social work, let's make a
                  positive impact together.
                </h5>
                <br />
                <h5
                  className="title"
                  style={{ color: "#604f43", fontWeight: 600 }}
                >
                  <b>Know Your Community</b>
                </h5>
                <h5 className="description">
                  Explore the rich tapestry of the Hatkar community. Discover
                  stories, traditions, and the incredible diversity that makes
                  us who we are.
                </h5>
                <br />
                <h5
                  className="title"
                  style={{ color: "#604f43", fontWeight: 600 }}
                >
                  <b>Hatkar Matrimony: Connecting Hearts, Building Community</b>
                </h5>
                <h5 className="description">
                  Because at Hatkar Matrimony, we believe that a connected
                  community is a happy and thriving community. Join us in the
                  celebration of love, laughter, and community spirit!
                </h5>
                <br />
                <h5
                  className="title"
                  style={{ color: "#604f43", fontWeight: 600 }}
                >
                  <b>History of Hatkars</b>
                </h5>
                <h5 className="description">
                  <b style={{ fontWeight: "600" }}>हटकर</b> हा शब्द हट्टीकारा या
                  कन्नड शब्दावरुन आला आहे. कन्नड मराठी शब्दकोशा मधे हट्टी म्हणजे
                  गाई-बैल असा अर्थ दिलेला अढळतो. हट्टीकारा म्हणजे गाईबैलांचा
                  मालक. हटकर हे पशुपालक असले तरी चालुक्य ,होयसळ राजघराण्यांचे
                  सेनापती सैनिक हे हटकर असल्याचे उल्लेख कन्नड शिलालेखांमधे
                  सापडतात. महाराष्ट्रात असलेले हटकर ही एक लढाऊ जमात आहे.
                  परंपरेने लढाई करणे हा त्यांचा प्रमुख व्यवसाय होता. यातूनच
                  त्यांच्यात अनेक मोठी सरदार घराणी उदयाला आल्याचे दिसते यात
                  सरदार धायगुडे, राजेपांढरे, बळंवतराव किताब आसलेले देवकाते, राजे
                  सरगर ,मासाळ,हंडे इ. घराणी सापडतात यांत नेमाजी शिंदे या हटकर
                  मराठी वीराने सर्वप्रथम नर्मनदी ओलांडली,त्याच काळात दामाजी
                  थोरात हे एक पराक्रमी सरदार होते. . हटकरांना हट्टीकारा,
                  बाराहट्टी, बरहट्टी, झेंडे, बंडगर, बंडे, बर्गी धनगर, बारगीर या
                  ना्वांनीसुद्धा ओळखले जाते.
                </h5>
                <h5 className="description">
                  तसेच काही हटकरांना पाटील, राव, नाईक, देशमुख, राजे या उपाध्या
                  आहेत.{" "}
                  <Link
                    to={{
                      pathname: "/About-us",
                    }}
                  >
                    {" "}
                    <Button className="btn-link" color="info">
                      Read more...
                    </Button>
                  </Link>
                </h5>
                <hr />

                {organisers && organisers.length > 0 ? (
                  <div>
                    {" "}
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="12">
                        <h4
                          className="title text-center"
                          style={{ fontWeight: "800" }}
                        >
                          संयोजक{" "}
                        </h4>
                      </Col>
                    </Row>
                    <CommunityMembers data={organisers} />
                  </div>
                ) : null}

                <hr />
                {trendingEvents && trendingEvents.status ? (
                  <SectionCarousel
                    data={trendingEvents}
                    renderResponsive={true}
                  />
                ) : null}
                <hr />
                <br />

                {socialOrganisations && socialOrganisations.length > 0 ? (
                  <div>
                    {" "}
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="12">
                        <h4
                          className="title text-center"
                          style={{ fontWeight: "800" }}
                        >
                          सामाजिक संस्था{" "}
                        </h4>
                      </Col>
                    </Row>
                    <CommunityMembers
                      data={socialOrganisations}
                      itemsPerRow={2}
                      style={{ width: "100%", height: "400px" }}
                      imageStyle={"thumnail img-no-padding img-responsive"}
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-dark text-center">
          <Container>
            <h3 className="title">Latest News & Updates</h3>

            <h5 className="description">
              Stay connected for the most recent community news and updates.
            </h5>

            {eventNews && eventFeeds ? (
              <Row style={{ marginTop: "70px" }}>
                <Col md="6">
                  {eventNews && eventNews.status ? (
                    <SectionCarousel data={eventNews} />
                  ) : null}
                </Col>
                <Col md="6">
                  {eventFeeds && eventFeeds.status ? (
                    <SectionCarousel data={eventFeeds} />
                  ) : null}
                </Col>
              </Row>
            ) : eventFeeds && eventFeeds.status ? (
              <SectionCarousel data={eventFeeds} />
            ) : null}
          </Container>
        </div>

        <div className="section">
          <Container>
            {socialWorkers && socialWorkers.length > 0 ? (
              <div>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="12">
                    <h4
                      className="title text-center"
                      style={{ fontWeight: "800" }}
                    >
                      सामाजिक कार्यकर्ते{" "}
                    </h4>
                    <hr />
                  </Col>
                </Row>
                <CommunityMembers data={socialWorkers} />
              </div>
            ) : null}

            <hr />
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h5 className="title text-center">
                  To advertise on the website, kindly contact us via email at{" "}
                  <a
                    href="mailto:hatkarmatrimony@gmail.com"
                    style={{ color: "#76daff", textTransform: "none" }}
                  >
                    hatkarmatrimony@gmail.com
                  </a>
                </h5>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section section-dark text-center">
          <Container>
            {/* <h3 className="title">Wish to connect us?</h3>
 { trendingEvents && trendingEvents.status ? <SectionCarousel data={trendingEvents} /> : null}  */}
            {founderBioFlag && founderBio?.image_URL ? (
              <div>
                <div className="avatar">
                  <img
                    alt={founderBio?.title}
                    className="img-circle img-no-padding img-responsive"
                    src={founderBio?.image_URL}
                    style={{ width: "150px", height: "150px" }}
                  />
                </div>
                {/* <div className="name"> */}
                <h4 className="title">
                  <a href={founderBio?.link} style={{ color: "white" }}>
                    {founderBio?.title} <br />
                    <p className="description">
                      {founderBio?.short_Description}

                      <br />
                    </p>
                  </a>{" "}
                </h4>
                {/* <h6 className="description">CEO & CTO</h6> */}
                {/* </div> */}

                <h5 className="description">
                  Inspired by{" "}
                  <a href="https://en.wikipedia.org/wiki/Atmanirbhar_Bharat">
                    Atmanirbhar Bharat Abhiyan
                  </a>{" "}
                  , our free digital platform is all about social work. Join us
                  to make a difference in our community!
                </h5>
                <hr />
                <h2 className="title">Wish to connect us?</h2>
              </div>
            ) : null}
            {!founderBioFlag ? (
              <div>
                <h2 className="title">Wish to connect us?</h2>
                <hr />
                <h5 className="description">
                  Inspired by{" "}
                  <a href="https://en.wikipedia.org/wiki/Atmanirbhar_Bharat">
                    Atmanirbhar Bharat Abhiyan
                  </a>{" "}
                  , our free digital platform is all about social work. Join us
                  to make a difference in our community!
                </h5>
              </div>
            ) : null}

            <Row style={{ marginTop: "70px" }}>
              <Col md="4">
                <div className="info">
                  <div
                    className="icon icon-info"
                    style={{ marginBottom: "-30px" }}
                  >
                    <i className="nc-icon nc-bulb-63" />
                  </div>
                  <div className="description">
                    <h4
                      className="info-title"
                      style={{ marginBottom: "30px" }}
                      onClick={(e) =>
                        openInquiryModal("Investor Connect", "investor_connect")
                      }
                    >
                      Investor Connect
                    </h4>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div
                    className="icon icon-info"
                    style={{ marginBottom: "-30px" }}
                  >
                    <i className="nc-icon nc-bulb-63" />
                  </div>
                  <div className="description">
                    <h4
                      className="info-title"
                      style={{ marginBottom: "30px" }}
                      onClick={(e) =>
                        openInquiryModal("Mentor Connect", "mentor_connect")
                      }
                    >
                      Mentor Connect
                    </h4>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div
                    className="icon icon-info"
                    style={{ marginBottom: "-30px" }}
                  >
                    <i className="nc-icon nc-bulb-63" />
                  </div>
                  <div className="description">
                    <h4
                      className="info-title"
                      style={{ marginBottom: "30px" }}
                      onClick={(e) =>
                        openInquiryModal(
                          "Advertiser Connect",
                          "advertiser_connect"
                        )
                      }
                    >
                      Advertiser connect
                    </h4>
                  </div>
                </div>
              </Col>
            </Row>

            {/* <Row>
              {trendingEvents && trendingEvents.status ? (
                <SectionCarousel
                  data={trendingEvents}
                  renderResponsive={true}
                />
              ) : null}
              <hr />
            </Row> */}
          </Container>
        </div>
        <div className="section landing-section">
          <Container>
            {marketingHighlights && marketingHighlights.length > 0 ? (
              <div>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="12">
                    <h4
                      className="title text-center"
                      style={{ fontWeight: "800" }}
                    >
                      Marketing Highlights{" "}
                    </h4>
                    <h5 className="title text-center">
                      To advertise on the website, kindly contact us via email
                      at{" "}
                      <a
                        href="mailto:hatkarmatrimony@gmail.com"
                        style={{ color: "#76daff", textTransform: "none" }}
                      >
                        hatkarmatrimony@gmail.com
                      </a>
                    </h5>
                    <hr />
                  </Col>
                </Row>
                <CommunityMembers
                  data={marketingHighlights}
                  itemsPerRow={2}
                  style={{ width: "100%", height: "400px" }}
                  imageStyle={"thumnail img-no-padding img-responsive"}
                />
              </div>
            ) : null}
            <hr />

            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Have Queries or Feedback?</h2>
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Name</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Name"
                          type="text"
                          value={getFeedbackName}
                          onChange={(event) =>
                            setFeedbackName(event.target.value)
                          }
                        />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="text"
                          value={getFeedbackEmail}
                          onChange={(event) =>
                            setFeedbackEmail(event.target.value)
                          }
                          type="email"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Message</label>
                  <Input
                    placeholder="Please write your queries or feedback here.."
                    type="textarea"
                    rows="4"
                    value={getFeedbackText}
                    onChange={(event) => setFeedbackText(event.target.value)}
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button
                        className="btn-fill"
                        color="danger"
                        size="lg"
                        onClick={onFeedbackClick}
                      >
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <SectionCarousel />
      <DemoFooter />
      {showConfirmAlert ? (
        <AlertModal data={alertParams} callBack={successCallBackData} />
      ) : null}
      {showMobileOtpValidation ? (
        <MobileVerificationModal callBack={successCallBackData} />
      ) : null}
      {showInquiryModal ? (
        <InquiryModal data={inquiryParams} callBack={successCallBackData} />
      ) : null}
      {communityModal ? (
        <CommunityRegistrationModal callBack={successCallBackData} />
      ) : null}
      {showAppUpdateModal ? (
        <AppUpdateModal data={AppUpdateParams} callBack={successCallBackData} />
      ) : null}
      {showCommunityConnectModal ? (
        <CommunityConnectModal
          data={communityConnectModalParam}
          callBack={successCallBackData}
        />
      ) : null}
    </>
  );
}

export default withRouter(LandingPage);
