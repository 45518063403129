/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { db, auth, storage } from "../../firebase/firebase";
import imageCompression from "browser-image-compression";
import MyLoader from "../../components/Modals/Spinner";
import LoadingOverlay from "react-loading-overlay";
import "@ungap/global-this";
import {
  NetworkDetector,
  fetchUserProfile,
  IPInfoFetcher,
  EncodeData,
  DecodeData,
} from "../../components/Modals/AsyncTask";

// reactstrap components

import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardTitle,
  CardText,
  Badge,
  CardHeader,
  CardFooter,
  Progress,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import AlertModal from "../../components/Modals/Modals";
import { userInfoContext } from "../../context/userContext";
import SkeletonCard from "components/SkeletonCard/SkeletonCard";
import TroubleFetching from "components/TroubleFetching/TroubleFetching";
import ImgsViewer from "react-images-viewer";

function PhotoUpload(props) {
  const userDataInitialState = {
    data: {},
  };
  const ALERT_PARAMS_INITIAL_STATE = {
    title: "",
    body: "",
    btnOk: "",
    btnCancel: "",
  };
  // console.log("PhotoUpload props : ", props);
  const [activeTab, setActiveTab] = useState("1");
  const [userData, setUserData] = useState({});
  const [userDataEditCancel, setUserDataEditCancel] = useState({});
  const [UID, setUID] = useState("");
  const [editEnabled, setEditStatus] = useState(false);
  const [editPhoto, setEditPhoto] = useState(true);
  const [photoURL, setPhotoURL] = useState("");
  const [photoStream, setPhotoStream] = useState("");
  const [getProgress, setProgress] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const [photoUploadStatus, setPhotoUploadStatus] = useState(null);
  const [getError, setError] = useState(null);
  const [alertParams, setAlertParams] = useState(ALERT_PARAMS_INITIAL_STATE);
  const [showConfirmAlert, setConfirmAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, setUserInfo } = useContext(userInfoContext);
  const [isSkeleton, setSkeleton] = useState(true);
  const [poorConnection, setPoorConnection] = useState(false);
  const [showProflePicture, setShowProflePicture] = useState(false);
  const [imgSRC, setImgSRC] = useState("");
  var resetNetwork;
  var ntwokChk = false;
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 400,
      // useWebWorker: true
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      setPhotoStream(compressedFile);
      setError(null);
      //  const urlLink = await uploadToServer(compressedFile);
      //  setPhotoURL(urlLink)
      // setImage( event.target.files[0]); // write your own logic
    } catch (error) {
      // console.log(error);
    }
  }
  const successCallBackData = (data) => {
    // console.log(data);
    setConfirmAlert(false);
    if (data == "RECONNECT") {
      updateUserInfo();
    } else if (data == "RELOAD") {
      // fetchData();
      initialFetch();
    } else if (data == "TRY AGAIN") {
      updateUserPhoto();
    }
  };
  const cancelUpdate = () => {
    setEditStatus(false);
    setEditPhoto(false);
    setUserData(userDataEditCancel);
    setPhotoUploadStatus(null);
    setProgress(null);
    setPhotoStream("");
    setShowProgress(false);
  };
  const updateUserPhoto = () => {
    setNetworkTimeOut(60000);
    const fileName =
      props && props.data ? props.data.imageName : "default_name_" + new Date();
    if (photoStream == "") {
      errorUpdate("Please select photo");
      return;
    }

    setShowProgress(true);
    let result = NetworkDetector();
    let imageLocation =
      props && props.data ? props.data.storageName : "default_storage";
    result.then(
      (result) => {
        const uploadTask = storage
          .ref(`${imageLocation}/${fileName}`)
          .put(photoStream);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // progress function ...
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // this.setState({ progress });
            setProgress(progress);
            // console.log(progress)
          },
          (error) => {
            // Error function ...
            setProgress(null);
            // console.log(error);
            ntwokChk = false;
            clearTimeout(resetNetwork);
          },
          () => {
            // complete function ...
            storage
              .ref(imageLocation)
              .child(fileName)
              .getDownloadURL()
              .then((url) => {
                setPhotoURL(url);
                props.callBack(url);
                ntwokChk = true;
                clearTimeout(resetNetwork);
                setNetworkTimeOut(40000);

                return url;
              });
          }
        );
      },
      function(error) {
        setAlertParams((prevState) => ({
          ...prevState,
          title: "Internet Disconnected",
          body: "Please check your internet connection and try again.",
          btnOk: "TRY AGAIN",
          btnCancel: "CANCEL",
        }));
        setIsLoading(false);
        setConfirmAlert(true);
        ntwokChk = false;
        clearTimeout(resetNetwork);
      }
    );
  };
  // const uploadToServer = (compressedFile)=>{
  //   console.log('uploadToServer success')
  //   console.log(compressedFile)
  // }

  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  useEffect(() => {
    setPhotoURL("");
    window.scrollTo(0, 0);
  }, []);
  const setNetworkTimeOut = (timer) => {
    ntwokChk = false;
    resetNetwork = setTimeout(() => {
      if (ntwokChk) {
        console.log("INTERNET WORKING");
        setIsLoading(false);
        setSkeleton(false);
      } else {
        console.log("SHOW NETWORK ERROR");
        setPoorConnection(true);
        setIsLoading(false);
        setSkeleton(false);
      }
    }, timer);
  };
  function initialFetch() {
    setNetworkTimeOut(40000);
    if (userInfo) {
      console.log("userInfo has data");
      clearTimeout(resetNetwork);
      setUserData(userInfo);
      setUserDataEditCancel(userInfo);
      setIsLoading(false);
      setPoorConnection(false);
      setSkeleton(false);
      ntwokChk = true;
    } else {
      console.log("userInfo data not found");
      fetchData();
    }
  }
  useEffect(() => {}, [userData], [photoURL]);

  // const callbackRef = useRef('');
  // useEffect(() => {
  //   // callbackRef.current = callback;
  //   console.log(callbackRef.current)
  // }, []);
  const onHandleChange = (event) => {
    //   setUserData(prevState => ({
    //     ...prevState, data:'Hello'
    // }));
    // setUserData(prevState => [{ ...prevState,  allowedState}]);
    const { name, value } = event.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
      error: null,
    }));
  };

  async function errorUpdate(msg) {
    await setError({
      message: msg,
    });
  }
  async function increment(url) {
    await setPhotoURL(url);
    // console.log(photoURL)
  }

  const editDetailsClick = () => {
    setEditStatus(true);
    setError(null);
  };
  const editPhotoClick = () => {
    setEditPhoto(true);
    setError(null);
  };
  function closeViewer() {
    setShowProflePicture(false);
  }
  function showProfilePic() {
    // setImgSRC(userData.photoURL);
    // setShowProflePicture(true);
  }

  const cntImage = 0;
  const imgWidth = 220;
  const open = true;
  return (
    <>
      {isLoading ? <MyLoader /> : null}

      {editPhoto ? (
        <Container>
          <Row>
            <Card style={{ width: "100%" }}>
              <CardBody>
                <div className="d-flex justify-content-around">
                  <div className="p-2 col-example" style={{ width: "90%" }}>
                    <label> Upload Photo</label>
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={(event) => handleImageUpload(event)}
                    />{" "}
                    <br />
                    {showProgress ? (
                      <div>
                        {getProgress < 100 ? (
                          <label className="text-center">
                            Uploading...{getProgress}%
                          </label>
                        ) : (
                          <label className="text-center">
                            Photo Uploaded Success.
                          </label>
                        )}
                        <Progress value={getProgress} />
                      </div>
                    ) : null}
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <div className="d-flex justify-content-center">
                  {!showProgress ? (
                    <div className="p-2 col-example">
                      <Button
                        block
                        className="btn-round"
                        size="sm"
                        color="danger"
                        onClick={updateUserPhoto}
                      >
                        Upload
                      </Button>
                    </div>
                  ) : null}
                  {/* {!showProgress ? (
                                  <div className="p-2 col-example">
                                    <Button
                                      block
                                      className="btn-round"
                                      size="sm"
                                      color="danger"
                                      onClick={cancelUpdate}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                ) : null} */}
                  {photoUploadStatus ? (
                    <div className="p-2 col-example">
                      <p className="title">{photoUploadStatus}</p>
                      <Button
                        block
                        className="btn-round"
                        size="sm"
                        color="danger"
                        onClick={cancelUpdate}
                      >
                        Okey
                      </Button>
                    </div>
                  ) : null}
                </div>

                {getError && (
                  <p style={{ color: "#c52e2e" }}>{getError.message}</p>
                )}
              </CardFooter>
            </Card>
            {/* <Col>
                          <br />
                          <div className="nav-tabs-navigation">
                            <div className="nav-tabs-wrapper">
                              <Nav role="tablist" tabs>
                                <NavItem>
                                  <NavLink
                                    className={
                                      activeTab === "1" ? "active" : ""
                                    }
                                    onClick={() => {
                                      toggle("1");
                                    }}
                                  >
                                    Upload Picture
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </div>
                          </div>
                         
                        </Col> */}
          </Row>
        </Container>
      ) : null}

      {showConfirmAlert ? (
        <AlertModal data={alertParams} callBack={successCallBackData} />
      ) : null}
    </>
  );
}

export default PhotoUpload;
