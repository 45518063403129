/* eslint-disable */
import React, {useState} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import { db, auth } from '../../firebase/firebase';
import { useSelector, useDispatch } from "react-redux";

const PrivateRoute =  ({ component: Component, isAuth1, ...rest }) =>{
 
 const isAuth = localStorage.getItem('isLoggedIn');
// const isAuth = useSelector(state => state.authReducer);
return ( <Route {...rest} render={(props) => (
   
  isAuth === 'true'
    ? <Component {...props} />
    :  <Redirect to={{
        pathname: '/404',
        state: {
          authType: "LOGIN"
        }
      }}/>
)} />)
}


// const PrivateRoute = ({ component: Component, isAuth, ...rest }) => (
  //   <Route {...rest} render={(props) => (
  //     // localStorage.getItem('isLoggedIn') === true
  //     fakeAuth.isAuthenticated === 'true'
  //       ? <Component {...props} />
  //       :  <Redirect to={{
  //           pathname: '/404',
  //           state: {
  //             authType: "LOGIN"
  //           }
  //         }}/>
  //   )} />
  // )

export default PrivateRoute;
