/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { AdMob, AdOptions } from "@capacitor-community/admob";

// reactstrap components

import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  Form,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import { Capacitor } from "@capacitor/core";
import { GLOBLE_CONSTANT } from "config/constant";

function TermsConditions(match) {
  const [activeTab, setActiveTab] = React.useState("1");
  const [getGender, setGender] = React.useState({
    selectedOption: "",
  });
  const [isRegistration, setRegistration] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [defaultContainer, setDefaultContainer] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [authType, setAuthType] = useState("LOGIN");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (Capacitor.isNativePlatform()) {
      // adMobIn();
    }
  }, []);
  const adMobIn = async () => {
    try {
      await AdMob.initialize();
    } catch (error) {
      console.log("ADMOB INITIALIZE ERROR");
    }

    const options: AdOptions = {
      adId: GLOBLE_CONSTANT.rewardVideoAd_ID,
    };
    await AdMob.prepareRewardVideoAd(options);
    const options_: AdOptions = {
      adId: GLOBLE_CONSTANT.interstitial_Add_ID,
    };
    // Prepare interstitial banner
    await AdMob.prepareInterstitial(options_);

    setTimeout(() => {
      if (getRandomInt(2) == "0") {
        showRrewardVideo();
      } else {
        showInterstitial();
      }
    }, 4000);
  };
  const showInterstitial = async () => {
    await AdMob.showInterstitial();
  };
  const showRrewardVideo = async () => {
    await AdMob.showRewardVideoAd();
  };
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const Textcolor = {
    color: "#fff",
  };
  const radioButtonMargin = {
    margin: "0 10px 0 10px",
  };
  const handleOptionChange = (changeEvent) => {
    // console.log(changeEvent.target.value);
    setGender({
      selectedOption: changeEvent.target.value,
    });
  };

  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="name">
              <h4 className="title">
                Terms & Conditions <br />
              </h4>
            </div>
          </div>

          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <p className="description">
                PLEASE READ THE FOLLOWING TERMS AND CONDITIONS VERY CAREFULLY
                BEFORE USING THE WEBSITE. ACCESSING, BROWSING OR OTHERWISE USING
                THE WEBSITE IMPLIES YOUR AGREEMENT TO BE BOUND BY ALL THESE
                TERMS AND CONDITIONS ("Agreement"). If you do not want to be
                bound by the Terms and Conditions, you must not use the Website
                or the Hatkar Matrimony Services. The Terms and Conditions also
                includes the applicable policies which are incorporated herein
                by way of reference and as amended from time to time (the "Terms
                and conditions").
              </p>
              <p className="description">
                This document is an electronic record in terms of the
                Information Technology Act, 2000 and rules there under
                pertaining to electronic records as applicable and amended. This
                electronic record is generated by a computer system and does not
                require any physical or digital signature.
              </p>

              <h4 className="title">User Agreement</h4>

              <p className="description">
                You are solely responsible for any information that you display
                when using the Website or to other members. You will keep all
                information provided to you through the Website as private and
                confidential and will not give such information to anyone
                without the permission of the person who provided it to you.
              </p>
              <p className="description">
                We are not responsible for, and cannot control, the use by
                others of any information which you provide to them and you
                should use caution in selecting the personal information you
                provide to others through the Website.
              </p>
              <p className="description">
                We cannot assume any responsibility for the content of messages
                sent by other users of the Website, and you release us from any
                and all liability in connection with the contents of any
                communications you may receive from other users.
              </p>
              <p className="description">
                You acknowledge that you can not sue HatkarMatrimony.com or any
                of its employees for any damages done to you through the site.
                We cannot guarantee, and assume no responsibility for verifying,
                the accuracy of the information provided by other users of the
                Website. You may not use the Website for any unlawful purpose.
              </p>
              <h4 className="title">Monitoring of Information</h4>
              <p className="description">
                We reserve the right to monitor all advertisements, public
                postings and messages to ensure that they conform to the content
                guidelines, which may be applicable from time to time. We also
                reserve the right to monitor all messages and chats that take
                place through the site. We are not responsible for any offensive
                or obscene materials that may be transmitted or posted by other
                users (including unauthorized users as well as the possibility
                of "hackers"). We are also not responsible for the use of any
                personal information that the user may choose to post on the
                Website.
              </p>

              <h4 className="title">Removal of Information</h4>
              <p className="description">
                While we do not and cannot review every message or other
                material posted or sent by users of the Website, and are not
                responsible for any content of these messages or materials, we
                reserve the right, but are not obligated, to delete, move, or
                edit messages or materials, that we, in our sole discretion,
                deem to violate the Code of Conduct set out above or any
                applicable content guidelines, or to be otherwise unacceptable.
                You shall remain solely responsible for the content of
                advertisements, public postings, messages and other materials
                you may upload to the Website or users of the Website.
              </p>
              <h4 className="title">Termination of Access to Website</h4>
              <p className="description">
                We may, in our sole discretion, terminate or suspend your access
                to all or part of the Website at any time, with or without
                notice, for any reason, including, without limitation, breach of
                this Agreement. Without limiting the generality of the
                foregoing, any fraudulent, abusive, or otherwise illegal
                activity may be grounds for termination of your access to all or
                part of the Website at our sole discretion, and you may be
                referred to appropriate law enforcement agencies.
              </p>
              <h4 className="title">Online Cancellation</h4>
              <p className="description">
                Any request for cancellation will be met with written support as
                fast as possible. You accept that when you cancel your
                membership with The Website you are automatically locked out of
                the site and your access is immediately terminated. You also
                agree and accept the complete and final loss of your profile,
                mail, and any other membership information that you should have.
                This information cannot be reclaimed or transferred to any third
                party. If you later change you decision you must reregister and
                pay for a new membership. However previous registration
                information will remain lo
              </p>

              <h4 className="title">Proprietary Information</h4>
              <p className="description">
                The Website contains information, which is proprietary to us and
                to our users. We assert full copyright protection in the
                website. Information posted by us or other users of the Website
                may be protected whether or not it is identified as proprietary
                to us or to them. You agree not to modify, copy or distribute
                any such information in any manner whatsoever without having
                first received the express permission of the owner of such
                information.
              </p>
              <h4 className="title">No responsibility.</h4>
              <p className="description">
                We are not responsible for any incidental, consequential,
                special, punitive, exemplary, direct or indirect damages of any
                kind whatsoever, which may arise out of or relate to your use of
                the Website, including but not limited to lost revenues,
                profits, business or data, or damages resulting from any
                viruses, worms, "Trojan horses" or other destructive software or
                materials, or communications by you or other users of the
                Website, or any interruption or suspension of the Website,
                regardless of the cause of the interruption or suspension. Any
                claim against us shall be limited to the amount you paid, if
                any, for use of the Website during the contract period less the
                time used. We may discontinue or change the Website or its
                availability to you at any time, and you may stop using the
                Website at any time.
              </p>

              <h4 className="title">Security</h4>
              <p className="description">
                Your account is private and should not be used by anyone else.
                You are responsible for all usage or activity on the Website by
                users using your password, including but not limited to use of
                your password by any third party.
              </p>
              <h4 className="title">Other Links</h4>
              <p className="description">
                The Website may from time to time contain links and or banners
                to other sites and resources ("External Links"). We are not
                responsible for, and have no liability as a result of, the
                availability of External Links or their contents
              </p>
              <h4 className="title">Disclosure and Other Communication</h4>
              <p className="description">
                We reserve the right to send electronic mail to you, for the
                purpose of informing you of changes or additions to the Website,
                or of any HatkarMatrimony.com related products and websites. We
                reserve the right to disclose information about your usage of
                the Website and Demographics in forms that do not reveal your
                personal identity. We reserve the right to disclose all
                information regarding your usage to law enforcement officers on
                first request
              </p>

              <h4 className="title">Complaints</h4>
              <p className="description">
                To resolve or report a complaint regarding the Website or
                members who use the Website users should write to our online
                support . Immediate actions will take place in order to help
                solve the problem.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h5 className="title text-center">
                To advertise on the website, kindly contact us via email at{" "}
                <a
                  href="mailto:hatkarmatrimony@gmail.com"
                  style={{ color: "#76daff", textTransform: "none" }}
                >
                  hatkarmatrimony@gmail.com
                </a>
              </h5>
            </Col>
          </Row>
          <br />
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <Button className="btn-round" color="info" href="/">
                Back to Home Page
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default TermsConditions;
