/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
const { PDFDocument, rgb, degrees } = PDFLib;
// reactstrap components

import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  Form,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import ImgsViewer from "react-images-viewer";
import { remoteConfig } from "../../firebase/firebase";

import { Capacitor } from "@capacitor/core";
import { GLOBLE_CONSTANT } from "config/constant";
import MyLoader from "../../components/Modals/Spinner";

function Download(props) {
  const [activeTab, setActiveTab] = React.useState("1");
  const [getGender, setGender] = React.useState({
    selectedOption: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [eventsData, setEventsData] = useState();
  const [imgSRC, setImgSRC] = useState("");
  const [showProflePicture, setShowProflePicture] = useState(false);
  const history = useHistory();
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const cntImage = 0;
  const imgWidth = 220;
  const open = true;
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.name
    ) {
      console.log("DATA HERE : ", props.location.state.name);
      setIsLoading(true);
      generatePDF(props.location.state.name.toUpperCase());
    } else {
      var currentLocation = window.location.href;
      var newarr = currentLocation.split("/");
      var length = newarr.length;
      var mainStr = newarr[length - 1];
      console.log("DATA HERE mainStr:", mainStr);
      if (mainStr.includes("kdsjfhdkshfkdjshfk5dj8s")) {
        var data = mainStr.substr(0, mainStr.length - 24);
        if (data) {
          var newData = data.split("_").join(" ");
          setIsLoading(true);
          // var abnm = 'mahesh bhusanoor'
          console.log("DATA HERE newData:", newData);
          generatePDF(newData.toUpperCase());
        }
      }
    }
    window.scrollTo(0, 0);
  }, []);

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  const fetchFromFirebase = () => {
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        let normal_events_feeds = JSON.parse(
          remoteConfig.getValue("normal_events_feeds").asString()
        );
        console.log(normal_events_feeds);
        if (normal_events_feeds && normal_events_feeds.status) {
          setEventsData(normal_events_feeds);
        }
      })
      .catch((err) => {
        console.log("REMOTE CONFIG ERROR : ", err);
      });
  };
  const generatePDF = async (name) => {
    const existingPdfBytes = await fetch(
      "https://www.hatkarmatrimony.com/cert.pdf"
    ).then((res) => res.arrayBuffer());
    console.log("Here", existingPdfBytes);
    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    pdfDoc.registerFontkit(fontkit);

    //get font
    // const fontBytes = await fetch("./Sanchez-Regular.ttf").then((res) =>
    //   res.arrayBuffer()
    // );

    // Embed our custom font in the document
    // const SanChezFont = await pdfDoc.embedFont(fontBytes);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Draw a string of text diagonally across the first page
    var coX = 350;
    var coY = 200;
    if (name.length < 10) {
      coX = 420;
      coY = 200;
    }
    if (name.length > 15) {
      coX = 390;
      coY = 200;
    }
    if (name.length > 40) {
      coX = 310;
      coY = 200;
    }
    firstPage.drawText(name, {
      x: coX,
      y: coY,
      size: 18,
      // font: SanChezFont,
      // color: rgb(0.2, 0.84, 0.67),
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    console.log("Done creating");

    // this was for creating uri and showing in iframe

    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
    // document.getElementById("pdf").src = pdfDataUri;
    // console.log("pdfDataUri : ",pdfDataUri);
    // const blob = new Blob([pdfBytes], { type: 'application/pdf'});
    // const url1 = await URL.createObjectURL(blob);
    setEventsData(pdfDataUri);
    setIsLoading(false);
  };
  const radioButtonMargin = {
    margin: "0 10px 0 10px",
  };
  const handleOptionChange = (changeEvent) => {
    console.log(changeEvent.target.value);
    setGender({
      selectedOption: changeEvent.target.value,
    });
  };
  function closeViewer() {
    setShowProflePicture(false);
  }
  function showPhoto(data) {
    setImgSRC(data);
    setShowProflePicture(true);
  }
  function downloadPDF() {
    const linkSource = eventsData;
    const downloadLink = document.createElement("a");
    const fileName = "हटकर समाज महाराष्ट्र राज्य सदस्य नोंदणी प्रमाणपत्र.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  var w = window.innerWidth;
  return (
    <>
      {isLoading ? <MyLoader /> : null}
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="name">
              <h4 className="title">
                Download Section
                <br />
              </h4>
            </div>
          </div>
          {eventsData ? (
            <div>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="12">
                  <h4
                    className="title text-center"
                    style={{ fontWeight: "800" }}
                  >
                    {" "}
                    सदस्य नोंदणी प्रमाणपत्र
                  </h4>
                </Col>
              </Row>
              <hr />
              {!Capacitor.isNativePlatform() ? (
                <div>
                  <Row>
                    <Col className=" text-center ">
                      <div>
                        {eventsData ? (
                          <embed
                            src={eventsData}
                            width="90%"
                            type="application/pdf"
                            height={w > 600 ? "800" : "400"}
                          />
                        ) : (
                          <h5 className="title text-center">
                            {" "}
                            OOPS!! No data found to download
                          </h5>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null}
              <br />
              <Row>
                <Col className="ml-auto mr-auto text-center" md="12">
                  <Button
                    className="btn-round"
                    color="success"
                    onClick={downloadPDF}
                  >
                    Download Now / डाउनलोड करा
                  </Button>
                </Col>
              </Row>

              <hr />
            </div>
          ) : (
            <h5 className="title text-center">
              {isLoading ? "Loading Certificate" : "No data found mahesh "}
            </h5>
          )}
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h5 className="title text-center">
                To advertise on the website, kindly contact us via email at{" "}
                <a
                  href="mailto:hatkarmatrimony@gmail.com"
                  style={{ color: "#76daff", textTransform: "none" }}
                >
                  hatkarmatrimony@gmail.com
                </a>
              </h5>
            </Col>
          </Row>
          <br />
          <hr />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <Button className="btn-round" color="info" href="/">
                Back to Home Page
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default Download;
