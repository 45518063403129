/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import { useHistory } from "react-router-dom";
// reactstrap components
/* eslint-disable */
import {
  Card,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

// core components

// const items = [
//   {
//     src: require("assets/img/soroush-karimi.jpg"),
//     altText: "Somewhere",
//     caption: "Somewhere"
//   },
//   {
//     src: require("assets/img/federico-beccari.jpg"),
//     altText: "Somewhere else",
//     caption: "Somewhere else"
//   },
//   {
//     src: require("assets/img/joshua-stannard.jpg"),
//     altText: "Here it is",
//     caption: "Here it is"
//   }
// ];

function SectionCarousel(props) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const [trendingEvents, setTrendingEvents] = React.useState({});
  const history = useHistory();
  const [items, setItems] = React.useState([]);
  React.useEffect(() => {
    if (props && props.data) {
      // if(props.data){
      setTrendingEvents(props.data);
      setItems(props.data.ITEMS);
      // }
    }
  }, []);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;

    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    //  alert(nextIndex)
    // console.log(nextIndex);
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    // console.log(nextIndex);
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  const navigateTo = (data) => {
    // console.log(post)
    history.push("/Community-Events", {
      eventsData: data,
    });
  };
  var w = window.innerWidth;
  return (
    <>
      {trendingEvents && trendingEvents.status ? (
        <div
          className={props && props.renderResponsive ? "section pt-o" : ""}
          id="carousel"
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <Card className="page-carousel">
                  <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    enableTouch={true}
                  >
                    <CarouselIndicators
                      items={trendingEvents.ITEMS}
                      activeIndex={activeIndex}
                      onClickHandler={goToIndex}
                    />
                    {trendingEvents.ITEMS.map((item, i) => {
                      return (
                        <CarouselItem
                          onExiting={onExiting}
                          onExited={onExited}
                          key={i}
                        >
                          <img
                            src={item.image_URL}
                            alt={item.title}
                            onClick={(event) => navigateTo(trendingEvents)}
                            style={{
                              height:
                                trendingEvents.type == "TRENDING"
                                  ? w > 600
                                    ? 500
                                    : 300
                                  : 300,
                              width: "100%",
                            }}
                          />
                          <CarouselCaption
                            captionText={item.short_Description}
                            captionHeader={item.title}
                          />
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="left carousel-control carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        previous();
                      }}
                      role="button"
                    >
                      <span className="fa fa-angle-left" />
                      <span className="sr-only sr-only-focusable">
                        Previous
                      </span>
                    </a>
                    <a
                      className="right carousel-control carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        next();
                      }}
                      role="button"
                    >
                      <span className="fa fa-angle-right" />
                      <span className="sr-only sr-only-focusable">Next</span>
                    </a>
                  </Carousel>
                </Card>
              </Col>
            </Row>
          </Container>{" "}
          :
        </div>
      ) : null}
    </>
  );
}

export default SectionCarousel;
