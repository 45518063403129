/* eslint-disable */
import React from 'react';
import { Jumbotron, Button, Container, Row, Col, CardBody, CardText,CardTitle , Card} from 'reactstrap';
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader';
import DemoFooter from 'components/Footers/DemoFooter';

const TroubleFetching = (props) => {
  function OkRetry() {
    props.callBack();
  }
  return (
    <div>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container><br /><br /><br />
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10" lg="10">
              {/* <Jumbotron style={{backgroundColor:'#fafafa'}}>
        <h1>Internet Disconnected!</h1>
        <p className="lead">Sorry!! At this moment we are unable to connect to the remote server. Please check your internet connection and try again.</p>
        <hr className="my-2" />
        <p>Please make sure your Wi-Fi or Mobile data is on.</p>
       
        <p className="lead">
          <Button color="primary" onClick={OkRetry}>Try Again...</Button>
        </p>
         </Jumbotron> */}
              <Card>
                <CardBody>
                  <CardTitle><h1>Internet Disconnected!</h1></CardTitle><br />
                  <CardText>Sorry!! At this moment we are unable to connect to the remote server. Please check your internet connection and try again.<br />
                        Please make sure your Wi-Fi or Mobile data is on.
                        </CardText>
                  <Button className="btn-round" color="primary" onClick={OkRetry}>Try Again...</Button>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>

      </div>
      <DemoFooter />
    </div>
  );
};

export default TroubleFetching;