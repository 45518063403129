/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { db, auth, storage } from "../../firebase/firebase";
import {
  NetworkDetector,
  fetchUserProfile,
  EncodeData,
  DecodeData,
} from "../../components/Modals/AsyncTask";

// reactstrap components

import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardTitle,
  CardText,
  Badge,
  CardHeader,
  CardFooter,
  Progress,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import { userInfoContext } from "../../context/userContext";
import SkeletonCard from "components/SkeletonCard/SkeletonCard";
import TroubleFetching from "components/TroubleFetching/TroubleFetching";
import ImgsViewer from "react-images-viewer";
import ProfileList from "../../pagination/ProfileList";

function ProfileViews(props) {
  let pageHeader = React.createRef();
  const [activeTab, setActiveTab] = useState("1");
  const [userData, setUserData] = useState({});
  const [photoURL, setPhotoURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeleton, setSkeleton] = useState(false);
  const [poorConnection, setPoorConnection] = useState(false);
  const { userInfo, setUserInfo } = useContext(userInfoContext);
  const [showProflePicture, setShowProflePicture] = useState(false);
  const [imgSRC, setImgSRC] = useState("");
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [posts, setPosts] = useState();
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  useEffect(() => {
    // setUserInfo(props.location.state.userInfo)
    // console.log(props.location.state)

    if (userInfo) {
      // console.log("Profile Page has data userInfo");
    } else {
      if (props.location.state !== undefined)
        setUserInfo(props.location.state.userInfo);
    }
    if (posts) {
      // console.log("Profile Page has data posts");
    } else {
      if (props.location.state !== undefined)
        setPosts(props.location.state.posts);
    }

    setSkeleton(false);
    setPhotoURL("");
    window.localStorage.setItem("index", 1);
    window.scrollTo(0, 0);
  }, []);

  function closeViewer() {
    setShowProflePicture(false);
  }
  function showProfilePic() {
    // setImgSRC(userData.photoURL)
    // setShowProflePicture(true)
  }
  const profileListCallBack = (data) => {
    // console.log("CALLBACK RECEIVED");
  };
  const handleEntailmentRequest = (e) => {
    e.preventDefault();
    //do something...
  };
  const cntImage = 0;
  const imgWidth = 220;
  const open = true;

  return (
    <>
      {/* <ImgsViewer
        imgs={[{ src:imgSRC}]}
        currImg={cntImage}
        onClose={closeViewer}
        isOpen={showProflePicture}
        showImgCount={false}
        backdropCloseable={true}
      /> */}

      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          {userInfo ? (
            <div className="owner">
              <div className="avatar">
                {!userInfo.photoURL && userInfo.gender == "male" ? (
                  <img
                    alt={userInfo.firstName}
                    className="img-circle img-no-padding img-responsive"
                    src={require("assets/image/default_User_Icon_Men.png")}
                    style={{ width: "150px", height: "150px" }}
                  />
                ) : null}
                {!userInfo.photoURL && userInfo.gender == "female" ? (
                  <img
                    alt={userInfo.firstName}
                    className="img-circle img-no-padding img-responsive"
                    style={{ width: "150px", height: "150px" }}
                    src={require("assets/image/default_User_Icon_Women.png")}
                  />
                ) : null}
                {userInfo.photoURL ? (
                  <img
                    alt={userInfo.firstName}
                    style={{ width: "150px", height: "150px" }}
                    className="img-circle img-no-padding img-responsive"
                    src={userInfo.photoURL}
                    // onClick={showProfilePic}
                  />
                ) : null}
              </div>
              <div className="name">
                <h4 className="title" style={{ textTransform: "capitalize" }}>
                  {userInfo.firstName} {userInfo.lastName}
                  <br />
                </h4>
              </div>
            </div>
          ) : null}
          <br />
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav role="tablist" tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    {props.location.state.profileType} Profiles
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        </Container>
        {posts ? (
          <ProfileList
            profiles={posts}
            callBack={profileListCallBack}
            onClick={(e) => {
              handleEntailmentRequest(e);
            }}
          />
        ) : null}
      </div>

      <DemoFooter />
    </>
  );
}
// const NewComponent = NetworkDetector(ProfilePage);
// export default  NetworkDetector(ProfilePage);
export default ProfileViews;
