/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
/* eslint-disable */
import { Button, Card, Form, Input, Container, Row, Col } from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import { Link } from "react-router-dom";

function PageNotFound() {
  document.documentElement.classList.remove("nav-open");

  //   React.useEffect(() => {

  //     document.body.classList.add("register-page");
  //     return function cleanup() {
  //       document.body.classList.remove("register-page");
  //     };

  //   });
  const style = {
    margin: "20px",
  };
  return (
    <>
      <ExamplesNavbar />
      <div
        className="page-header"
        style={{
          backgroundImage:
            "url(" + require("assets/image/Hatkar_Matrimony_Ring.jpg") + ")",
        }}
      >
        <div className="filter" />
        <Container>
          <div className="motto text-center">
            <h5 className="description text-center">
              <marquee
                direction="left"
                width="400"
                style={{ fontWeight: "800", color: "green" }}
              >
                संकेतस्थळावर जाहिरात करण्यासाठी संपर्क करा :
                hatkarmatrimony@gmail.com
              </marquee>
            </h5>
            <hr />
            <h3 className="title mx-auto">
              404: Uh oh, that page couldn't be found.
            </h3>
            <p>
              But you are looking for love in all the right places, lets find
              someone right for you.
            </p>

            <br />

            <Link
              to={{
                pathname: "/secure-auth",
                state: {
                  authType: "LOGIN",
                },
              }}
            >
              <Button
                style={style}
                className="btn-round"
                color="neutral"
                type="button"
                outline
              >
                Login
              </Button>
            </Link>

            <Link
              to={{
                pathname: "/secure-auth",
                state: {
                  authType: "REGISTER",
                },
              }}
            >
              <Button
                style={style}
                className="btn-round"
                color="neutral"
                type="button"
                outline
              >
                Register
              </Button>
            </Link>
          </div>
        </Container>
        <div className="footer register-footer text-center">
          <p
            style={{
              color: "#ffffff",
              textTransform: "none",
              marginTop: "30px",
            }}
          >
            Copyright © 2019 Hatkar Matrimony. All rights reserved | Design by{" "}
            <a
              href="https://hatkarmatrimony.com/"
              style={{ color: "#76daff", textTransform: "none" }}
            >
              Hatkar Matrimony Team
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
