import * as firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/remote-config";

const config = {
  apiKey: "AIzaSyCWK_MHOMEHBbQHWX7iGMZ3KjNzuewnKtQ",
  authDomain: "hatkarmatrimony-e55e5.firebaseapp.com",
  databaseURL: "https://hatkarmatrimony-e55e5.firebaseio.com",
  projectId: "hatkarmatrimony-e55e5",
  storageBucket: "hatkarmatrimony-e55e5.appspot.com",
  messagingSenderId: "279781617375",
  appId: "1:279781617375:web:d8a18762200e8cde85436a",
  measurementId: "G-FBT84NW0XP",
};

// firebase.initializeApp(config)
export const myFirebase = firebase.initializeApp(config);
const remoteConfigInstance = firebase.remoteConfig();
// Set configSettings
const configSettings = {
  minimumFetchIntervalMillis: 3600000, // 1 hour
  fetchTimeoutMillis: 60000, // 1 minute
};

remoteConfigInstance.settings = configSettings;
export const remoteConfig = remoteConfigInstance;
const baseDb = myFirebase.firestore();
const baseAuth = myFirebase.auth();
const baseStorage = myFirebase.storage();
export const storage = baseStorage;
export const auth = baseAuth;

export const db = baseDb;
